import {
  buildColumns,
  CommandBar,
  IColumn,
  ICommandBarItemProps,
  MarqueeSelection,
  Panel,
  PanelType,
  SearchBox,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  PrimaryButton,
  DefaultButton,
  Dialog,
  DialogType,
  DialogFooter,
  Label
} from "@fluentui/react";
import React, { useState, useEffect, useCallback } from "react";
import ReactPaginate from "react-paginate";
import { context } from "AppContext";
import { IProductListEntry } from "models/IProductListEntry";
import Logger from "Logger";
import { Loading } from "components/Core/Loading";
import { copyAndSort, trimLower } from "helpers/HelperFunctions";
import { ISubscriptionListEntry } from "models/ISubscriptionListEntry";
import { EditProductSubscriptionPanel } from "./EditProductSubscriptionPanel";
import {
  onRenderRowWithCompany,
  renderItemColumnWithoutCompanyWarning
} from "components/Core/DetailsListUtils";
import { PAGE_SIZE } from "helpers/const";


interface IProductSubscriptionsProps {
  visible: boolean;
  onDismiss: () => void;
  selectedProductListEntry: IProductListEntry | undefined;
}

export const ProductSubscriptionsPanel = (
  props: IProductSubscriptionsProps
) => {
  const ctx = React.useContext(context)!;
  const [loading, setLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState([] as IColumn[]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentProductSubscriptions, setCurrentProductSubscriptions] =
    useState<ISubscriptionListEntry[]>([]);
  const [filteredProductSubscriptions, setFilteredProductSubscriptions] =
    useState<ISubscriptionListEntry[]>([]);
  const [
    filteredProductSubscriptionsPage,
    setFilteredProductSubscriptionsPage
  ] = useState<ISubscriptionListEntry[]>([]);
  const [selectedProductSubscription, setSelectedProductSubscription] =
    useState<ISubscriptionListEntry>();
  const [
    isDeleteSubscriptionConfirmationDialogVisible,
    setIsDeleteSubscriptionConfirmationDialogVisible
  ] = useState<boolean>(false);
  const [changeStateConfirmationMessage, setChangeStateConfirmationMessage] =
    useState({ title: "", message: "", newState: "" });

  const productSubscriptionSelection: Selection = new Selection({
    onSelectionChanged: () => {
      setSelectedProductSubscription(
        productSubscriptionSelection.getSelection()[0] as ISubscriptionListEntry
      );
    }
  }) as any;
  const [searchText, setSearchText] = useState("");
  const [
    editProductSubscriptionPanelVisible,
    setEditProductSubscriptionPanelVisible
  ] = useState(false);

  const loadProductSubscriptions = useCallback(() => {
    if (props.selectedProductListEntry?.id && props.visible) {
      setLoading(true);
      ctx.backendClient
        .getProductSubscriptions(
          props.selectedProductListEntry.id,
          ctx.apimEnvironment
        )
        .then((subscriptions) => {
          const subscriptionListEntries = subscriptions.map((s) => {
            return {
              key: s.id,
              id: s.id,
              displayName: s.displayName,
              state: s.state,
              createdDate: new Date(s.createdDate).toLocaleString("de-DE"),
              allowTracing: s.allowTracing ? "Yes" : "No",
              email: s.email,
              company: s.company
            } as any;
          });

          setCurrentProductSubscriptions(subscriptionListEntries);
          setFilteredProductSubscriptions(subscriptionListEntries);
        })
        .catch((err) => Logger.Error(err))
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedProductListEntry, ctx.apimEnvironment, props.visible]);

  useEffect(() => {
    loadProductSubscriptions();
  }, [loadProductSubscriptions]);

  // apply paging based on current page and pagesize
  useEffect(() => {
    const offset = currentPage * PAGE_SIZE;
    setFilteredProductSubscriptionsPage(
      filteredProductSubscriptions.slice(offset, offset + PAGE_SIZE)
    );
  }, [currentPage, filteredProductSubscriptions]);

  // search and filtering
  useEffect(() => {
    if (currentProductSubscriptions) {
      const newfilteredProductSubscriptions = currentProductSubscriptions
        .slice()
        .filter((productSubscription) => {
          if (
            productSubscription.displayName &&
            productSubscription.displayName !== "" &&
            trimLower(productSubscription.displayName).indexOf(
              trimLower(searchText)
            ) === -1 &&
            productSubscription.email &&
            trimLower(productSubscription.email)?.indexOf(
              trimLower(searchText)
            ) === -1
          ) {
            return false;
          }
          return true;
        });
      setFilteredProductSubscriptions(newfilteredProductSubscriptions);
      setCurrentPage(0);
    }
  }, [searchText, currentProductSubscriptions]);

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "edit",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      onClick: () => {
        setEditProductSubscriptionPanelVisible(true);
      },
      disabled: selectedProductSubscription === undefined
    },
    {
      key: "activate",
      text: "Activate",
      iconProps: { iconName: "CheckMark" },
      onClick: () => {
        setChangeStateConfirmationMessage({
          title: "Confirm Subscription Activation",
          message: `Are you sure you want to activate this ${
            selectedProductSubscription?.state ?? ""
          } product subscription by ${
            selectedProductSubscription?.email
          }? After activation the subscription key can be used in API calls.`,
          newState: `Active`
        });
      },
      disabled: selectedProductSubscription === undefined,
      style: {
        display: `${
          !selectedProductSubscription ||
          selectedProductSubscription?.state === "Submitted" ||
          selectedProductSubscription.state === "Suspended" ||
          selectedProductSubscription.state === "Cancelled"
            ? "initial"
            : "none"
        }`
      }
    },
    {
      key: "reject",
      text: "Reject",
      iconProps: { iconName: "StatusCircleBlock" },
      onClick: () => {
        setChangeStateConfirmationMessage({
          title: "Confirm Subscription Rejection",
          message: `Are you sure you want to reject this product subscription submitted by ${selectedProductSubscription?.email}? The requesting user will receive an email that the subscription request has been rejected.`,
          newState: `Rejected`
        });
      },
      disabled: selectedProductSubscription === undefined,
      style: {
        display: `${
          !selectedProductSubscription ||
          selectedProductSubscription?.state === "Submitted"
            ? "initial"
            : "none"
        }`
      }
    },
    {
      key: "suspend",
      text: "Suspend",
      iconProps: { iconName: "DisableUpdates" },
      onClick: () => {
        setChangeStateConfirmationMessage({
          title: "Confirm Subscription Suspension",
          message: `Are you sure you want to suspend this ${
            selectedProductSubscription?.state ?? ""
          } product subscription requested by ${
            selectedProductSubscription?.email
          }? Calls to an API using a suspended subscription key will result in an unauthorized error.
           The subscription can be reactivated after it has been suspended.`,
          newState: `Suspended`
        });
      },
      disabled: selectedProductSubscription === undefined,
      style: {
        display: `${
          !selectedProductSubscription ||
          selectedProductSubscription?.state === "Active"
            ? "initial"
            : "none"
        }`
      }
    },
    {
      key: "delete",
      text: "Delete",
      iconProps: { iconName: "delete" },
      onClick: () => {
        setIsDeleteSubscriptionConfirmationDialogVisible(true);
      },
      disabled: selectedProductSubscription === undefined
    }
  ];

  // build DetailsList columns
  useEffect(() => {
    if (columns.length < 1) {
      let newColumns = buildColumns(currentProductSubscriptions);
      newColumns = newColumns.filter(
        (column) => column.key !== "id" && column.key !== "key"
      );
      for (const column of newColumns) {
        column.isResizable = true;
        switch (column.key) {
          case "displayName":
            column.name = "Name";
            break;
          case "email":
            column.name = "Owner Email";
            break;
          case "state":
            column.name = "State";
            column.maxWidth = 100;
            break;
          case "createdDate":
            column.name = "Creation Date";
            column.maxWidth = 150;
            break;
          case "allowTracing":
            column.name = "Allow Tracing";
            column.maxWidth = 100;
            break;
          case "company":
            column.name = "Company";
        }
      }
      setColumns(newColumns);
    }
  }, [currentProductSubscriptions, columns.length]);

  const onColumnClick = (
    ev: React.MouseEvent<HTMLElement> | undefined,
    column: IColumn | undefined
  ): void => {
    if (column && filteredProductSubscriptions) {
      const newColumns: IColumn[] = [...columns];
      const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key
      )[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
      const newlySortedProductSubscriptions = copyAndSort(
        filteredProductSubscriptions,
        currColumn.fieldName!,
        currColumn.isSortedDescending
      );
      setColumns(newColumns);
      setFilteredProductSubscriptions(newlySortedProductSubscriptions);
    }
  };

  const onPageChanged = (page: { selected: number }) => {
    setCurrentPage(page.selected);
    productSubscriptionSelection.setAllSelected(false);
  };

  return (
    <div>
      <Panel
        headerText={`${
          props.selectedProductListEntry?.displayName ?? ""
        } - Product Subscriptions`}
        isOpen={props.visible}
        onDismiss={() => {
          setSearchText("");
          setSelectedProductSubscription(undefined);
          props.onDismiss();
        }}
        closeButtonAriaLabel="Close"
        type={PanelType.custom}
        customWidth="1200px"
        layerProps={{ styles: { root: { zIndex: 998 } } }}
        onOuterClick={() => {
          /* ignore */
        }}
      >
        <Loading loading={loading} />
        <Label style={{ fontSize: "20px" }}>
          Environment:{" "}
          <span
            style={{
              color: `${
                ctx.apimEnvironment === "DEV"
                  ? "#1FFF5A"
                  : ctx.apimEnvironment === "TEST"
                  ? "orange"
                  : "#95233A"
              }`
            }}
          >
            {ctx.apimEnvironment}
          </span>
        </Label>
        <div style={{ textAlign: "center", marginTop: 10 }}>
          <SearchBox
            styles={{ root: { width: 300 } }}
            placeholder="Search"
            value={searchText ?? ""}
            onEscape={() => {
              setSearchText("");
              setCurrentPage(0);
            }}
            onClear={() => {
              setSearchText("");
              setCurrentPage(0);
            }}
            onChange={(_, newSearchText) => {
              setSearchText(newSearchText ?? "");
              setCurrentPage(0);
            }}
            onSearch={(newSearchText) => {
              setSearchText(newSearchText ?? "");
              setCurrentPage(0);
            }}
          />
          <CommandBar
            items={commandBarItems}
            styles={{ root: { marginTop: 10, padding: 0 } }}
          />
          <div>
            <MarqueeSelection selection={productSubscriptionSelection}>
              <ShimmeredDetailsList
                setKey="items"
                items={filteredProductSubscriptionsPage.map((sb) => {
                  return {
                    ...sb,
                    company: sb?.company?.name
                  };
                })}
                onRenderItemColumn={renderItemColumnWithoutCompanyWarning}
                onRenderRow={onRenderRowWithCompany}
                columns={columns}
                selectionMode={SelectionMode.single}
                enableShimmer={loading}
                ariaLabelForShimmer="Loading product subscriptions"
                ariaLabelForGrid="Product Subscriptions"
                listProps={{
                  renderedWindowsAhead: 0,
                  renderedWindowsBehind: 0
                }}
                onColumnHeaderClick={onColumnClick}
                selection={productSubscriptionSelection}
                selectionPreservedOnEmptyClick={true}
              />
            </MarqueeSelection>
            <div>
              <ReactPaginate
                previousLabel={"← Previous"}
                nextLabel={"Next →"}
                pageCount={Math.ceil(Math.max(filteredProductSubscriptions.length, 1) / PAGE_SIZE)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                containerClassName={"paginationContainer"}
                previousClassName={currentPage < 1 ? "disabledPagination" : ""}
                nextClassName={
                  currentPage ===
                  Math.ceil(Math.max(filteredProductSubscriptions.length, 1) / PAGE_SIZE) - 1
                    ? "disabledPagination"
                    : ""
                }
                activeClassName={"active"}
                breakLabel={"..."}
                onPageChange={onPageChanged}
                forcePage={currentPage}
              />
            </div>
          </div>
          {!loading &&
            (!currentProductSubscriptions ||
              currentProductSubscriptions.length < 1) && (
              <div>
                <h5>{`No Subscriptions exist for this product in APIM ${ctx.apimEnvironment.toString()}.`}</h5>
              </div>
            )}
          <div>Total: {currentProductSubscriptions.length} Subscriptions</div>
        </div>
        <Dialog
          hidden={changeStateConfirmationMessage.message === ""}
          onDismiss={() =>
            setChangeStateConfirmationMessage({
              title: "",
              message: "",
              newState: ""
            })
          }
          dialogContentProps={{
            type: DialogType.normal,
            title: changeStateConfirmationMessage.title,
            subText: changeStateConfirmationMessage.message
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 800, minWidth: 800 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                setLoading(true);
                setChangeStateConfirmationMessage({
                  title: "",
                  message: "",
                  newState: ""
                });
                ctx.backendClient
                  .updateProductSubscription(
                    props.selectedProductListEntry?.id || "",
                    selectedProductSubscription?.id || "",
                    ctx.apimEnvironment,
                    selectedProductSubscription?.displayName || "",
                    changeStateConfirmationMessage.newState,
                    selectedProductSubscription?.company?.id
                  )
                  .then((_) => {
                    Logger.Success(
                      `Subscription is now ${changeStateConfirmationMessage.newState}`
                    );
                    loadProductSubscriptions();
                  })
                  .catch((err) => Logger.Error(err))
                  .finally(() => {
                    setLoading(false);
                  });
              }}
              text="Change state"
            />
            <DefaultButton
              onClick={() => {
                setChangeStateConfirmationMessage({
                  title: "",
                  message: "",
                  newState: ""
                });
              }}
              text="Cancel"
            />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isDeleteSubscriptionConfirmationDialogVisible}
          onDismiss={() =>
            setIsDeleteSubscriptionConfirmationDialogVisible(false)
          }
          dialogContentProps={{
            type: DialogType.normal,
            title: "Confirm Subscription Deletion",
            subText: `Are you sure you want to delete this ${
              selectedProductSubscription?.state ?? ""
            } product subscription requested by ${
              selectedProductSubscription?.email
            }?`
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 800, minWidth: 800 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                setLoading(true);
                setIsDeleteSubscriptionConfirmationDialogVisible(false);
                ctx.backendClient
                  .deleteProductSubscription(
                    props.selectedProductListEntry?.id ?? "",
                    selectedProductSubscription?.id ?? "",
                    ctx.apimEnvironment
                  )
                  .then((_) => {
                    Logger.Success("Subscription successfully deleted");
                    loadProductSubscriptions();
                  })
                  .catch((err) => {
                    Logger.Error(err);
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
              text="Delete subscription"
            />
            <DefaultButton
              onClick={() => {
                setIsDeleteSubscriptionConfirmationDialogVisible(false);
              }}
              text="Cancel"
            />
          </DialogFooter>
        </Dialog>
      </Panel>
      <EditProductSubscriptionPanel
        visible={editProductSubscriptionPanelVisible}
        onDismiss={() => {
          setEditProductSubscriptionPanelVisible(false);
        }}
        onSaved={(savedProductSubscription) => {
          const updatedProductSubscriptions = [...currentProductSubscriptions];
          const indexToUpdate = updatedProductSubscriptions.findIndex(
            (ps) => ps.id === savedProductSubscription.id
          );
          updatedProductSubscriptions[indexToUpdate] = savedProductSubscription;
          setCurrentProductSubscriptions(updatedProductSubscriptions);
        }}
        selectedSubscriptionListEntry={currentProductSubscriptions.find(
          (x) => x.id === selectedProductSubscription?.id
        )}
        selectedProductListEntry={props.selectedProductListEntry}
      />
    </div>
  );
};
