import {
  buildColumns,
  CommandBar,
  DetailsListLayoutMode,
  Dropdown,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  IDropdownOption,
  IDropdownStyles,
  Label,
  MarqueeSelection,
  SearchBox,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack
} from "@fluentui/react";
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { context } from "AppContext";
import { copyAndSort, trimLower } from "helpers/HelperFunctions";
import { IComplianceWarning } from "models/IComplianceWarning";
import {
  ComplianceWarningScope,
  ComplianceWarningSeverity,
  ComplianceWarningState
} from "models/ComplianceWarningEnums";
import { Loading } from "components/Core/Loading";
import Logger from "Logger";
import { PAGE_SIZE } from "helpers/const";


interface IComplianceWarningsExceptionRequestsProps {}

export const ComplianceWarningsExceptionRequests = (
  props: IComplianceWarningsExceptionRequestsProps
) => {
  const ctx = React.useContext(context)!;
  const [columns, setColumns] = useState([] as IColumn[]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentComplianceWarnings, setCurrentComplianceWarnings] = useState<
    IComplianceWarning[]
  >([]);
  const [filteredComplianceWarnings, setFilteredComplianceWarnings] = useState<
    IComplianceWarning[]
  >([]);
  const [filteredComplianceWarningsPage, setFilteredComplianceWarningsPage] =
    useState<IComplianceWarning[]>([]);
  const [selectedComplianceWarning, setSelectedComplianceWarning] =
    useState<IComplianceWarning>();

  const complianceWarningSelection: Selection = new Selection({
    onSelectionChanged: () => {
      setSelectedComplianceWarning(
        complianceWarningSelection.getSelection()[0] as any
      );
    }
  }) as any;
  const [searchText, setSearchText] = useState("");
  const [filteredScope, setFilteredScope] = useState<
    ComplianceWarningScope | undefined
  >();
  const [filteredSeverity, setFilteredSeverity] = useState<
    ComplianceWarningSeverity | undefined
  >();
  const [filteredState, setFilteredState] = useState<ComplianceWarningState>(
    ComplianceWarningState.PENDING_EXCEPTION
  );

  useEffect(() => {
    setLoading(true);
    ctx.backendClient
      .getAllComplianceWarningPendingExceptionRequests()
      .then((res) => setCurrentComplianceWarnings(res))
      .catch((error) => Logger.Error(error))
      .finally(() => {
        setLoading(false);
      });
  }, [ctx.backendClient]);

  // apply paging based on current page and pagesize
  useEffect(() => {
    const offset = currentPage * PAGE_SIZE;
    setFilteredComplianceWarningsPage(
      filteredComplianceWarnings.slice(offset, offset + PAGE_SIZE)
    );
  }, [currentPage, filteredComplianceWarnings]);

  const filterDropdownStylesEmpty: Partial<IDropdownStyles> = {
    dropdown: { width: 200 }
  };
  const filterDropdownStylesFiltered: Partial<IDropdownStyles> = {
    dropdown: { width: 200 },
    title: { backgroundColor: "#edebe9" }
  };
  const [loading, setLoading]: any = useState<boolean>(false);

  // search and filtering
  useEffect(() => {
    if (currentComplianceWarnings) {
      const newfilteredProductApis = currentComplianceWarnings
        .slice()
        .filter((complianceWarning) => {
          if (
            (trimLower(complianceWarning?.message ?? "").indexOf(
              trimLower(searchText)
            ) === -1 &&
              trimLower(complianceWarning?.exceptionRequestReason ?? "").indexOf(
                trimLower(searchText)
              ) === -1 &&
              trimLower(complianceWarning?.exceptionRequestorEmail ?? "").indexOf(
                trimLower(searchText)
              ) === -1 &&
              trimLower(complianceWarning?.apiDisplayName ?? "").indexOf(
                trimLower(searchText)
              ) === -1 &&
              trimLower(complianceWarning?.productId ?? "").indexOf(
                trimLower(searchText)
              ) === -1) ||
            (filteredSeverity &&
              filteredSeverity !== complianceWarning.severity) ||
            (filteredScope && filteredScope !== complianceWarning.scope) ||
            (filteredState && filteredState !== complianceWarning.state)
          ) {
            return false;
          }
          return true;
        });
      setFilteredComplianceWarnings(newfilteredProductApis);
      setCurrentPage(0);
      complianceWarningSelection.setAllSelected(false);
      setSelectedComplianceWarning(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchText,
    currentComplianceWarnings,
    filteredSeverity,
    filteredScope,
    filteredState
  ]);

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "approve",
      text: "Approve exception",
      iconProps: { iconName: "CheckMark" },
      onClick: () => {
        if (selectedComplianceWarning) {
          setLoading(true);
          ctx.backendClient
            .approveComplianceWarningExceptionRequest(
              selectedComplianceWarning?.productId,
              selectedComplianceWarning.id
            )
            .then((complianceWarning) => {
              let newCurrentComplianceWarnings = [...currentComplianceWarnings];
              const indexToUpdate = newCurrentComplianceWarnings.findIndex(
                (cw) => cw.id === complianceWarning.id
              );
              newCurrentComplianceWarnings[indexToUpdate] = complianceWarning;
              setCurrentComplianceWarnings(newCurrentComplianceWarnings);
              Logger.Success(
                "The compliance warning exception request has been successfully approved."
              );
            })
            .catch((error) => Logger.Error(error))
            .finally(() => {
              setLoading(false);
            });
        }
      },
      disabled:
        selectedComplianceWarning === undefined ||
        selectedComplianceWarning.state !==
          ComplianceWarningState.PENDING_EXCEPTION
    },
    {
      key: "reject",
      text: "Reject exception",
      iconProps: { iconName: "CalculatorMultiply" },
      onClick: () => {
        if (selectedComplianceWarning) {
          setLoading(true);
          ctx.backendClient
            .rejectComplianceWarningExceptionRequest(
              selectedComplianceWarning?.productId,
              selectedComplianceWarning.id
            )
            .then((complianceWarning) => {
              let newCurrentComplianceWarnings = [...currentComplianceWarnings];
              const indexToUpdate = newCurrentComplianceWarnings.findIndex(
                (cw) => cw.id === complianceWarning.id
              );
              newCurrentComplianceWarnings[indexToUpdate] = complianceWarning;
              setCurrentComplianceWarnings(newCurrentComplianceWarnings);
              Logger.Success(
                "The compliance warning exception request has been rejected."
              );
            })
            .catch((error) => Logger.Error(error))
            .finally(() => {
              setLoading(false);
            });
        }
      },
      disabled:
        selectedComplianceWarning === undefined ||
        selectedComplianceWarning.state !==
          ComplianceWarningState.PENDING_EXCEPTION
    },
    {
      key: "revoke",
      text: "Revoke exception",
      iconProps: { iconName: "Blocked2" },
      onClick: () => {
        if (selectedComplianceWarning) {
          setLoading(true);
          ctx.backendClient
            .revokeComplianceWarningExceptionRequest(
              selectedComplianceWarning?.productId,
              selectedComplianceWarning.id
            )
            .then((complianceWarning) => {
              let newCurrentComplianceWarnings = [...currentComplianceWarnings];
              const indexToUpdate = newCurrentComplianceWarnings.findIndex(
                (cw) => cw.id === complianceWarning.id
              );
              newCurrentComplianceWarnings[indexToUpdate] = complianceWarning;
              setCurrentComplianceWarnings(newCurrentComplianceWarnings);
              Logger.Success(
                "The compliance warning exception request has been revoked."
              );
            })
            .catch((error) => Logger.Error(error))
            .finally(() => {
              setLoading(false);
            });
        }
      },
      disabled:
        selectedComplianceWarning === undefined ||
        selectedComplianceWarning.state !==
          ComplianceWarningState.APPROVED_EXCEPTION
    }
  ];

  // build DetailsList columns
  useEffect(() => {
    let newColumns = buildColumns(currentComplianceWarnings ?? []);
    newColumns = newColumns.filter(
      (column) =>
        column.key !== "id" &&
        column.key !== "apiId" &&
        column.key !== "operationId" &&
        column.key !== "solution" &&
        column.key !== "exceptionApproverRejectorEmail" &&
        column.key !== "exceptionApprovedRejectedDate"
    );
    for (const column of newColumns) {
      column.isResizable = true;
      switch (column.key) {
        case "scope":
          column.name = "Scope";
          column.maxWidth = 80;
          column.className = "2";
          break;
        case "productId":
          column.name = "Product";
          column.maxWidth = 150;
          column.className = "3";
          break;
        case "apiDisplayName":
          column.name = "API";
          column.maxWidth = 150;
          break;
        case "apiVersion":
          column.name = "Version";
          column.maxWidth = 40;
          break;
        case "operationDisplayName":
          column.name = "Operation";
          column.maxWidth = 150;
          break;
        case "severity":
          column.name = "Severity";
          column.maxWidth = 120;
          column.className = "1";
          break;
        case "message":
          column.name = "Message";
          column.minWidth = 100;
          column.minWidth = 150;
          break;
        case "solution":
          column.name = "Solution";
          break;
        case "state":
          column.name = "State";
          column.className = "4";
          column.maxWidth = 80;
          break;
        case "exceptionRequestReason":
          column.name = "Reason";
          column.maxWidth = 120;
          break;
        case "exceptionRequestorEmail":
          column.name = "Requestor";
          column.maxWidth = 100;
          break;
        case "exceptionRequestCreatedDate":
          column.name = "Date Requested";
          column.maxWidth = 150;
          break;
      }
    }
    newColumns.sort((a, b) => +(a.className ?? 1000) - +(b.className ?? 1000));
    setColumns(newColumns);
  }, [
    columns.length,
    ctx.complianceWarningsForProducts,
    currentComplianceWarnings
  ]);

  const onColumnClick = (
    ev: React.MouseEvent<HTMLElement> | undefined,
    column: IColumn | undefined
  ): void => {
    if (column && filteredComplianceWarnings) {
      const newColumns: IColumn[] = [...columns];
      const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key
      )[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
      const newlySortedProductApis = copyAndSort(
        filteredComplianceWarnings,
        currColumn.fieldName!,
        currColumn.isSortedDescending
      );
      setColumns(newColumns);
      setFilteredComplianceWarnings(newlySortedProductApis);
      complianceWarningSelection.setAllSelected(false);
      setSelectedComplianceWarning(undefined);
    }
  };

  const onPageChanged = (page: { selected: number }) => {
    setCurrentPage(page.selected);
  };

  const renderItemColumn = (
    item: IComplianceWarning,
    index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (column) {
      const fieldContent = item[
        column.fieldName as keyof IComplianceWarning
      ] as string;
      switch (column.key) {
        case "scope":
          return <span>{ComplianceWarningScope[+fieldContent]}</span>;
        case "severity":
          return (
            <Stack horizontal>
              <IconButton
                key={`ComplianceWarningButton-${item.id}`}
                iconProps={{
                  iconName:
                    item.severity === ComplianceWarningSeverity.CRITICAL
                      ? "AlertSolid"
                      : item.severity === ComplianceWarningSeverity.WARNING
                      ? "Warning"
                      : "Info"
                }}
                style={{
                  color:
                    item.severity === ComplianceWarningSeverity.CRITICAL
                      ? "#fc0303"
                      : item.severity === ComplianceWarningSeverity.WARNING
                      ? "#fc7f03"
                      : "rgb(0, 51, 102"
                }}
                styles={{ icon: { fontSize: 25 } }}
                ariaLabel={`Compliance`}
                onClick={() => {}}
              />
              {ComplianceWarningSeverity[+fieldContent]}
            </Stack>
          );
        case "state":
          return <span>{ComplianceWarningState[+fieldContent]}</span>;
        case "message":
          column.isMultiline = true;
          return <span>{fieldContent}</span>;
        case "exceptionRequestReason":
          column.isMultiline = true;
          return <span>{fieldContent}</span>;
        case "solution":
          column.isMultiline = true;
          return <span>{fieldContent}</span>;
        default:
          return <span>{fieldContent}</span>;
      }
    }
    return <span></span>;
  };

  return (
    <div>
      <Loading loading={loading} />
      <Stack
        horizontal
        tokens={{
          childrenGap: 10
        }}
      >
        <SearchBox
          styles={{ root: { width: 300 } }}
          placeholder="Search"
          value={searchText ?? ""}
          onEscape={() => {
            setSearchText("");
            setCurrentPage(0);
          }}
          onClear={() => {
            setSearchText("");
            setCurrentPage(0);
          }}
          onChange={(_, newSearchText) => {
            setSearchText(newSearchText ?? "");
            setCurrentPage(0);
          }}
          onSearch={(newSearchText) => {
            setSearchText(newSearchText ?? "");
            setCurrentPage(0);
          }}
        />
        <Dropdown
          placeholder="Filter Scope"
          selectedKey={filteredScope}
          onChange={(
            event: React.FormEvent<HTMLDivElement>,
            item: IDropdownOption | undefined
          ): void => {
            if (item) {
              setFilteredScope(item.key as number);
            }
          }}
          options={[
            { key: 0, text: "All" },
            { key: ComplianceWarningScope.PRODUCT, text: "Product" },
            { key: ComplianceWarningScope.API, text: "API" },
            {
              key: ComplianceWarningScope.API_OPERATION,
              text: "API Operation"
            }
          ]}
          styles={
            filteredScope !== undefined
              ? filterDropdownStylesFiltered
              : filterDropdownStylesEmpty
          }
        />
        <Dropdown
          placeholder="Filter Severity"
          selectedKey={filteredSeverity}
          onChange={(
            event: React.FormEvent<HTMLDivElement>,
            item: IDropdownOption | undefined
          ): void => {
            if (item) {
              setFilteredSeverity(item.key as number);
            }
          }}
          options={[
            { key: 0, text: "All" },
            { key: ComplianceWarningSeverity.CRITICAL, text: "Critical" },
            { key: ComplianceWarningSeverity.WARNING, text: "Warning" }
          ]}
          styles={
            filteredSeverity !== undefined
              ? filterDropdownStylesFiltered
              : filterDropdownStylesEmpty
          }
        />
        <Label>Filter State:</Label>
        <Dropdown
          placeholder="Filter State"
          selectedKey={filteredSeverity}
          onChange={(
            event: React.FormEvent<HTMLDivElement>,
            item: IDropdownOption | undefined
          ): void => {
            if (item) {
              setFilteredState(item.key as number);
            }
          }}
          options={[
            {
              key: ComplianceWarningState.PENDING_EXCEPTION,
              text: "Pending Exception",
              selected: true
            },
            {
              key: ComplianceWarningState.APPROVED_EXCEPTION,
              text: "Approved Exception"
            }
          ]}
          styles={
            filteredState !== undefined
              ? filterDropdownStylesFiltered
              : filterDropdownStylesEmpty
          }
        />
      </Stack>
      <CommandBar
        items={commandBarItems}
        styles={{ root: { marginTop: 10, padding: 0 } }}
      />
      <div>
        <MarqueeSelection selection={complianceWarningSelection}>
          <ShimmeredDetailsList
            setKey="items"
            items={filteredComplianceWarningsPage}
            onRenderItemColumn={renderItemColumn}
            columns={columns}
            selectionMode={SelectionMode.single}
            ariaLabelForShimmer="Loading compliance warnings"
            ariaLabelForGrid="Compliance Warnings"
            listProps={{
              renderedWindowsAhead: 0,
              renderedWindowsBehind: 0
            }}
            onColumnHeaderClick={onColumnClick}
            selection={complianceWarningSelection}
            selectionPreservedOnEmptyClick={true}
            layoutMode={DetailsListLayoutMode.fixedColumns}
          />
        </MarqueeSelection>
        <div style={{ textAlign: "center", marginTop: 10 }}>
          <ReactPaginate
            previousLabel={"← Previous"}
            nextLabel={"Next →"}
            pageCount={Math.ceil(Math.max(filteredComplianceWarnings.length, 1) / PAGE_SIZE)}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            containerClassName={"paginationContainer"}
            previousClassName={currentPage < 1 ? "disabledPagination" : ""}
            nextClassName={
              currentPage ===
              Math.ceil(Math.max(filteredComplianceWarnings.length, 1) / PAGE_SIZE) - 1
                ? "disabledPagination"
                : ""
            }
            activeClassName={"active"}
            breakLabel={"..."}
            onPageChange={onPageChanged}
            forcePage={currentPage}
          />
        </div>
      </div>
      {(!currentComplianceWarnings || currentComplianceWarnings.length < 1) && (
        <div>
          <h5>{`No compliance warning exception requests found.`}</h5>
        </div>
      )}
      <div style={{ textAlign: "center", marginTop: 10 }}>
        Total: {currentComplianceWarnings?.length ?? 0} compliance warning
        exception request(s)
      </div>
    </div>
  );
};
