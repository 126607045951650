
export const copyAndSort = <T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
): T[] => {
    const key = columnKey as keyof T;
    return items
        .slice(0)
        .sort((a: T, b: T) =>
            (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
        );
};

export const trimLower = (text: string) => {
    return text?.toLowerCase().trim();
};