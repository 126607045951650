import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import { context } from 'AppContext';
import Logger from 'Logger';
import { TextField, Stack, IconButton, IStackTokens } from '@fluentui/react';
import { Loading } from 'components/Core/Loading';
import CopyToClipboard from 'react-copy-to-clipboard';

interface ClientSecretProps {
  authenticated: boolean;
}

export const ClientSecret = (props: ClientSecretProps) => {
  const ctx = React.useContext(context)!;
  const { clientSecretName }: any = useParams();
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);

  const loadSecretFromBackend = useCallback(() => {
    const loadDependencies = async () => {
      let loadingSecret: any = {};
      if (props.authenticated) {
        loadingSecret = ctx.backendClient
          .getClientSecret(clientSecretName)
          .then((res) => {
            setClientSecret(res);
          })
          .catch((err) => Logger.Error(err));
      } else {
        loadingSecret = ctx.backendClient
          .getClientSecretAnonymous(clientSecretName)
          .then((res) => {
            setClientSecret(res);
          })
          .catch((err) => Logger.Error(err));
      }
      Promise.all([loadingSecret]).then(() => setLoading(false));
    };
    loadDependencies();
  }, [clientSecretName, props.authenticated, ctx.backendClient]);

  useEffect(() => {
    loadSecretFromBackend();
  }, [loadSecretFromBackend]);

  const stackTokens: IStackTokens = {
    childrenGap: 4
  };

  return (
    <div>
      <Loading loading={loading} />
      <h3>Get client secret (app secret)</h3>
      <p>
        Here is the client secret for the client app registration that you
        requested, please store it safely.
      </p>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-lg3 ms-md8 ms-sm12">
          <Stack horizontal verticalAlign="end" tokens={stackTokens}>
            <TextField
              disabled
              value={clientSecret}
              onChange={() => {}}
              label="Client Secret"
              style={{ minWidth: '330px' }}
            />
            <CopyToClipboard
              text={clientSecret}
              onCopy={() => {
                setCopied(true);
              }}
            >
              {
                <div>
                  {(copied && <span>Copied!</span>) || (
                    <IconButton
                      id="CopyToClipBoardButton"
                      iconProps={{ iconName: 'Copy' }}
                      title="Copy to Clipboard"
                      ariaLabel="Copy to Clipboard"
                      onClick={() => {}}
                    />
                  )}
                </div>
              }
            </CopyToClipboard>
          </Stack>
        </div>
      </div>
    </div>
  );
};
