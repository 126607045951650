import { useState, useEffect, useContext } from "react";
import {
  Panel,
  PanelType,
  TextField,
  Toggle,
  Dropdown,
  PrimaryButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDropdownOption,
  ITextFieldProps,
  Stack
} from "@fluentui/react";
import {
  ProductCategory,
  ProductCategoryToString,
  ProductConfidentiality,
  ProductConfidentialityToString,
  ProductType,
  ProductTypeToString,
  ProductBackendHosting,
  ProductBackendHostingToString,
  ProductBackendRouting,
  ProductBackendRoutingToString,
  ProductAuthorization,
  ProductAuthorizationToString
} from "models/ProductMetadataEnums";
import { IProduct, ProductState } from "models/IProduct";
import { InfoLabelButton } from "components/Core/InfoLabelButton";
import { Loading } from "components/Core/Loading";
import { context } from "AppContext";
import Logger from "Logger";
import { toast } from "react-toastify";
import { mapEnumToDropDownValues } from "components/Core/DropDownUtils";

interface IProductDetailsProps {
  visible: boolean;
  onDismiss: () => void;
  onSave: (product: IProduct) => void;
  product?: IProduct;
  loading: boolean;
}

type EnumType = { [s: number]: string };

const getEnumKeys = (enumerable: EnumType) => {
  return Object.keys(enumerable)
    .filter((k) => !isNaN(+k))
    .map((k) => +k);
};

export const ProductFormPanel = (props: IProductDetailsProps) => {
  const ctx = useContext(context)!;
  const [touched, setTouched] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = useState<IProduct>();

  const [
    isConfirmationCloseDialogVisible,
    setIsConfirmationCloseDialogVisible
  ] = useState(false);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    if (props.product) {
      setCurrentProduct({ ...props.product });
    }
  }, [props.product]);

  const getErrorMessage = (fieldName: string) => {
    if (props.loading) {
      return "";
    }
    const guidPattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    let message = "";
    switch (fieldName) {
      case "all":
      // falls through
      case "backendAppIdForProdApim":
        if (
          currentProduct?.backendAppIdForProdApim &&
          !guidPattern.test(currentProduct?.backendAppIdForProdApim!)
        ) {
          message = "PROD Backend AppId is not a valid AppId (Guid)";
          break;
        }
        if (fieldName !== "all") {
          break;
        }
      // falls through
      case "backendAppIdForTestApim":
        if (
          currentProduct?.backendAppIdForTestApim &&
          !guidPattern.test(currentProduct?.backendAppIdForTestApim!)
        ) {
          message = "TEST Backend AppId is not a valid AppId (Guid)";
          break;
        }
        if (fieldName !== "all") {
          break;
        }
      // falls through
      case "backendAppIdForDevApim":
        if (
          currentProduct?.backendAppIdForDevApim &&
          !guidPattern.test(currentProduct?.backendAppIdForDevApim!)
        ) {
          message = "DEV Backend AppId is not a valid AppId (Guid)";
          break;
        }
        if (fieldName !== "all") {
          break;
        }
      // falls through
      case "description":
        if (!currentProduct?.description) {
          message = "Product description is required";
          break;
        }
        if (fieldName !== "all") {
          break;
        }
      // falls through
      case "displayName":
        if (!currentProduct?.displayName) {
          message = "Product name is required";
          break;
        }
    }
    return message;
  };

  const saveProductMetadata = () => {
    const validationErrorMessage = getErrorMessage("all");
    if (!validationErrorMessage) {
      setLoading(true);
      ctx.backendClient
        .updateProduct(currentProduct!.id, currentProduct!, ctx.apimEnvironment)
        .then(() => {
          setTouched(false);
          setIsConfirmationCloseDialogVisible(false);
          toast.success("Successfully updated Product")
          props.onSave(currentProduct!);
          props.onDismiss();
        })
        .catch((error) => Logger.Error(error))
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error(validationErrorMessage, { autoClose: 15000 });
    }
  };

  const onRenderLabelInfoButton = (
    textFieldProps: ITextFieldProps | undefined
  ) => <InfoLabelButton id="test" {...textFieldProps} />;

  return (
    <div>
      <Panel
        headerText={props.product?.displayName}
        isOpen={props.visible}
        onDismiss={() => {
          if (!touched) {
            props.onDismiss();
          } else {
            setIsConfirmationCloseDialogVisible(true);
          }
        }}
        closeButtonAriaLabel="Close"
        type={PanelType.medium}
        layerProps={{ styles: { root: { zIndex: 998 } } }}
        onOuterClick={() => {}}
      >
        <Loading loading={loading || false} />
        <hr />
        <h5>
          Product data from{" "}
          <span
            style={{
              color: `${
                ctx.apimEnvironment === "DEV"
                  ? "#1FFF5A"
                  : ctx.apimEnvironment === "TEST"
                  ? "orange"
                  : "#95233A"
              }`
            }}
          >
            {ctx.apimEnvironment}
          </span>{" "}
          Azure APIM
        </h5>
        <TextField
          label="Name "
          required
          value={currentProduct?.displayName || ""}
          onChange={(e) => {
            setCurrentProduct({
              ...currentProduct!,
              displayName: (e.target as HTMLInputElement).value
            });
            setTouched(true);
          }}
          onGetErrorMessage={() => getErrorMessage("displayName")}
          summary={`The name of the product as also seen in the Azure portal (is also synchronized when changed in Azure portal).`}
          onRenderLabel={onRenderLabelInfoButton}
        />
        <TextField
          label="Description"
          required
          multiline
          autoAdjustHeight
          value={currentProduct?.description || ""}
          onChange={(e) => {
            setCurrentProduct({
              ...currentProduct!,
              description: (e.target as HTMLInputElement).value
            });
            setTouched(true);
          }}
          onGetErrorMessage={() => getErrorMessage("description")}
          summary={`The description of the product as also seen in the Azure portal (is also synchronized when changed in Azure portal).`}
          onRenderLabel={onRenderLabelInfoButton}
        />
        <Toggle
          label={
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{
                childrenGap: 4,
                maxWidth: 300
              }}
            >
              <span>State</span>
              <InfoLabelButton
                id="productStateInfoButton"
                summary={`Shows if the product is published for usage in the developer portal or not.`}
              />
            </Stack>
          }
          onText="Published"
          offText="Not Published"
          onChange={(_, checked) => {
            setCurrentProduct({
              ...currentProduct!,
              state: checked
                ? ProductState.Published
                : ProductState.NotPublished
            });
            setTouched(true);
          }}
          checked={currentProduct?.state === ProductState.Published}
        />
        <TextField
          label="Subscription Limit"
          value={
            currentProduct?.subscriptionsLimit &&
            currentProduct.subscriptionRequired
              ? currentProduct?.subscriptionsLimit?.toString()
              : ""
          }
          disabled={!currentProduct?.subscriptionRequired}
          onChange={(e) => {
            setCurrentProduct({
              ...currentProduct!,
              subscriptionsLimit: isNaN(+(e.target as HTMLInputElement).value)
                ? undefined
                : +(e.target as HTMLInputElement).value
            });
            setTouched(true);
          }}
          onGetErrorMessage={() => getErrorMessage("subscriptionLimit")}
          summary={`Defines how many subscription keys can be issued for that product as seen in the Azure portal (is also synchronized when changed in Azure portal).`}
          onRenderLabel={onRenderLabelInfoButton}
        />
        <TextField
          label="Legal Terms"
          multiline
          autoAdjustHeight
          value={currentProduct?.terms || ""}
          onChange={(e) => {
            setCurrentProduct({
              ...currentProduct!,
              terms: (e.target as HTMLInputElement).value
            });
            setTouched(true);
          }}
          summary={`The legal terms of the product as also seen in the Azure portal (is also synchronized when changed in Azure portal).`}
          onRenderLabel={onRenderLabelInfoButton}
        />
        <hr />
        <h5>Global product metadata</h5>
        <Dropdown
          label="Category"
          selectedKey={currentProduct?.category}
          onChange={(_, option) => {
            setCurrentProduct({
              ...currentProduct!,
              category: +option?.key!
            });
            setTouched(true);
          }}
          options={mapEnumToDropDownValues(
            ProductCategory,
            ProductCategoryToString
          )}
          onRenderLabel={() => {
            return (
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{
                  childrenGap: 4,
                  maxWidth: 300
                }}
              >
                <span>Category</span>
                <InfoLabelButton
                  id="CategoryInfoButton"
                  summary={`Categorization of APIs in the product (take the most critical when multiple APIs are present in the product).
                  Normal: It is no big problem if the API(s) in the product are not available for a few hours or even a day.
                  Important: The API(s) in the product should not be unavailable for more than 3-4 hours.
                  Business Critical: The API(s) in the product are critical to the OMV running business and should not be unavailable for more than 30min`}
                />
              </Stack>
            );
          }}
        />
        <Dropdown
          label="Confidentiality"
          selectedKey={currentProduct?.confidentiality}
          onChange={(_, option) => {
            setCurrentProduct({
              ...currentProduct!,
              confidentiality: +option?.key!
            });
            setTouched(true);
          }}
          options={mapEnumToDropDownValues(
            ProductConfidentiality,
            ProductConfidentialityToString
          )}
          onRenderLabel={() => {
            return (
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{
                  childrenGap: 4,
                  maxWidth: 300
                }}
              >
                <span>Confidentiality</span>
                <InfoLabelButton
                  id="ConfidentialityInfoButton"
                  summary={`Confidentiality of APIs in the product (take the most confidential when multiple APIs are present in the product). 
                  Public: It is no problem if the data would be exposed to anyone (internal or external). 
                  Confidential: Business confidential data that should not be exposed to any unauthorized persona. 
                  Strictly Confidential: Highly critical data that should never be exposed to anyone unauthorized, e.g. GDPR-relevant data, credit card or any other payment data, strict company secrets, etc.`}
                />
              </Stack>
            );
          }}
        />
        <Dropdown
          label="Type"
          selectedKeys={
            currentProduct?.type
              ? getEnumKeys(ProductType).filter(
                  (sk) => currentProduct.type! & sk
                )
              : undefined
          }
          multiSelect
          onChange={(_, option: IDropdownOption | undefined): void => {
            if (option) {
              let updatedType = currentProduct?.type ?? 0;
              updatedType = option.selected
                ? updatedType | +option.key
                : updatedType ^ +option.key;
              setCurrentProduct({
                ...currentProduct!,
                type: updatedType !== 0 ? updatedType : undefined
              });
              setTouched(true);
            }
          }}
          options={mapEnumToDropDownValues(ProductType, ProductTypeToString)}
          onRenderLabel={() => {
            return (
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{
                  childrenGap: 4,
                  maxWidth: 300
                }}
              >
                <span>Type</span>
                <InfoLabelButton
                  id="TypeInfoButton"
                  summary={`Type of APIs in the product (refers to the path of the API in the Azure APIM starting with '/public', '/partner' or only '/' for internal APIs). Please select more than one option if you have multiple types of APIs in this product.`}
                />
              </Stack>
            );
          }}
        />
        <Dropdown
          label="Backends hosted"
          selectedKeys={
            currentProduct?.backendHosting
              ? getEnumKeys(ProductBackendHosting).filter(
                  (sk) => currentProduct.backendHosting! & sk
                )
              : undefined
          }
          multiSelect
          onChange={(_, option: IDropdownOption | undefined): void => {
            if (option) {
              let updatedBackendHosting = currentProduct?.backendHosting ?? 0;
              updatedBackendHosting = option.selected
                ? updatedBackendHosting | +option.key
                : updatedBackendHosting ^ +option.key;
              setCurrentProduct({
                ...currentProduct!,
                backendHosting:
                  updatedBackendHosting !== 0
                    ? updatedBackendHosting
                    : undefined
              });
              setTouched(true);
            }
          }}
          options={mapEnumToDropDownValues(
            ProductBackendHosting,
            ProductBackendHostingToString
          )}
          onRenderLabel={() => {
            return (
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{
                  childrenGap: 4,
                  maxWidth: 300
                }}
              >
                <span>Backends hosted</span>
                <InfoLabelButton
                  id="BackendsHostedInfoButton"
                  summary={`Defines where the backends of all APIs in the products are hosted. Int can be 'Onprem' (within the OMV datacenter(s)), 'Internet' (e.g. in the cloud) or both when backends of APIs in the product are hosted onprem and on the internet.`}
                />
              </Stack>
            );
          }}
        />
        <Dropdown
          label="Backends routed"
          selectedKeys={
            currentProduct?.backendRouting
              ? getEnumKeys(ProductBackendRouting).filter(
                  (sk) => currentProduct.backendRouting! & sk
                )
              : undefined
          }
          multiSelect
          onChange={(_, option: IDropdownOption | undefined): void => {
            if (option) {
              let updatedBackendRouting = currentProduct?.backendRouting ?? 0;
              updatedBackendRouting = option.selected
                ? updatedBackendRouting | +option.key
                : updatedBackendRouting ^ +option.key;
              setCurrentProduct({
                ...currentProduct!,
                backendRouting:
                  updatedBackendRouting !== 0
                    ? updatedBackendRouting
                    : undefined
              });
              setTouched(true);
            }
          }}
          options={mapEnumToDropDownValues(
            ProductBackendRouting,
            ProductBackendRoutingToString
          )}
          onRenderLabel={() => {
            return (
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{
                  childrenGap: 4,
                  maxWidth: 300
                }}
              >
                <span>Backends routed</span>
                <InfoLabelButton
                  id="BackendsRoutedInfoButton"
                  summary={`Defines how routing is working in APIs contained in the product. Can be 'Configuration' if the global configuration field 'Service URL' is used from the API definition, 'Policy' when backends are set in any APIs policy (e.g. with <set-backend-service>) or can be both when a combination is used.`}
                />
              </Stack>
            );
          }}
        />
        <Dropdown
          label="Authorization"
          selectedKeys={
            currentProduct?.authorization
              ? getEnumKeys(ProductAuthorization).filter(
                  (sk) => currentProduct.authorization! & sk
                )
              : undefined
          }
          multiSelect
          onChange={(_, option: IDropdownOption | undefined): void => {
            if (option) {
              let updatedAuthorization = currentProduct?.authorization ?? 0;
              updatedAuthorization = option.selected
                ? updatedAuthorization | +option.key
                : updatedAuthorization ^ +option.key;
              setCurrentProduct({
                ...currentProduct!,
                authorization:
                  updatedAuthorization !== 0 ? updatedAuthorization : undefined
              });
              setTouched(true);
            }
          }}
          options={mapEnumToDropDownValues(
            ProductAuthorization,
            ProductAuthorizationToString
          )}
          onRenderLabel={() => {
            return (
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{
                  childrenGap: 4,
                  maxWidth: 300
                }}
              >
                <span>Authorization</span>
                <InfoLabelButton
                  id="AuthorizationInfoButton"
                  summary={`Defines which client authorization types the APIs in the product support. Can be multiple if APIs in the product support different authorization mechanisms. If you have chosen that 'OAuth' is supported please make sure that you also configured the Backend AppId(s) used for your environments`}
                />
              </Stack>
            );
          }}
        />
        <Toggle
          label={
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{
                childrenGap: 4,
                maxWidth: 300
              }}
            >
              <span>Is using Azure KeyVault </span>
              <InfoLabelButton
                id="isUsingAzureKeyVaultInfoButton"
                summary={`Is the product policy or at least one of the APIs policies that are contained in the product using any Azure KeyVault to get secrets, keys or certificates? `}
              />
            </Stack>
          }
          onText="Yes"
          offText="No"
          onChange={(_, checked) => {
            setCurrentProduct({
              ...currentProduct!,
              isUsingKeyVault: checked ?? false
            } as any);
            setTouched(true);
          }}
          checked={currentProduct?.isUsingKeyVault}
        />
        <PrimaryButton
          text="Save"
          onClick={saveProductMetadata}
          allowDisabledFocus
          disabled={!touched}
          styles={{ root: { marginTop: 20 } }}
        />
        <DefaultButton
          text="Cancel"
          onClick={() => {
            if (!touched) {
              props.onDismiss();
            } else {
              setIsConfirmationCloseDialogVisible(true);
            }
          }}
          allowDisabledFocus
          styles={{ root: { marginTop: 20, marginLeft: 20 } }}
        />
      </Panel>
      <Dialog
        hidden={!isConfirmationCloseDialogVisible}
        onDismiss={() => setIsConfirmationCloseDialogVisible(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title:
            "Are you sure you want to close the product metadata, all unsaved changes will be lost?"
        }}
        modalProps={{
          isBlocking: true,
          styles: { main: { maxWidth: 450 } }
        }}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setCurrentProduct({ ...props.product! });
              setTouched(false);
              setIsConfirmationCloseDialogVisible(false);
              props.onDismiss();
            }}
            text="Yes"
          />
          <DefaultButton
            onClick={() => {
              setIsConfirmationCloseDialogVisible(false);
            }}
            text="No"
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
