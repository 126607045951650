import React, { useState } from "react";
import { context } from "AppContext";
import {
  TextField,
  Stack,
  IStackTokens,
  PrimaryButton,
  DefaultButton
} from "@fluentui/react";

interface AddAccessToExistingClientFormNavigationProps {
  productId: string | undefined;
  productDisplayName: string | undefined;
  onCancel: () => void;
}

export const AddAccessToExistingClientFormNavigation = (
  props: AddAccessToExistingClientFormNavigationProps
) => {
  const ctx = React.useContext(context)!;
  const [clientId, setClientId] = useState<string>("");
  const [notificationEmail, setNotificationEmail] = useState<string>("");
  const stackTokens: IStackTokens = {
    childrenGap: 4
  };

  const getExistingAppIdErrorMessage = () => {
    const guidPattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (clientId !== "" && !guidPattern.test(clientId)) {
      return "Existing client id is not a valid AppId (GUID)";
    }
    return "";
  };

  const getEmailValidationErrorMessage = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(notificationEmail).toLowerCase())) {
      return "";
    }
    return "Email is not valid";
  };

  return (
    <div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-lg3 ms-md8 ms-sm12">
          <Stack tokens={stackTokens}>
            <TextField
              onChange={(e) => {
                setClientId((prevState) =>
                  (e.target as HTMLInputElement).value?.trim()
                );
              }}
              required
              label="Existing client id (app id GUID):"
              style={{ width: "700px" }}
              value={clientId}
              onGetErrorMessage={() => getExistingAppIdErrorMessage()}
            />
            <TextField
              label="Send updated information to Email"
              value={notificationEmail}
              onChange={(e) => {
                setNotificationEmail((prevState) =>
                  (e.target as HTMLInputElement).value?.trim()
                );
              }}
              required
              onGetErrorMessage={() => getEmailValidationErrorMessage()}
            />
          </Stack>
          <Stack horizontal tokens={stackTokens} style={{ marginTop: "10px" }}>
            <PrimaryButton
              onClick={() => {
                window.location.href = `/products/${props.productId}/clientapps/${clientId}/addPermission?addPermissionRequestorMail=${notificationEmail}&apimEnvironment=${ctx.apimEnvironment}`;
              }}
              text="Select Permissions"
              style={{ width: "200px" }}
              disabled={
                !clientId ||
                clientId.trim() === "" ||
                getExistingAppIdErrorMessage().length > 0 ||
                !notificationEmail ||
                notificationEmail.trim() === "" ||
                getEmailValidationErrorMessage().length > 0
              }
            />
            <DefaultButton onClick={() => props.onCancel()} text="Cancel" />
          </Stack>
        </div>
      </div>
    </div>
  );
};
