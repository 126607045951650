import {
  Stack,
  IconButton,
  PrimaryButton,
  IButtonStyles
} from "@fluentui/react";
import { IProduct } from "models/IProduct";
import { useContext } from "react";
import { PuffLoader } from "react-spinners";
import { context } from "AppContext";
import { ComplianceWarningState } from "models/ComplianceWarningEnums";

interface ProductComplianceInfoProps {
  product: IProduct;
  onClick: () => void;
}
export const ProductComplianceInfo = (props: ProductComplianceInfoProps) => {
  const ctx = useContext(context)!;
  const complianceWarningsTotalForProduct =
    ctx.complianceWarningsForProducts?.get(props.product.id);
  const complianceInfosForProduct = ctx.complianceWarningsForProducts
    ?.get(props.product.id)
    ?.filter(
      (cw) =>
        cw.severity === 3 &&
        (cw.state === ComplianceWarningState.OPEN ||
          cw.state === ComplianceWarningState.REJECTED_EXCEPTION)
    );
  const complianceWarningsForProduct = ctx.complianceWarningsForProducts
    ?.get(props.product.id)
    ?.filter(
      (cw) =>
        cw.severity === 2 &&
        (cw.state === ComplianceWarningState.OPEN ||
          cw.state === ComplianceWarningState.REJECTED_EXCEPTION)
    );
  const complianceCriticalErrorsForProduct = ctx.complianceWarningsForProducts
    ?.get(props.product.id)
    ?.filter(
      (cw) =>
        cw.severity === 1 &&
        (cw.state === ComplianceWarningState.OPEN ||
          cw.state === ComplianceWarningState.REJECTED_EXCEPTION)
    );

    const complianceDeploymentBlockingErrors= ctx.complianceWarningsForProducts
    ?.get(props.product.id)
    ?.filter(cw => cw.blocking && 
      (cw.state === ComplianceWarningState.OPEN ||
      cw.state === ComplianceWarningState.REJECTED_EXCEPTION)
    );

  const complianceExceptionsForProduct = ctx.complianceWarningsForProducts
    ?.get(props.product.id)
    ?.filter((cw) => cw.state === ComplianceWarningState.APPROVED_EXCEPTION);

  const warningButtonCountStyles: IButtonStyles = {
    root: {
      width: 15,
      maxWidth: 15,
      minWidth: 15,
      height: 15,
      minHeight: 15,
      padding: 0,
      borderRadius: 15,
      textAlign: "center",
      fontSize: 8,
      position: "relative",
      top: -12,
      left: 15
    },
    label: { maxWidth: 10 }
  };
  return (
    <Stack horizontal>
      {complianceWarningsTotalForProduct === undefined && (
        <PuffLoader color={"#123abc"} loading={true} size={30} />
      )}
      {complianceWarningsTotalForProduct &&
        complianceWarningsTotalForProduct!.length === 0 && (
          <IconButton
            key={`ComplianceOKButton-${props.product.id}`}
            iconProps={{ iconName: "Accept" }}
            style={{ color: "rgb(31, 255, 90)" }}
            ariaLabel={`Compliance`}
            onClick={() => {}}
          />
        )}
      {complianceWarningsTotalForProduct &&
        complianceWarningsTotalForProduct!.length > 0 && (
          <Stack style={{ height: "30px" }}>
            <Stack horizontal>
            {complianceDeploymentBlockingErrors &&
                complianceDeploymentBlockingErrors.length > 0 && (
                  <Stack>
                    <IconButton
                      key={`ComplianceWarningButton-${props.product.id}`}
                      iconProps={{ iconName: "Blocked" }}
                      style={{ color: "#fc0303" }}
                      styles={{ icon: { fontSize: 25 } }}
                      ariaLabel={`ComplianceError`}
                      onClick={() => props.onClick()}
                    />
                    <PrimaryButton
                      onClick={() => props.onClick()}
                      text={"" + complianceDeploymentBlockingErrors.length}
                      styles={warningButtonCountStyles}
                    />
                  </Stack>
                )}
              {complianceCriticalErrorsForProduct &&
                complianceCriticalErrorsForProduct.length > 0 && (
                  <Stack>
                    <IconButton
                      key={`ComplianceWarningButton-${props.product.id}`}
                      iconProps={{ iconName: "AlertSolid" }}
                      style={{ color: "#fc0303" }}
                      styles={{ icon: { fontSize: 25 } }}
                      ariaLabel={`ComplianceError`}
                      onClick={() => props.onClick()}
                    />
                    <PrimaryButton
                      onClick={() => props.onClick()}
                      text={"" + complianceCriticalErrorsForProduct.length}
                      styles={warningButtonCountStyles}
                    />
                  </Stack>
                )}
              {complianceWarningsForProduct &&
                complianceWarningsForProduct.length > 0 && (
                  <Stack>
                    <IconButton
                      key={`ComplianceWarningButton-${props.product.id}`}
                      iconProps={{ iconName: "Warning" }}
                      style={{ color: "#fc7f03" }}
                      styles={{ icon: { fontSize: 25 } }}
                      ariaLabel={`ComplianceWarning`}
                      onClick={() => props.onClick()}
                    />
                    <PrimaryButton
                      onClick={() => props.onClick()}
                      text={"" + complianceWarningsForProduct.length}
                      styles={warningButtonCountStyles}
                    />
                  </Stack>
                )}
              {complianceInfosForProduct &&
                complianceInfosForProduct.length > 0 && (
                  <Stack>
                    <IconButton
                      key={`ComplianceWarningButton-${props.product.id}`}
                      iconProps={{ iconName: "Info" }}
                      style={{ color: "rgb(0, 51, 102)" }}
                      styles={{ icon: { fontSize: 25 } }}
                      ariaLabel={`ComplianceInformation`}
                      onClick={() => props.onClick()}
                    />
                    <PrimaryButton
                      onClick={() => props.onClick()}
                      text={"" + complianceInfosForProduct.length}
                      styles={warningButtonCountStyles}
                    />
                  </Stack>
                )}
              {complianceExceptionsForProduct &&
                complianceExceptionsForProduct.length > 0 && (
                  <Stack>
                    <IconButton
                      key={`ComplianceWarningButton-${props.product.id}`}
                      iconProps={{ iconName: "Accept" }}
                      style={{ color: "rgb(0, 51, 102)" }}
                      styles={{ icon: { fontSize: 25 } }}
                      ariaLabel={`ComplianceException`}
                      onClick={() => props.onClick()}
                    />
                    <PrimaryButton
                      onClick={() => props.onClick()}
                      text={"" + complianceExceptionsForProduct.length}
                      styles={warningButtonCountStyles}
                    />
                  </Stack>
                )}
            </Stack>
          </Stack>
        )}
    </Stack>
  );
};
