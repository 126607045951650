import * as React from 'react';
import {
  ITextFieldProps,
  Stack,
  IconButton,
  Callout,
  DefaultButton,
  IStackTokens,
  IStackStyles,
  IButtonStyles
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';

const stackTokens: IStackTokens = {
  childrenGap: 4,
  maxWidth: 300
};

const iconProps = { iconName: 'Info' };
const labelCalloutStackStyles: Partial<IStackStyles> = {
  root: { padding: 20 }
};
const iconButtonStyles: Partial<IButtonStyles> = { root: { marginBottom: -3 } };

export const InfoLabelButton = (props: ITextFieldProps): JSX.Element => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const descriptionId: string = useId('description');
  const iconButtonId: string = useId('iconButton');
  return (
    <div>
      <Stack horizontal verticalAlign="center" tokens={stackTokens}>
        <span id={props.id}>
          {props.label}
          {'required' in props && props.required && (
            <span style={{ color: 'red' }}> * </span>
          )}
        </span>
        <IconButton
          id={iconButtonId}
          iconProps={iconProps}
          title="Info"
          ariaLabel="Info"
          onClick={toggleIsCalloutVisible}
          styles={iconButtonStyles}
        />
      </Stack>
      {isCalloutVisible && (
        <Callout
          target={'#' + iconButtonId}
          setInitialFocus
          onDismiss={toggleIsCalloutVisible}
          ariaDescribedBy={descriptionId}
          role="alertdialog"
        >
          <Stack
            tokens={stackTokens}
            horizontalAlign="start"
            styles={labelCalloutStackStyles}
          >
            <span id={descriptionId}>{props.summary}</span>
            <DefaultButton onClick={toggleIsCalloutVisible}>
              Close
            </DefaultButton>
          </Stack>
        </Callout>
      )}
    </div>
  );
};
