import React, { useState, useEffect } from "react";
import { Stack, Label } from "@fluentui/react";
import {
    VictoryLine,
    VictoryLegend,
    VictoryChart,
    VictoryGroup,
    VictoryAxis,
} from "victory";

interface DataEntry {
    day: Date;
    info: number;
    warning: number;
    critical: number;
}

interface ILineChartProps {
    size: number;
    toplabel: string;
    data: DataEntry[];
}

export const LineChart = (props: ILineChartProps) => {
    const [data, setData] = useState (
        props.data.map((e) => {
            return { day: new Date(), info: 0, warning: 0, critical: 0 };
        })
    );
    useEffect(() => {
        let data = [...props.data];
        if (props.data && props.data.length < 1) {
            data.push({
                day: new Date() , info: 0, warning: 0, critical: 0
            });
        }
        setData(data);
    }, [props.data]);

    return (
        <Stack
            styles={{
                root: { width: props.size, marginBottom: 10, marginTop: 10}
            }}
        >
            <Label style={{
                margin: 'auto',
                position: 'relative',
                top: 15 + 'px',
                textAlign: 'center',
                fontSize: '1rem',
                fontWeight: 'bold'
            }}> {props.toplabel}</Label>
            <VictoryChart  scale={{ x: "time" }} padding={{ right: 20, left: 55, top: 30, bottom: 50 }} >
                <VictoryGroup >
                    <VictoryLine 
                        domain={{ x: [new Date(new Date().setDate(new Date().getDate() - 6)), new Date()], y: [0, 10] }}
                        style={{
                            data: { stroke: "#1F55DF" }
                        }}
                        data={
                            data.map((e) => {
                                return {
                                    x: new Date(e.day),
                                    y:
                                        e.info
                                };
                            })}
                    />

                    <VictoryLine 
                        style={{
                            data: { stroke: "#e39014" }
                        }}
                        data={
                            data.map((e) => {
                                return {
                                    x: new Date(e.day),
                                    y:
                                        e.warning
                                };
                            })}
                    />

                    <VictoryLine 
                        style={{
                            data: { stroke: "#eb4034" }
                        } }
                        data={
                            data.map((e) => {
                                return {
                                    x: new Date(e.day),
                                    y:
                                        e.critical
                                };
                            })}
                    />
            
                </VictoryGroup >
                <VictoryAxis dependentAxis={true} style={{ tickLabels: { angle: 0 } }} fixLabelOverlap={true} />
                <VictoryAxis style={{ tickLabels: { angle: 0 } }} fixLabelOverlap={true} />
            </VictoryChart>
            
            <VictoryLegend padding={{ right: 5, left: 55, top: 20, bottom: 50 }}
                x={120} y={10}
                gutter={20}
                orientation="horizontal"
                style={{ border: { stroke: "black" }, title: { fontSize: 20 } }}
                data={[
                    { name: "Info", symbol: { fill: "#1F55DF" } },
                    { name: "Warning", symbol: { fill: "#e39014" } },
                    { name: "Critical", symbol: { fill: "#95233A" } }
                ]}
            />
        </Stack>
    );
};
