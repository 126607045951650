import React from 'react';
import { FadeLoader } from 'react-spinners';
interface LoadingProps {
  loading: boolean;
}
export const Loading = (props: LoadingProps) => {
  return (
    <div>
      {props.loading && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.3)',
            zIndex: 2
          }}
        >
          <div style={{ position: 'fixed', top: '45%', left: '50%' }}>
            <FadeLoader color={'#123abc'} loading={true} />
          </div>
        </div>
      )}
    </div>
  );
};
