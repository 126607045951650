import React, { useState, useEffect } from "react";
import { Stack, Label } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import {
  VictoryPie,
  VictoryLegend,
  VictoryLabel,
  VictoryTooltip
} from "victory";
 
interface DataEntry {
  label: string;
  amount: number;
  navigateToLink: string;
}

interface IDonutChartProps {
  size: number;
  animationDuration: number;
  total: number;
  centerLabel: string;
  data: DataEntry[];
  colorScale: string[];
  undefinedNavigateToLink: string;
}

export const DonutChart = (props: IDonutChartProps) => {
  const history = useNavigate();
  const [data, setData] = useState(
    props.data.map((e) => {
      return { label: '', amount: 1, navigateToLink: '' };
    })
  );

  useEffect(() => {
    const allSetEntries = props.data.reduce(function (
      accumulator,
      currentValue
    ) {
      return accumulator + currentValue.amount;
    },
    0);
    let data = [...props.data];
    if (props.total > allSetEntries) {
      data.push({
        label: 'Undefined',
        amount: props.total - allSetEntries,
        navigateToLink: props.undefinedNavigateToLink
      });
    }
    setData(data);
  }, [props.data, props.total, props.undefinedNavigateToLink]);
  return (
    <Stack
      styles={{
        root: { width: props.size, height: props.size, marginBottom: 100 }
      }}
    >
      <Label
        style={{
          margin: 'auto',
          position: 'relative',
          top: props.size / 2 + 15 + 'px',
          textAlign: 'center',
          fontSize: '1rem',
          fontWeight: 'bold'
        }}
      >
        {props.centerLabel}
      </Label>
      <VictoryPie
        data={data.map((e) => {
          return {
            x: e?.amount || 0,
            y:
              e.amount && props.total && props.total > 0
                ? (e.amount / props.total) * 100
                : 0
          };
        })}
        animate={{
          duration: props.animationDuration
        }}
        labels={({ datum }) =>
          datum.x && datum.x > 0 ? Math.round(datum.x) : ''
        }
        labelComponent={
          <CustomLabel
            colorScale={props.colorScale}
            data={data}
            filterLabel={props.centerLabel}
          />
        }
        innerRadius={props.size / 3}
        padAngle={3}
        colorScale={props.colorScale}
        labelRadius={props.size / 3 + 23}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onMouseOver: () => {
                return [
                  {
                    target: 'data',
                    mutation: () => ({
                      radius: props.size / 3 + 65,
                      innerRadius: props.size / 3 - 12
                    })
                  },
                  {
                    target: 'labels',
                    mutation: () => ({ active: true })
                  }
                ];
              },
              onMouseOut: () => {
                return [
                  {
                    target: 'data',
                    mutation: () => {}
                  },
                  {
                    target: 'labels',
                    mutation: () => ({ active: false })
                  }
                ];
              },
              onClick: () => {
                return [
                  {
                    target: 'data',
                    mutation: (d) => {
                      history(data[d.index].navigateToLink);
                      window.dispatchEvent(new Event('popstate'));
                    }
                  }
                ];
              }
            }
          }
        ]}
      />
      {data.length > 1 && (
        <VictoryLegend
          orientation="vertical"
          gutter={20}
          colorScale={props.colorScale}
          style={{ labels: { fontSize: 20 } }}
          data={data.map((e) => {
            return { name: e.label };
          })}
        />
      )}
    </Stack>
  );
};

class CustomLabel extends React.Component<
  {
    colorScale: string[];
    data: DataEntry[];
    filterLabel: string;
  },
  {}
> {
  static defaultEvents = [
    {
      target: 'data',
      eventHandlers: {
        onMouseOver: () => ({
          target: 'labels',
          mutation: () => ({ active: true })
        }),
        onMouseOut: () => ({
          target: 'labels',
          mutation: () => ({ active: undefined })
        }),
        onFocus: () => ({
          target: 'labels',
          mutation: () => ({ active: true })
        }),
        onBlur: () => ({
          target: 'labels',
          mutation: () => ({ active: undefined })
        })
      }
    }
  ];
  render() {
    var props = this.props as any;
    return (
      <g>
        <VictoryLabel
          text={props.active ? '' : props.text}
          x={props.x-6}
          y={props.y}
          style={{
            fontSize: 20,
            fill: 'white',
            fontFamily:
              'Segoe UI, Roboto, Helvetica Neu", Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
            fontWeight: 'bold'
          }}
        />
        <VictoryTooltip
          {...props}
          orientation={props.y < 200 ? 'top' : 'bottom'}
          centerOffset={{ x: 200 - props.x }}
          pointerWidth={30}
          pointerLength={30}
          text={`${props?.text ?? ''} Products with ${
            this.props?.data[props.slice.index]?.label
          } ${this.props?.filterLabel} (${Math.round(props?.slice.value)}%)`}
          cornerRadius={5}
          flyoutStyle={{
            fill: 'white',
            strokeWidth: 2,
            stroke: this.props.colorScale[props.slice.index]
          }}
          style={{ fill: 'black', fontSize: 20, padding: 30 }}
        />
      </g>
    );
  }
}
