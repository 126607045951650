import {
  buildColumns,
  CommandBar,
  IColumn,
  ICommandBarItemProps,
  MarqueeSelection,
  SearchBox,
  Selection,
  SelectionMode,
  ShimmeredDetailsList
} from "@fluentui/react";
import { useState, useEffect, useCallback, useContext } from "react";
import ReactPaginate from "react-paginate";
import { context } from "AppContext";
import { IProductOwnershipRequestListEntry } from "models/IProductOwnershipRequestListEntry";
import { Loading } from "components/Core/Loading";
import Logger from "Logger";
import { copyAndSort, trimLower } from "helpers/HelperFunctions";
import { renderItemColumnWithCompanyWarning } from "components/Core/DetailsListUtils";
import { PAGE_SIZE } from "helpers/const";

export const ProductOwnershipRequestsList = () => {
  const ctx: any = useContext(context)!;
  const [loading, setLoading]: any = useState<boolean>(false);
  const [
    selectedProductOwnershipRequest,
    setSelectedProductOwnershipRequest
  ]: any = useState<IProductOwnershipRequestListEntry>();
  const [columns, setColumns]: any = useState([] as IColumn[]);
  const [currentPage, setCurrentPage]: any = useState(0);
  const [
    currentProductOwnershipRequests,
    setCurrentProductOwnershipRequests
  ]: any = useState<IProductOwnershipRequestListEntry[]>([]);
  const [
    filteredProductOwnershipRequests,
    setFilteredProductOwnershipRequests
  ]: any = useState<IProductOwnershipRequestListEntry[]>([]);
  const [
    filteredProductOwnershipRequestsPage,
    setFilteredProductOwnershipRequestsPage
  ]: any = useState<IProductOwnershipRequestListEntry[]>([]);

  const productOwnershipRequestSelection: Selection = new Selection({
    onSelectionChanged: () => {
      setSelectedProductOwnershipRequest(
        productOwnershipRequestSelection.getSelection()[0] as any
      );
    }
  }) as any;
  const [searchText, setSearchText]: any = useState("");

  const loadProductApis: any = useCallback(() => {
    setLoading(true);
    ctx.backendClient
      .getProductOwnershipRequests()
      .then((productOwnershipRequests: IProductOwnershipRequestListEntry[]) => {
        setCurrentProductOwnershipRequests(productOwnershipRequests);
        setFilteredProductOwnershipRequests(productOwnershipRequests);
      })
      .catch((err: any) => Logger.Error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [ctx.backendClient]);

  useEffect(() => {
    loadProductApis();
  }, [loadProductApis]);

  // apply paging based on current page and pagesize
  useEffect(() => {
    const offset: any = currentPage * PAGE_SIZE;
    setFilteredProductOwnershipRequestsPage(
      filteredProductOwnershipRequests.slice(offset, offset + PAGE_SIZE)
    );
  }, [currentPage, filteredProductOwnershipRequests]);

  // search and filtering
  useEffect(() => {
    if (currentProductOwnershipRequests) {
      const filteredProductApis: any = currentProductOwnershipRequests
        .slice()
        .filter((por: IProductOwnershipRequestListEntry) => {
          if (
            trimLower(por.productId).indexOf(trimLower(searchText)) === -1 &&
            trimLower(por.requestorMail).indexOf(trimLower(searchText)) === -1
          ) {
            return false;
          }
          return true;
        });
      setFilteredProductOwnershipRequests(filteredProductApis);
      setCurrentPage(0);
    }
  }, [searchText, currentProductOwnershipRequests]);

  const updateProductOwnershipRequest = (
    productOwnershipRequestId: string,
    approved: boolean
  ) => {
    ctx.backendClient
      .setProductOwnershipRequestStatus(productOwnershipRequestId, approved)
      .then(() => {
        if (approved) {
          Logger.Success(
            "Product ownership request has been approved. The requestor will be notified via email."
          );
        } else {
          Logger.Success(
            "Product ownership request has been rejected. The requester will be notified via email."
          );
        }
        const updatedCurrentProductOwnershipRequests =
          currentProductOwnershipRequests.filter(
            (por: IProductOwnershipRequestListEntry) =>
              por.id !== selectedProductOwnershipRequest.id
          );
        setCurrentProductOwnershipRequests(
          updatedCurrentProductOwnershipRequests
        );
      })
      .catch((error: any) => {
        Logger.Error(error);
      });
  };

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "approve",
      text: "Approve",
      iconProps: { iconName: "CheckMark" },
      onClick: () => {
        updateProductOwnershipRequest(selectedProductOwnershipRequest.id, true);
      },
      disabled: selectedProductOwnershipRequest === undefined
    },
    {
      key: "reject",
      text: "Reject",
      iconProps: { iconName: "CalculatorMultiply" },
      onClick: () => {
        updateProductOwnershipRequest(
          selectedProductOwnershipRequest.id,
          false
        );
      },
      disabled: selectedProductOwnershipRequest === undefined
    }
  ];

  // build DetailsList columns
  useEffect(() => {
    if (columns.length < 1) {
      let newColumns: any = buildColumns(currentProductOwnershipRequests);
      newColumns = newColumns.filter(
        (column: any) =>
          column.key !== "id" &&
          column.key !== "requestorObjectId" &&
          column.key !== "status"
      );
      for (const column of newColumns) {
        column.isResizable = true;
        switch (column.key) {
          case "productId":
            column.name = "Product";
            break;
          case "requestorMail":
            column.name = "Requestor";
            break;
          case "createdAt":
            column.name = "Creation Date";
            break;
        }
      }
      setColumns(newColumns);
    }
  }, [currentProductOwnershipRequests, columns.length]);

  const onColumnClick: any = (
    ev: React.MouseEvent<HTMLElement> | undefined,
    column: IColumn | undefined
  ): void => {
    if (column && filteredProductOwnershipRequests) {
      const newColumns: IColumn[] = [...columns];
      const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key
      )[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
      const newlySortedOwnershipRequests: any = copyAndSort(
        filteredProductOwnershipRequests,
        currColumn.fieldName!,
        currColumn.isSortedDescending
      );
      setColumns(newColumns);
      setFilteredProductOwnershipRequests(newlySortedOwnershipRequests);
    }
  };

  const onPageChanged: any = (page: { selected: number }) => {
    setCurrentPage(page.selected);
  };

  return (
    <div>
      <Loading loading={loading} />
      <div style={{ textAlign: "center", marginTop: 10 }}>
        <SearchBox
          styles={{ root: { width: 300 } }}
          placeholder="Search"
          value={searchText ?? ""}
          onEscape={() => {
            setSearchText("");
            setCurrentPage(0);
          }}
          onClear={() => {
            setSearchText("");
            setCurrentPage(0);
          }}
          onChange={(_, newSearchText) => {
            setSearchText(newSearchText ?? "");
            setCurrentPage(0);
          }}
          onSearch={(newSearchText) => {
            setSearchText(newSearchText ?? "");
            setCurrentPage(0);
          }}
        />
        <CommandBar
          items={commandBarItems}
          styles={{ root: { marginTop: 10, padding: 0 } }}
        />
        <div>
          <MarqueeSelection selection={productOwnershipRequestSelection}>
            <ShimmeredDetailsList
              setKey="items"
              items={filteredProductOwnershipRequestsPage}
              onRenderItemColumn={renderItemColumnWithCompanyWarning}
              columns={columns}
              selectionMode={SelectionMode.single}
              enableShimmer={loading}
              ariaLabelForShimmer="Loading product apis"
              ariaLabelForGrid="Product APIs"
              listProps={{
                renderedWindowsAhead: 0,
                renderedWindowsBehind: 0
              }}
              onColumnHeaderClick={onColumnClick}
              selection={productOwnershipRequestSelection}
              selectionPreservedOnEmptyClick={true}
            />
          </MarqueeSelection>
          <div>
            <ReactPaginate
              previousLabel={"← Previous"}
              nextLabel={"Next →"}
              pageCount={Math.ceil(Math.max(filteredProductOwnershipRequests.length, 1) / PAGE_SIZE)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              containerClassName={"paginationContainer"}
              previousClassName={currentPage < 1 ? "disabledPagination" : ""}
              nextClassName={
                currentPage ===
                Math.ceil(Math.max(filteredProductOwnershipRequests.length, 1) / PAGE_SIZE) - 1
                  ? "disabledPagination"
                  : ""
              }
              activeClassName={"active"}
              breakLabel={"..."}
              onPageChange={onPageChanged}
              forcePage={currentPage}
            />
          </div>
        </div>
        {!loading &&
          (!currentProductOwnershipRequests ||
            currentProductOwnershipRequests.length < 1) && (
            <div>
              <h5>{`No pending product ownership requests.`}</h5>
            </div>
          )}
        <div>
          Total: {currentProductOwnershipRequests.length} pending requests
        </div>
      </div>
    </div>
  );
};
