import { PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";

export default class GraphClient {
  private readonly msalInstance: PublicClientApplication;
  private readonly graphAuthConfig: any;
  isAuthInitialized: boolean = false;

  constructor(msalInstance: PublicClientApplication) {
    this.msalInstance = msalInstance;
    const activeAccount =
      msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];
    this.graphAuthConfig = {
      account: activeAccount,
      forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
      scopes: ["User.Read"]
    };
  }

  getUserInformation = async () => {
    return axios
      .get(
        `https://graph.microsoft.com/v1.0/me?$select=givenname,surname,displayName,department,userPrincipalName`,
        {
          headers: {
            Authorization: `Bearer ${await this.getAccessToken()}`
          }
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  getUserPhoto = async (size: string) => {
    return axios
      .get(`https://graph.microsoft.com/v1.0/me/photos/${size}/$value`, {
        headers: {
          Authorization: `Bearer ${await this.getAccessToken()}`,
          "content-type": "image/jpeg"
        },
        responseType: "arraybuffer"
      })
      .then((res) => {
        const base64Image = this.arrayBufferToBase64(res.data);
        return base64Image;
      })
      .catch(() => {
        return "";
      });
  };

  private getAccessToken = async () => {
    if(!this.isAuthInitialized) {
      await this.msalInstance.initialize();
      this.isAuthInitialized = true;
    }
    return (await this.msalInstance.acquireTokenSilent(this.graphAuthConfig))
      .accessToken;
  };

  private arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    if (!buffer) return;
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
}
