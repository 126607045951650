import React, { useState, useEffect, useCallback } from "react";
import { IProduct } from "models/IProduct";
import { AADPeoplePicker } from "components/Core/AADPeoplePicker";
import { context } from "AppContext";
import Logger from "Logger";
import {
  Panel,
  PanelType,
  Persona,
  PersonaSize,
  Stack,
  IconButton,
  PrimaryButton,
  DefaultButton,
  Dialog,
  DialogType,
  DialogFooter
} from "@fluentui/react";
import { IPerson } from "models/IPerson";
import { Loading } from "components/Core/Loading";

interface IProductOwnersProps {
  visible: boolean;
  onDismiss: () => void;
  product: IProduct | undefined;
  loading: boolean;
}

export const ProductOwnerPanel = (props: IProductOwnersProps) => {
  const ctx = React.useContext(context)!;
  const [loggedInUser, setLoggedInUser] = useState({
    givenName: "",
    surname: "",
    displayName: "",
    department: "",
    userPrincipalName: ""
  });
  const [touched, setTouched] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = useState<IProduct>();
  const [
    isConfirmationCloseDialogVisible,
    setIsConfirmationCloseDialogVisible
  ] = useState(false);
  const [productOwnerControls, setProductOwnerControls] =
    useState<JSX.Element[]>();
  const [addedProductOwners, setAddedProductOwners] = useState<IPerson[]>();
  const [loading, setLoading] = useState<boolean>();

  const getLogginInUserData = useCallback(() => {
    ctx.graphClient
      .getUserInformation()
      .then((res) => setLoggedInUser(res))
      .catch((err) => Logger.Error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLogginInUserData();
  }, [getLogginInUserData]);

  useEffect(() => {
    if (props.product) {
      setCurrentProduct({ ...props.product });
    }
  }, [props.product]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    if (!currentProduct?.productOwners) {
      return;
    }
    const controls: JSX.Element[] = [];
    currentProduct.productOwners.forEach((po, index) => {
      controls.push(
        <Stack horizontal key={`PersonaStack-${po.upn}`}>
          <Persona
            key={`Persona-${po.upn}`}
            text={`${po.lastname}, ${po.firstName} `}
            size={PersonaSize.size40}
            secondaryText={po.upn}
          />
          <IconButton
            key={`RemoveButton-${po.upn}`}
            iconProps={{ iconName: "Delete" }}
            ariaLabel={`Remove ${po.upn}`}
            disabled={
              !loggedInUser?.userPrincipalName ||
              !po?.upn ||
              props.loading ||
              loggedInUser.userPrincipalName.toLowerCase() ===
                po.upn.toLowerCase()
            }
            onClick={() => {
              const updatedProductowners = [...currentProduct.productOwners];
              updatedProductowners.splice(index, 1);
              setCurrentProduct(((prevProductDetails: any) => ({
                ...prevProductDetails!,
                productOwners: updatedProductowners
              })) as any);
              setTouched(true);
            }}
          />
        </Stack>
      );
    });
    setProductOwnerControls(controls);
  }, [
    currentProduct?.productOwners,
    loggedInUser.userPrincipalName,
    props.loading
  ]);

  const saveProductOwners = () => {
    setLoading(true);
    if (currentProduct?.id && currentProduct?.productOwners) {
      ctx.backendClient
        .updateProductOwners(currentProduct.id, currentProduct?.productOwners)
        .then(() => {
          Logger.Success("Product owners updated.");
        })
        .catch((err) => Logger.Error(err))
        .finally(() => {
          setLoading(false);
        });
    }
    setTouched(false);
    setIsConfirmationCloseDialogVisible(false);
    props.onDismiss();
  };

  return (
    <div>
      <Panel
        headerText={
          (currentProduct?.displayName ?? props.product?.displayName) +
          " Owners"
        }
        isOpen={props.visible}
        onDismiss={() => {
          if (!touched) {
            props.onDismiss();
          } else {
            setIsConfirmationCloseDialogVisible(true);
          }
        }}
        closeButtonAriaLabel="Close"
        type={PanelType.medium}
        layerProps={{ styles: { root: { zIndex: 998 } } }}
        onOuterClick={() => {
          /*ignored*/
        }}
      >
        <Loading loading={loading || false} />
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <AADPeoplePicker
            pickerWidth={300}
            onchange={(items) => {
              const newAddedProductOwners = items?.map((i) => {
                const ownerStringData = i.tertiaryText?.split("|") ?? [];
                return {
                  firstName: ownerStringData[0] ?? "",
                  lastname: ownerStringData[1] ?? "",
                  upn: i.secondaryText ?? "",
                  objectId: ownerStringData[2] ?? ""
                } as IPerson;
              });
              setAddedProductOwners(newAddedProductOwners);
            }}
          />
          <DefaultButton
            onClick={() => {
              const updatedProductOwners =
                currentProduct?.productOwners.slice() ?? [];
              const newProductOwners = addedProductOwners?.filter(
                (npo) =>
                  !updatedProductOwners.some(
                    (po: any) =>
                      po?.upn &&
                      po.upn.trim().toLowerCase() ===
                        npo?.upn?.trim().toLowerCase()
                  )
              );
              if (newProductOwners && newProductOwners.length > 0) {
                updatedProductOwners.push(...newProductOwners);
                setCurrentProduct(((prevProductDetails: any) => ({
                  ...prevProductDetails!,
                  productOwners: updatedProductOwners
                })) as any);
                setTouched(true);
              }
            }}
            text="Add"
            styles={{
              root: { position: "relative", top: 31 }
            }}
          />
        </Stack>
        <Stack
          tokens={{ childrenGap: 10, maxHeight: 500 }}
          styles={{ root: { marginTop: 20 } }}
        >
          {productOwnerControls}
        </Stack>
        <PrimaryButton
          text="Save"
          onClick={saveProductOwners}
          allowDisabledFocus
          disabled={!touched}
          styles={{ root: { marginTop: 20 } }}
        />
        <DefaultButton
          text="Cancel"
          onClick={() => {
            if (!touched) {
              props.onDismiss();
            } else {
              setIsConfirmationCloseDialogVisible(true);
            }
          }}
          allowDisabledFocus
          styles={{ root: { marginTop: 20, marginLeft: 20 } }}
        />
      </Panel>
      <Dialog
        hidden={!isConfirmationCloseDialogVisible}
        onDismiss={() => setIsConfirmationCloseDialogVisible(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title:
            "Are you sure you want to close the product owners panel? All unsaved changes will be lost!"
        }}
        modalProps={{
          isBlocking: true,
          styles: { main: { maxWidth: 450 } }
        }}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setCurrentProduct({ ...props.product! });
              setTouched(false);
              setIsConfirmationCloseDialogVisible(false);
              props.onDismiss();
            }}
            text="Yes"
          />
          <DefaultButton
            onClick={() => {
              setIsConfirmationCloseDialogVisible(false);
            }}
            text="No"
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
