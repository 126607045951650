import { IClientAppListEntry } from "models/IClientAppListEntry";
import { IPerson } from "models/IPerson";

export interface IProduct {
  id: string;
  displayName: string;
  description: string;
  subscriptionRequired: boolean;
  approvalRequired: boolean;
  subscriptionsLimit?: number;
  terms?: string;
  state: ProductState;
  backendAppIdForDevApim?: string;
  backendAppIdForTestApim?: string;
  backendAppIdForProdApim?: string;
  category?: number;
  confidentiality?: number;
  type?: number;
  backendHosting?: number;
  backendRouting?: number;
  authorization?: number;
  isUsingKeyVault?: boolean;
  productOwners: IPerson[];
  clientApps: IClientAppListEntry[];
}

export enum ProductState {
  NotPublished = 0,
  Published = 1
}
