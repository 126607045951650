import {
  PrimaryButton,
  DefaultButton,
  TextField,
  Label
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { context } from "AppContext";
import { ISubscriptionListEntry } from "models/ISubscriptionListEntry";
import { IProductListEntry } from "models/IProductListEntry";
import Logger from "Logger";
import { Loading } from "components/Core/Loading";
import queryString  from "query-string";
import { useLocation } from "react-router";
import { CompanyPicker } from "components/Core/CompanyPicker";

interface IEditProductSubscriptionFormProps {
  visible: boolean;
  onDismiss: () => void;
  onSaved: (savedProductSubscription: ISubscriptionListEntry) => void;
  touched: boolean;
  setTouched: (touched: boolean) => void;
  selectedSubscriptionListEntry: ISubscriptionListEntry | undefined;
  selectedProductListEntry: IProductListEntry | undefined;
}

export const EditProductSubscriptionForm = (
  props: IEditProductSubscriptionFormProps
) => {
  const ctx = React.useContext(context)!;
  const urlParams: queryString.ParsedQuery = queryString.parse(useLocation().search);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentProductSubscription, setCurrentProductSubscription] =
    useState<ISubscriptionListEntry>();

  useEffect(() => {
    if (props.selectedSubscriptionListEntry) {
      setCurrentProductSubscription({ ...props.selectedSubscriptionListEntry });
    } else {
      if (!urlParams.productId) {
        Logger.Error(`URL-Parameter productId is not set.`);
      }
      if (!urlParams.subscriptionId) {
        Logger.Error(`URL-Parameter subscriptionId is not set.`);
      }
      setLoading(true);
      ctx.backendClient
        .getProductSubscription(
          urlParams.productId?.toString()!,
          urlParams.subscriptionId?.toString()!,
          ctx.apimEnvironment
        )
        .then((subscription) => {
          setCurrentProductSubscription(subscription);
        })
        .catch((err) => {
          Logger.Error(err);
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedSubscriptionListEntry]);

  const getErrorMessage = (fieldName: string) => {
    let message = "";
    switch (fieldName) {
      case "all":
      // falls through
      case "company":
        if (!currentProductSubscription?.company) {
          message = "Product subscription needs a company";
          break;
        }
        if (fieldName !== "all") {
          break;
        }
      // falls through
      case "displayName":
        if (!currentProductSubscription?.displayName) {
          message = "Product subscription display name is required";
          break;
        }
    }

    return message;
  };

  return (
    <div style={{ maxWidth: "500px" }}>
      <Loading loading={loading} />
      <TextField
        label="Product"
        disabled={true}
        value={
          props.selectedProductListEntry?.id ?? urlParams.productId?.toString()
        }
        onChange={() => {}}
      />
      <TextField
        label="Requestor"
        disabled={true}
        value={currentProductSubscription?.email ?? ""}
        onChange={() => {}}
      />
      <TextField
        onChange={(e) => {
          if (currentProductSubscription) {
            setCurrentProductSubscription({
              ...currentProductSubscription!,
              displayName: (e.target as HTMLInputElement).value
            });
            props.setTouched(true);
          }
        }}
        label="Subscription DisplayName"
        style={{ width: "700px" }}
        value={currentProductSubscription?.displayName || ""}
        required
        onGetErrorMessage={() => getErrorMessage("displayName")}
      />
      <CompanyPicker
        object={currentProductSubscription}
        updateObjectFunction={setCurrentProductSubscription}
        setTouched={props.setTouched}
        getErrorMessage={() => getErrorMessage("company")}
      />
      <PrimaryButton
        text={!props.selectedProductListEntry ? "Approve" : "Save"}
        onClick={() => {
          if (
            props.selectedProductListEntry &&
            currentProductSubscription &&
            currentProductSubscription.company
          ) {
            setLoading(true);
            ctx.backendClient
              .updateProductSubscription(
                props.selectedProductListEntry?.id,
                currentProductSubscription?.id,
                ctx.apimEnvironment,
                currentProductSubscription?.displayName,
                currentProductSubscription?.state,
                currentProductSubscription?.company?.id
              )
              .then((_) => {
                Logger.Success("Subscription successfully updated");
                props.onSaved(currentProductSubscription);
              })
              .catch((error) => Logger.Error(error))
              .finally(() => {
                setLoading(false);
              });
          } else if (
            !props.selectedProductListEntry &&
            currentProductSubscription
          ) {
            setLoading(true);
            ctx.backendClient
              .updateProductSubscription(
                urlParams.productId?.toString()!,
                currentProductSubscription?.id,
                ctx.apimEnvironment,
                currentProductSubscription?.displayName,
                "Active",
                currentProductSubscription?.company?.id
              )
              .then((_) => {
                setCurrentProductSubscription({
                  ...currentProductSubscription!,
                  state: "Active"
                });
                Logger.Success("Subscription successfully approved");
              })
              .catch((error) => Logger.Error(error))
              .finally(() => {
                setLoading(false);
              });
          }
        }}
        allowDisabledFocus
        disabled={
          !currentProductSubscription?.company ||
          !props.touched ||
          getErrorMessage("all").length > 0 ||
          (!props.selectedProductListEntry &&
            currentProductSubscription &&
            (!currentProductSubscription?.state ||
              currentProductSubscription?.state !== "Submitted"))
        }
        styles={{ root: { marginTop: 20 } }}
      />
      {(props.selectedProductListEntry && (
        <DefaultButton
          text="Cancel"
          onClick={() => {
            props.onDismiss();
          }}
          allowDisabledFocus
          styles={{ root: { marginTop: 20, marginLeft: 20 } }}
        />
      )) || (
        <PrimaryButton
          text="Reject"
          onClick={() => {
            if (currentProductSubscription) {
              setLoading(true);
              ctx.backendClient
                .updateProductSubscription(
                  urlParams.productId?.toString()!,
                  currentProductSubscription?.id,
                  ctx.apimEnvironment,
                  currentProductSubscription?.displayName,
                  "Rejected",
                  currentProductSubscription?.company?.id
                )
                .then((_) => {
                  setCurrentProductSubscription({
                    ...currentProductSubscription!,
                    state: "Rejected"
                  });
                  Logger.Success("Subscription has been rejected");
                })
                .catch((error) => Logger.Error(error))
                .finally(() => {
                  setLoading(false);
                });
            }
          }}
          allowDisabledFocus
          styles={{ root: { marginTop: 20, marginLeft: 20 } }}
          disabled={
            !currentProductSubscription?.state ||
            currentProductSubscription?.state !== "Submitted"
          }
        />
      )}
      {!currentProductSubscription?.state ||
        (currentProductSubscription?.state !== "Submitted" &&
          !props.selectedProductListEntry &&
          currentProductSubscription && (
            <Label
              style={{
                marginTop: 15,
                backgroundColor: "#ffeecc",
                width: "100%",
                paddingLeft: 10,
                paddingRight: 10
              }}
            >
              <span style={{ fontWeight: 700, color: "red" }}>IMPORTANT: </span>
              This subscription is already in state{" "}
              {currentProductSubscription?.state}. Therefore, no action is
              possible.
            </Label>
          ))}
    </div>
  );
};
