import { Toggle, Stack, ComboBox, IComboBoxOption } from "@fluentui/react";
import { CompanyReSync } from "components/SubscriptionManagement/CompanyReSync";
import { InfoLabelButton } from "./InfoLabelButton";
import React, { useEffect, useMemo, useState } from "react";
import { context } from "AppContext";
import { ISubscriptionListEntry } from "models/ISubscriptionListEntry";
import { IClientApp } from "models/IClientApp";

interface ICompanyPickerProps {
  updateObjectFunction: Function;
  object: ISubscriptionListEntry | IClientApp;
  setTouched: Function;
  getErrorMessage: Function;
  renderOnlyPicker: boolean;
}

export const CompanyPicker: any = (props: ICompanyPickerProps) => {
  let renderOnlyPicker;
  props.renderOnlyPicker === undefined ? renderOnlyPicker = false : renderOnlyPicker = true;

  const ctx = React.useContext(context)!;
  const initialCompanyComboBoxOptions = useMemo(() => {
    return ctx.companies
      .map<IComboBoxOption>((c) => {
        return { key: c.id, text: c.name };
      })
      .filter((c) => {
        return c.key !== "0";
      });
  }, [ctx.companies]);

  const [companyComboboxOptions, setCompanyComboboxOptions] = useState<
    IComboBoxOption[]
  >(initialCompanyComboBoxOptions);

  const [filteredCompanyComboboxOptions, setFilteredCompanyComboboxOptions] =
    useState<IComboBoxOption[]>(companyComboboxOptions);

  useEffect(() => {
    setFilteredCompanyComboboxOptions(initialCompanyComboBoxOptions);
    setCompanyComboboxOptions(initialCompanyComboBoxOptions);
  }, [initialCompanyComboBoxOptions]);

  const [internalClient, setInternalClient] = React.useState(false);

  useEffect(() => {
    props.object?.company && props.object?.company?.id === "0"
      ? setInternalClient(true)
      : setInternalClient(false);
  }, [props.object]);

  const onCompanySearch = (value: string) => {
    let newFilteredCompanies = companyComboboxOptions.slice().filter((o) => {
      return o.text.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredCompanyComboboxOptions(newFilteredCompanies);
  };

  return (
    renderOnlyPicker === false ?
    <div>
      <Toggle
        label={
          <Stack
            horizontal
            verticalAlign="center"
            tokens={{
              childrenGap: 4,
              maxWidth: 300
            }}
          >
            <span>Is OMV Group internal client</span>
            <InfoLabelButton
              id="isOmvGroupinternalClient"
              summary={`If the subscription is used from a client within the OMV Group (OMV, Petrom or Borealis), activate this`}
            />
          </Stack>
        }
        onText="Yes"
        offText="No"
        onChange={(_, checked) => {
          if (checked) {
            props.updateObjectFunction({
              ...props.object!,
              company: ctx.companies.find((c) => c.id === "0")!
            });
          } else {
            props.updateObjectFunction({
              ...props.object!,
              company: undefined
            });
          }
          props.setTouched(true);
        }}
        checked={internalClient}
      />
      <Stack horizontal verticalFill={true}>
        {props.object?.company?.id !== "0" && (
          <ComboBox
            selectedKey={props.object?.company?.id}
            label="Company"
            autoComplete="on"
            allowFreeform={true}
            options={filteredCompanyComboboxOptions}
            onChange={(_, option) => {
              props.updateObjectFunction({
                ...props.object!,
                company: ctx.companies.find((c) => c.id === option?.key!)!
              });
              props.setTouched(true);
            }}
            onInputValueChange={onCompanySearch}
            required={true}
            style={{ width: "100%" }}
            errorMessage={props.getErrorMessage("company")}
          />
        )}

        <div style={{ paddingTop: "27px" }}>
          {!internalClient ? <CompanyReSync /> : null}
        </div>
      </Stack>
      Cannot find the company you want to assign? Please check all possible
      namings, otherwise you can request a new company on SNow{" "}
      <a
        href="https://omv.service-now.com/sp?id=sc_cat_item&sys_id=ed8ac1551b9124509a0671d4464bcb22"
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>
      .
    </div>
    :
    <div>
      <Stack horizontal verticalFill={true}>
        {props.object?.company?.id !== "0" && (
          <ComboBox
            selectedKey={props.object?.company?.id}
            label="Company"
            autoComplete="on"
            allowFreeform={true}
            options={filteredCompanyComboboxOptions}
            onChange={(_, option) => {
              props.updateObjectFunction({
                ...props.object!,
                company: ctx.companies.find((c) => c.id === option?.key!)!
              });
              props.setTouched(true);
            }}
            onInputValueChange={onCompanySearch}
            required={true}
            style={{ width: "100%" }}
            errorMessage={props.getErrorMessage("company")}
          />
        )}
      </Stack>
    </div>
  );
};
