import "bootstrap/dist/css/bootstrap.css";
import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { App } from "./App";
import { IAppContext, AppContextProvider } from "./AppContext";
import GraphClient from "./clients/GraphClient";
import BackendClient from "./clients/BackendClient";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClientSecret } from "./components/ClientAppManagement/ClientSecret";
import { Route, Routes } from "react-router";
import queryString from "query-string";
import { Layout } from "./components/LayoutAndNavigation/Layout";
import { IProductListEntry } from "./models/IProductListEntry";
import { msalConfig, loginRequest } from "./auth/authConfig";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { IComplianceWarning } from "models/IComplianceWarning";
import { ICompany } from "models/ICompany";
import Logger from "Logger";

initializeIcons();

export const Index = () => {
  const urlParams: queryString.ParsedQuery = queryString.parse(
    window.location.search
  );
  const pca = new PublicClientApplication(msalConfig);

  const [apimEnvironment, setApimEnvironment] = useState(
    urlParams.apimEnvironment?.toString() ||
      getCookie("apimEnvironment") ||
      "DEV"
  );

  const setNewApimEnvironment = React.useCallback(
    (newApimEnvironment: string) => {
      setApimEnvironment(newApimEnvironment);
      var date = new Date();
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
      document.cookie = `apimEnvironment=${newApimEnvironment}; expires=${date.toUTCString()})}; path=/`;
    },
    []
  );
  const [products, setProducts] = useState([] as IProductListEntry[]);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const [loadingComplianceWarnings, setLoadingComplianceWarnings] =
    useState<boolean>(false);

  const setNewProducts = React.useCallback(
    (newProducts: IProductListEntry[]) => {
      setProducts(newProducts);
    },
    []
  );
  const setNewLoadingProducts = React.useCallback(
    (newLoadingProducts: boolean) => {
      setLoadingProducts(newLoadingProducts);
    },
    []
  );
  const setNewLoadingComplianceWarnings = React.useCallback(
    (newLoadingComplianceWarnings: boolean) => {
      setLoadingComplianceWarnings(newLoadingComplianceWarnings);
    },
    []
  );
  const setNewCompanies = React.useCallback((newCompanies: ICompany[]) => {
    setCompanies(newCompanies);
  }, []);
  const setNewCompanyResyncInProgress = React.useCallback(
    (newCompanyResyncInProgress: boolean) => {
      if (newCompanyResyncInProgress) {
        setTimeout(() => {
          appContext.backendClient
            .getCompanies()
            .then((companies) => {
              appContext.setCompanies(companies);
              Logger.Success(
                "Company resync completed. You can now find the newest companies in the company selection dropdowns."
              );
            })
            .catch((error) => {
              Logger.Error(error);
            })
            .finally(() => {
              setCompanyResyncInProgress(false);
            });
        }, 60000);
      }
      setCompanyResyncInProgress(newCompanyResyncInProgress);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [companies, setCompanies] = useState([] as ICompany[]);
  const [companyResyncInProgress, setCompanyResyncInProgress] =
    useState<boolean>(false);
  const [complianceWarningsForProducts, setComplianceWarningsForProducts] =
    useState(new Map<string, IComplianceWarning[]>());
  const setNewComplianceWarningsForAllProducts = React.useCallback(
    (warningsMap: Map<string, Array<IComplianceWarning>>) => {
      setComplianceWarningsForProducts(warningsMap);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [isLoggedInUserPOPAdmin, setIsLoggedInUserPOPAdmin] =
    useState<boolean>(false);
  const setNewIsLoggedInUserPOPAdmin = React.useCallback((isAdmin: boolean) => {
    setIsLoggedInUserPOPAdmin(isAdmin);
  }, []);

  const [isLoggedInUserComplianceManager, setIsLoggedInUserComplianceManager] =
    useState<boolean>(false);
  const setNewIsLoggedInUserComplianceManager = React.useCallback(
    (isComplianceManager: boolean) => {
      setIsLoggedInUserComplianceManager(isComplianceManager);
    },
    []
  );

  const [isLoggedInSecurityAdmin, setIsLoggedInSecurityAdmin] = useState<boolean>(false);
  const setNewIsLoggedInSecuriyAdmin = React.useCallback((isAdmin: boolean) => {
    setIsLoggedInSecurityAdmin(isAdmin);
  }, []);

  const appContext: IAppContext = {
    graphClient: new GraphClient(pca),
    backendClient: new BackendClient(pca),
    apimEnvironment: apimEnvironment,
    products: products,
    complianceWarningsForProducts: complianceWarningsForProducts,
    isLoggedInUserPOPAdmin: isLoggedInUserPOPAdmin,
    setIsLoggedInUserPOPAdmin: setNewIsLoggedInUserPOPAdmin,
    isLoggedInUserComplianceManager: isLoggedInUserComplianceManager,
    isLoggedInSecurityAdmin: isLoggedInSecurityAdmin,
    loadingProducts: loadingProducts,
    loadingComplianceWarnings: loadingComplianceWarnings,
    companies: companies,
    companyResyncInProgress: companyResyncInProgress,
    setCompanyResyncInProgress: setNewCompanyResyncInProgress,
    setIsLoggedInUserComplianceManager: setNewIsLoggedInUserComplianceManager,
    setIsLoggedInSecurityAdmin: setNewIsLoggedInSecuriyAdmin,
    setApimEnvironment: setNewApimEnvironment,
    setProducts: setNewProducts,
    setComplianceWarningsForAllProducts: setNewComplianceWarningsForAllProducts,
    setLoadingProducts: setNewLoadingProducts,
    setLoadingComplianceWarnings: setNewLoadingComplianceWarnings,
    setCompanies: setNewCompanies
  } as any;

  if (
    window.location.pathname &&
    window.location.pathname.toLowerCase().startsWith("/clientsecretanonymous/")
  ) {
    return (
      <div>
        <AppContextProvider value={appContext}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/clientsecretanonymous/:clientSecretName"
                element={
                  <Layout hasUserContext={false}>
                    <ClientSecret authenticated={false} />
                  </Layout>
                }
              />
            </Routes>
          </BrowserRouter>
        </AppContextProvider>
        <ToastContainer style={{ zIndex: 1000001 }} />{" "}
        {/*1000001 is one more as the fluentui-Panelvalue*/}
      </div>
    );
  } else {
    return (
      <div>
        <MsalProvider instance={pca}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
          >
            <AppContextProvider value={appContext}>
              <BrowserRouter basename={baseUrl}>
                <App />
              </BrowserRouter>
            </AppContextProvider>
          </MsalAuthenticationTemplate>
        </MsalProvider>
        <ToastContainer style={{ zIndex: 1000001 }} />{" "}
        {/*1000001 is one more as the fluentui-Panelvalue*/}
      </div>
    );
  }
};

function ErrorComponent({ error }: any) {
  return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
  return <p>Redirecting you for authentication... please wait...</p>;
}

const baseUrl =
  document.getElementsByTagName("base")[0].getAttribute("href") || "";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Index /> // do not wrap this in <React.StrictMode> as this will lead to problems in the productlist-selection
);

function getCookie(name: string) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
