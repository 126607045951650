import {
  buildColumns,
  CommandBar,
  IColumn,
  ICommandBarItemProps,
  Label,
  MarqueeSelection,
  Panel,
  PanelType,
  SearchBox,
  Selection,
  SelectionMode,
  ShimmeredDetailsList
} from "@fluentui/react";
import React, { useState, useEffect, useCallback } from "react";
import ReactPaginate from "react-paginate";
import { context } from "AppContext";
import { IProductListEntry } from "models/IProductListEntry";
import Logger from "Logger";
import { IApiListEntry } from "models/IApiListEntry";
import { Loading } from "components/Core/Loading";
import { copyAndSort, trimLower } from "helpers/HelperFunctions";
import { renderItemColumnWithCompanyWarning } from "components/Core/DetailsListUtils";
import { PAGE_SIZE } from "helpers/const";

interface IProductApisPanelProps {
  visible: boolean;
  onDismiss: () => void;
  selectedProductListEntry: IProductListEntry | undefined;
}

export const ProductApisPanel = (props: IProductApisPanelProps) => {
  const ctx = React.useContext(context)!;
  const [loading, setLoading] = useState<boolean>(false);
  // const [selectedProductApi, setSelectedProductApi] = useState<IApiListEntry>();
  const [columns, setColumns] = useState([] as IColumn[]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentProductApis, setCurrentProductApis] = useState<IApiListEntry[]>(
    []
  );
  const [filteredProductApis, setFilteredProductApis] = useState<
    IApiListEntry[]
  >([]);
  const [filteredProductApisPage, setFilteredProductApisPage] = useState<
    IApiListEntry[]
  >([]);

  const productApiSelection: Selection = new Selection({
    onSelectionChanged: () => {
      // setSelectedProductApi(productApiSelection.getSelection()[0] as any);
    }
  }) as any;
  const [searchText, setSearchText] = useState("");
  const [uniqueApisCount, setUniqueApisCount] = useState<number>(0);

  const loadProductApis = useCallback(() => {
    if (props.selectedProductListEntry?.id && props.visible) {
      setLoading(true);
      ctx.backendClient
        .getProductApis(props.selectedProductListEntry.id, ctx.apimEnvironment)
        .then((apis) => {
          const apisListEntries = apis.map((a) => {
            return {
              displayName: a.displayName,
              path: a.path,
              apiVersion: a.apiVersion ?? "Original",
              serviceUrl: a.serviceUrl ?? "undefined",
              subscriptionRequired: a.subscriptionRequired ? "Yes" : "No"
            } as IApiListEntry;
          });
          setCurrentProductApis(apisListEntries);
          setFilteredProductApis(apisListEntries);
          setUniqueApisCount(
            new Set(apisListEntries.map((api: IApiListEntry) => api.path)).size
          );
        })
        .catch((err) => Logger.Error(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    props.selectedProductListEntry,
    ctx.apimEnvironment,
    props.visible,
    ctx.backendClient
  ]);

  useEffect(() => {
    loadProductApis();
  }, [loadProductApis]);

  // apply paging based on current page and pagesize
  useEffect(() => {
    const offset = currentPage * PAGE_SIZE;
    setFilteredProductApisPage(
      filteredProductApis.slice(offset, offset + PAGE_SIZE)
    );
  }, [currentPage, filteredProductApis]);

  // search and filtering
  useEffect(() => {
    if (currentProductApis) {
      const newfilteredProductApis = currentProductApis
        .slice()
        .filter((productApi) => {
          if (
            trimLower(productApi.displayName).indexOf(trimLower(searchText)) ===
              -1 &&
            trimLower(productApi.path).indexOf(trimLower(searchText)) === -1
          ) {
            return false;
          }
          return true;
        });
      setFilteredProductApis(newfilteredProductApis);
      setCurrentPage(0);
    }
  }, [searchText, currentProductApis]);

  const commandBarItems: ICommandBarItemProps[] = [
    // TODO: implement removing api from product
    /*{
              key: 'remove',
              text: 'Remove from Product',
              iconProps: { iconName: 'Delete' },
              onClick: () => {},
              disabled: selectedProductApi === undefined
            }*/
  ];

  // build DetailsList columns
  useEffect(() => {
    if (columns.length < 1) {
      let newColumns = buildColumns(currentProductApis);
      newColumns = newColumns.filter((column) => column.key !== "isOnline");
      for (const column of newColumns) {
        column.isResizable = true;
        switch (column.key) {
          case "path":
            column.name = "Path";
            break;
          case "displayName":
            column.name = "Name";
            break;
          case "serviceUrl":
            column.name = "Service Url";
            break;
          case "subscriptionRequired":
            column.name = "Subscription required";
            break;
          case "apiVersion":
            column.name = "Version";
            break;
        }
      }
      setColumns(newColumns);
    }
  }, [currentProductApis, columns.length]);

  const onColumnClick = (
    ev: React.MouseEvent<HTMLElement> | undefined,
    column: IColumn | undefined
  ): void => {
    if (column && filteredProductApis) {
      const newColumns: IColumn[] = [...columns];
      const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key
      )[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
      const newlySortedProductApis = copyAndSort(
        filteredProductApis,
        currColumn.fieldName!,
        currColumn.isSortedDescending
      );
      setColumns(newColumns);
      setFilteredProductApis(newlySortedProductApis);
    }
  };

  const onPageChanged = (page: { selected: number }) => {
    setCurrentPage(page.selected);
  };

  return (
    <div>
      <Panel
        headerText={`${
          props.selectedProductListEntry?.displayName ?? ""
        } - Assigned APIs`}
        isOpen={props.visible}
        onDismiss={() => {
          setSearchText("");
          props.onDismiss();
        }}
        closeButtonAriaLabel="Close"
        type={PanelType.custom}
        customWidth="1500px"
        layerProps={{ styles: { root: { zIndex: 998 } } }}
        onOuterClick={() => {
          /* ignore */
        }}
      >
        <Loading loading={loading} />
        <Label style={{ fontSize: "20px" }}>
          Environment:{" "}
          <span
            style={{
              color: `${
                ctx.apimEnvironment === "DEV"
                  ? "#1FFF5A"
                  : ctx.apimEnvironment === "TEST"
                  ? "orange"
                  : "#95233A"
              }`
            }}
          >
            {ctx.apimEnvironment}
          </span>
        </Label>
        <div style={{ textAlign: "center", marginTop: 10 }}>
          <SearchBox
            styles={{ root: { width: 300 } }}
            placeholder="Search"
            value={searchText ?? ""}
            onEscape={() => {
              setSearchText("");
              setCurrentPage(0);
            }}
            onClear={() => {
              setSearchText("");
              setCurrentPage(0);
            }}
            onChange={(_, newSearchText) => {
              setSearchText(newSearchText ?? "");
              setCurrentPage(0);
            }}
            onSearch={(newSearchText) => {
              setSearchText(newSearchText ?? "");
              setCurrentPage(0);
            }}
          />
          <CommandBar
            items={commandBarItems}
            styles={{ root: { marginTop: 10, padding: 0 } }}
          />
          <div>
            <MarqueeSelection selection={productApiSelection}>
              <ShimmeredDetailsList
                setKey="items"
                items={filteredProductApisPage}
                onRenderItemColumn={renderItemColumnWithCompanyWarning}
                columns={columns}
                selectionMode={SelectionMode.single}
                enableShimmer={loading}
                ariaLabelForShimmer="Loading product apis"
                ariaLabelForGrid="Product APIs"
                listProps={{
                  renderedWindowsAhead: 0,
                  renderedWindowsBehind: 0
                }}
                onColumnHeaderClick={onColumnClick}
                selection={productApiSelection}
                selectionPreservedOnEmptyClick={true}
              />
            </MarqueeSelection>
            <div>
              <ReactPaginate
                previousLabel={"← Previous"}
                nextLabel={"Next →"}
                pageCount={Math.ceil(Math.max(filteredProductApis.length, 1) / PAGE_SIZE)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                containerClassName={"paginationContainer"}
                previousClassName={currentPage < 1 ? "disabledPagination" : ""}
                nextClassName={
                  currentPage ===
                  Math.ceil(Math.max(filteredProductApis.length, 1) / PAGE_SIZE) - 1
                    ? "disabledPagination"
                    : ""
                }
                activeClassName={"active"}
                breakLabel={"..."}
                onPageChange={onPageChanged}
                forcePage={currentPage}
              />
            </div>
          </div>
          {!loading &&
            (!currentProductApis || currentProductApis.length < 1) && (
              <div>
                <h5>{`No APIs are assigned to this product in APIM ${ctx.apimEnvironment.toString()}.`}</h5>
              </div>
            )}
          <div>
            Total: {uniqueApisCount} APIs, {currentProductApis.length} Versions
          </div>
        </div>
      </Panel>
    </div>
  );
};
