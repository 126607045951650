import { CompanyPicker } from "components/Core/CompanyPicker";
import React, { useState } from "react";
import { ListOfClients } from "./ListOfClients";
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { ICompany } from "models/ICompany";
import { Outcome } from "./Outcome";

interface ICompanyy {
    company: ICompany;
}

interface IEnablerOrDisabler{
  type: string;
}

const disabler = {
  header: "Block Access from an external Company",
  text1: "To disable all OAuth2-clients and subscriptions of a external company (blocking access to api.omv.com, api-test.omv.com, api-dev.omv.com), the following steps are needed:",
  text2: "Select the company of which you want to disable all OAuth2-clients and subscriptions",
  text3: "Confirm that all shown OAuth2-clients and subscriptions shall be disabled"
}

const enabler = {
  header: "Re-Enable Access from an external Company",
  text1: "To re-enable all OAuth2-clients and subscriptions of a external company (granting access to api.omv.com, api-test.omv.com, api-dev.omv.com), the following steps are needed",
  text2: "Select the company of which you want to enable all OAuth2-clients and subscriptions",
  text3: "Confirm that all shown OAuth2-clients and subscriptions shall be enabled"
}

export const ClientEnablerDisabler = (props:IEnablerOrDisabler) => {
  
  let [stepInProcess, setStepInProcess] = useState(0);
  let [companyObject, setCompanyObject] = useState<ICompanyy>();
  let [response, setResponse] = useState(null);
  let [type, setType] = useState(props.type);

  if(type !== props.type) {
    setStepInProcess(0);
    setType(props.type);
  }

  let texts = type === "disabler" ? disabler : enabler;

  return (
    <div>
        <h3>{texts.header}</h3>
        <br />
        {
          stepInProcess === 0 ? 
          <div>
            <div>
              {texts.text1}
              <ol>
                  <li>{texts.text2}</li>
                  <li>A list of affected clients is displayed</li>
                  <li>{texts.text3}</li>
              </ol>
              <div style={{width: "30%"}}>
                  <CompanyPicker renderOnlyPicker={true} getErrorMessage={() => {}} updateObjectFunction={setCompanyObject} setTouched={() => { return }} />
              </div>
              <br />
              <PrimaryButton onClick={() => setStepInProcess(1)} text="Next" disabled={companyObject === undefined ? true : false}/>
            </div>
          </div>
          :
          stepInProcess === 1 ? 
          <ListOfClients setStepInProcess={setStepInProcess} chosenCompany={companyObject?.company} setResponseFromBackend={setResponse} type={props.type} />
          :
          stepInProcess === 2 ?
          <Outcome setStepInProcess={setStepInProcess} setCompany={setCompanyObject} responseFromBackend={response} type={props.type} />
          : null 
        }
    </div>
    )
}