import { useState, useEffect, useContext } from "react";
import { Stack } from "@fluentui/react";
import { context } from "AppContext";
import { Loading } from "components/Core/Loading";
import { ProductSearch } from "../Common/ProductSearch";
import { LineChart } from "../Common/LineChart";
import { ComplianceWarningStatistics, IComplianceWarningStatistics } from "../../models/IComplianceWarningStatistics";

export const ComplianceWarningsStatisticsPanel = () => {
    const ctx = useContext(context)!;
    const LineChart_SIZE = 400;

    const [statistics, setComplianceWarningsStatistics] = useState<IComplianceWarningStatistics[]>([new ComplianceWarningStatistics()]);
    const [startDate, setstartDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() -6)));
    const [endDate, setendDate] = useState<Date>(new Date());
    const [productId, setproductId] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        handleSearch(startDate, endDate, productId);
    }, []);

    function handleSearch(startDate: Date, endDate: Date, productId: string) {
        if (startDate > endDate) {
            alert("Startdate should be less than EndDate");
            setLoading(false); 
            return;

        }
        let diff = Math.floor((endDate.valueOf() - startDate.valueOf()) / (1000 * 3600 * 24)) + 1;
        if (diff > 100) {
            alert("The report duration should be less than 100 days. ");
            setLoading(false); 
            return;
        }
            
        setLoading(true);
        if (!productId)
            productId = "";

        setstartDate(startDate);
        setendDate(endDate);
        setproductId(productId);
        ctx.backendClient
            .getComplianceWarningStatistics(productId, startDate, endDate)
            .then((res) => {
                setComplianceWarningsStatistics(res);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => { 
                setLoading(false); 
            });
    }

    return (
        <Stack>
            <Loading loading={loading} />
            <Stack>
                <h3>Welcome to The ComplianceWarningStatistics</h3>
            </Stack>
            <Stack style={{ marginTop: "25px" }}>
                <div>
                    Find Products base on the {" "}
                    <span
                        style={{
                            color: `${ctx.apimEnvironment === "DEV"
                                ? "#1FFF5A"
                                : ctx.apimEnvironment === "TEST"
                                    ? "orange"
                                    : "#95233A"
                                }`
                        }}
                    >
                        {ctx.apimEnvironment}  
                    </span>
                    <span> Env :</span> 
            </div>
            </Stack>
            <div >
                <ProductSearch startDate={startDate} endDate={endDate} producId={productId} handleSearch={handleSearch}></ProductSearch>
            </div>
            {(!loading) ? (
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col">
                        <LineChart
                            size={LineChart_SIZE}
                            toplabel="Dev Env"
                            data={
                                statistics?.map((e) => {
                                    return {
                                        day: e.createDate,
                                        info: e.devInfoWarningsCount,
                                        warning: e.devWarningWarningsCount,
                                        critical: e.devCriticalWarningsCount,
                                    };

                                })
                            }
                        />
                    </div>
                    <div className="ms-Grid-col">
                        <LineChart
                            size={LineChart_SIZE}
                            toplabel="Test Env"
                            data={statistics?.map((e) => {
                                return {
                                        day: e.createDate,
                                        info: e.testInfoWarningsCount,
                                        warning: e.testWarningWarningsCount,
                                        critical: e.testCriticalWarningsCount,
                                    };
                                })
                            }
                        />
                    </div>
                    <div className="ms-Grid-col">
                        <LineChart
                            size={LineChart_SIZE}
                            toplabel="Prod Env"
                            data={
                                statistics?.map((e) => {
                                    return {
                                        day: e.createDate,
                                        info: e.prodInfoWarningsCount,
                                        warning: e.prodWarningWarningsCount,
                                        critical: e.prodCriticalWarningsCount,
                                    };
                                })
                            }
                        />
                    </div>
                </div>
            ) : (
                <p>
                </p>
            )}
        </Stack>
    );
};
