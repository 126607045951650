import {
  Panel,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  DefaultButton,
  PanelType,
  TextField,
  ResponsiveMode
} from "@fluentui/react";
import { Loading } from "components/Core/Loading";
import { IClientAppListEntry } from "models/IClientAppListEntry";
import React, { useState, useEffect } from "react";
import { context } from "AppContext";
import Logger from "Logger";
import { IProduct } from "models/IProduct";
import CopyToClipboard from "react-copy-to-clipboard";

interface IClientAppsBasicAuthMappingProps {
  onDismiss: () => void;
  selectedProduct: IProduct | undefined;
  selectedClientApp: IClientAppListEntry | undefined;
}

export const ClientAppBasicAuthPanel = (
  props: IClientAppsBasicAuthMappingProps
) => {
  const ctx = React.useContext(context)!;
  const [
    isRemoveBasicAuthMappingConfirmationDialogVisible,
    setIsRemoveBasicAuthMappingConfirmationDialogVisible
  ] = useState(false);
  const [
    isUpdateBasicAuthMappingConfirmationDialogVisible,
    setIsUpdateBasicAuthMappingConfirmationDialogVisible
  ] = useState(false);
  const [
    isShowBasicAuthPoilicyDialogVisible,
    setIsShowBasicAuthPolicyDialogVisible
  ] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [basicAuthMappingExisting, setBasicAuthMappingExisting] =
    useState<boolean>(false);
  const [backendUsername, setBackendUsername] = useState<string>("");
  const [backendPassword, setBackendPassword] = useState<string>("");
  const [touched, setTouched] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    ctx.backendClient
      .getBasicAuthMappingForClientApp(
        props.selectedProduct?.id ?? "",
        props.selectedClientApp?.objectId ?? "",
        ctx.apimEnvironment
      )
      .then((result) => {
        setBasicAuthMappingExisting(true);
        setBackendUsername(result.username);
        setBackendPassword(result.password);
      })
      .catch((error) => {
        if (error.response.status !== 404) {
          Logger.Error(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    props.selectedClientApp,
    ctx.apimEnvironment,
    ctx.backendClient,
    props.selectedProduct
  ]);

  const setBasicAuthMapping = () => {
    if (props.selectedClientApp) {
      setLoading(true);
      ctx.backendClient
        .setBasicAuthMappingForClientApp(
          props.selectedProduct?.id ?? "",
          props.selectedClientApp?.objectId ?? "",
          backendUsername,
          backendPassword,
          ctx.apimEnvironment
        )
        .then(() => {
          setTouched(false);
          setBasicAuthMappingExisting(true);
          Logger.Success(
            "Basic Auth Mapping successfully added for this client app."
          );
        })
        .catch((error) => {
          Logger.Error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const removeBasicAuthMapping = () => {
    if (props.selectedClientApp) {
      setLoading(true);
      ctx.backendClient
        .removeBasicAuthMappingForClientApp(
          props.selectedProduct?.id ?? "",
          props.selectedClientApp?.objectId ?? "",
          ctx.apimEnvironment
        )
        .then(() => {
          props.onDismiss();
          setTouched(false);
          Logger.Success(
            "Basic Auth Mapping successfully removed for this client app."
          );
        })
        .catch((error) => {
          Logger.Error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Panel
        headerText={`${props.selectedClientApp?.displayName ?? ""}`}
        isOpen={true}
        onDismiss={() => {
          props.onDismiss();
        }}
        closeButtonAriaLabel="Close"
        layerProps={{ styles: { root: { zIndex: 1200 } } }}
        type={PanelType.medium}
        onOuterClick={() => {
          /*ignored*/
        }}
      >
        <Loading loading={loading} />
        <TextField
          label="Backend Username"
          value={backendUsername}
          onChange={(e) => {
            setBackendUsername((e.target as HTMLInputElement).value?.trim());
            setTouched(true);
          }}
          required
        />
        <TextField
          label="Backend Password"
          value={backendPassword}
          type="password"
          onChange={(e) => {
            setBackendPassword((e.target as HTMLInputElement).value?.trim());
            setTouched(true);
          }}
          required
        />
        <div className="ms-Grid-row" style={{ marginTop: "15px" }}>
          <div className="ms-Grid-col ms-lg12">
            <PrimaryButton
              text={basicAuthMappingExisting ? "Update" : "Add"}
              onClick={() => {
                if (basicAuthMappingExisting) {
                  setIsUpdateBasicAuthMappingConfirmationDialogVisible(true);
                } else {
                  setBasicAuthMapping();
                }
              }}
              disabled={
                backendUsername === "" || backendPassword === "" || !touched
              }
              allowDisabledFocus
            />
            <DefaultButton
              text="Cancel"
              onClick={() => {
                if (props.onDismiss) {
                  props.onDismiss();
                }
              }}
              styles={{ root: { marginLeft: 15 } }}
              allowDisabledFocus
            />
            {basicAuthMappingExisting && (
              <DefaultButton
                text="Remove"
                onClick={() => {
                  setIsRemoveBasicAuthMappingConfirmationDialogVisible(true);
                }}
                styles={{
                  root: {
                    backgroundColor: "#f00",
                    color: "#fff",
                    marginLeft: 15
                  }
                }}
                allowDisabledFocus
              />
            )}
          </div>
        </div>
        {basicAuthMappingExisting && (
          <div className="ms-Grid-row" style={{ marginTop: "15px" }}>
            <DefaultButton
              text="Show Basic Authentication API Policy"
              onClick={() => {
                setIsShowBasicAuthPolicyDialogVisible(true);
              }}
                          styles={{
                              root: {
                                  backgroundColor: "#008000",
                                  color: "#fff",
                                  marginLeft: 15
                              }
                          }}
              allowDisabledFocus
            />
          </div>
        )}
        <Dialog
          hidden={!isUpdateBasicAuthMappingConfirmationDialogVisible}
          onDismiss={() =>
            setIsUpdateBasicAuthMappingConfirmationDialogVisible(false)
          }
          dialogContentProps={{
            type: DialogType.normal,
            title: `Are you sure you want to update the basic authentication mapping for this client app, the variables for this will be updated in API Management and this new setting will be used to authenticate to the backend in your API policy?`
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                setBasicAuthMapping();
                setIsUpdateBasicAuthMappingConfirmationDialogVisible(false);
              }}
              text="Yes"
            />
            <DefaultButton
              onClick={() => {
                setIsUpdateBasicAuthMappingConfirmationDialogVisible(false);
              }}
              text="No"
            />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isRemoveBasicAuthMappingConfirmationDialogVisible}
          onDismiss={() =>
            setIsRemoveBasicAuthMappingConfirmationDialogVisible(false)
          }
          dialogContentProps={{
            type: DialogType.normal,
            title: `Are you sure you want to remove the basic authentication mapping from this client app? The basic auth mapping will not be usable in API policies anymore! Please make sure you remove the policy action from your API policies.`
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                removeBasicAuthMapping();
                setBackendUsername("");
                setBackendPassword("");
                setIsRemoveBasicAuthMappingConfirmationDialogVisible(false);
              }}
              text="Yes"
            />
            <DefaultButton
              onClick={() => {
                setIsRemoveBasicAuthMappingConfirmationDialogVisible(false);
              }}
              text="No"
            />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isShowBasicAuthPoilicyDialogVisible}
          onDismiss={() => {
            setIsShowBasicAuthPolicyDialogVisible(false);
            setCopied(false);
          }}
          dialogContentProps={{
            type: DialogType.normal,
            title: "API policy to add this basic backend authentication"
          }}
          modalProps={{
            isBlocking: true,
            styles: {
              main: {
                fontSize: 9,
                minWidth: "55% !important",
                maxWidth: "55% !important",
                width: "55% !important"
              }
            },
            responsiveMode: ResponsiveMode.medium
          }}
        >
          <div style={{ fontSize: 16 }}>
            <span>
            {`<include-fragment fragment-id="add-basic-authentication-mapped-from-oauth2token" />`}
            </span>
            <br />
            <br />
            <CopyToClipboard
              text={`<include-fragment fragment-id="add-basic-authentication-mapped-from-oauth2token" />`}
              onCopy={() => {
                setCopied(true);
              }}
            >
              {
                <div>
                  {(copied && <span>Copied!</span>) || (
                    <DefaultButton
                      id="CopyToClipBoardButton"
                      text="Copy to Clipboard"
                      ariaLabel="Copy to Clipboard"
                      onClick={() => {}}
                    />
                  )}
                  {"   "}
                  <DefaultButton
                    onClick={() => {
                      setIsShowBasicAuthPolicyDialogVisible(false);
                      setCopied(false);
                    }}
                    text="Close"
                  />
                </div>
              }
            </CopyToClipboard>
          </div>
          <DialogFooter></DialogFooter>
        </Dialog>
      </Panel>
    </div>
  );
};
