import { Configuration } from "@azure/msal-browser";
export const msalConfig: Configuration = {
  auth: {
    authority:
      "https://login.microsoftonline.com/a8f2ac6f-681f-4361-b51f-c85d86014a17",
    knownAuthorities: [
      "https://login.microsoftonline.com/a8f2ac6f-681f-4361-b51f-c85d86014a17"
    ],
    clientId: "5b72072e-6e51-4b82-a615-8b7483bcde6b",
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
} as any;

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
  scopes: ["openid", "profile", "User.Read"]
};
