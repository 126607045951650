import {  useState, useContext, useEffect } from "react";
import {
    Stack,
    DatePicker,
    DefaultButton,
    TagPicker,
    ITag,
    IBasePicker,
    IBasePickerSuggestionsProps
} from "@fluentui/react";
import { context } from "AppContext";
import { IProductListEntry } from "models/IProductListEntry";
import Autocomplete from "./Autocomplete";
import { Loading } from "../Core/Loading";
import React from "react";

interface ISearchProps {
    startDate: Date |  undefined;
    endDate: Date |  undefined;
    producId: string;
    handleSearch: any;
}

export const ProductSearch = (props: ISearchProps) => {
    const ctx = useContext(context)!;
    const [startdate, setstartDate] = useState(props.startDate);
    const [enddate, setendDate] = useState(props.endDate);
    const [selectedProduct, setSelectedProduct] = useState<ITag>();
    const [allProducts, setAllProducts] = useState<ITag[]>();

    useEffect(() => {
        const allProductsAsTags: ITag[] = [];
        ctx.products.forEach((pwo) => {
            allProductsAsTags.push({
                key: pwo.id,
                name: pwo.displayName
            });
        });
        setAllProducts(allProductsAsTags);
    }, [ctx.products]);



    const setSelectedStartDate = (date: Date) => {
        date.setHours(new Date().getHours());
        date.setMinutes(new Date().getMinutes());
        date.setSeconds(new Date().getSeconds());
        setstartDate(date);
    }

    const setSelectedEndDate = (date: Date) => {
        date.setHours(new Date().getHours());
        date.setMinutes(new Date().getMinutes());
        date.setSeconds(new Date().getSeconds());
        setendDate(date);
    }
    const picker = React.useRef<IBasePicker<ITag>>(null);
    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested products',
        noResultsFoundText: 'No product found'
    };
    const onProductSelected = React.useCallback((product?: ITag): ITag | null => {
        if (
            !product ||
            (picker.current && listContainsProductList(product, picker.current.items))
        ) {
            setSelectedProduct(undefined);
            return null;
        }
        setSelectedProduct(product);
        return product;
    }, []);
    const listContainsProductList = (tag: ITag, selectedProducts?: ITag[]) => {
        if (
            !selectedProducts ||
            !selectedProducts.length ||
            selectedProducts.length === 0
        ) {
            return false;
        }
        return selectedProducts.some((compareTag) => compareTag.key === tag.key);
    };

    const filterSelectedProducts = (
        filterText: string): ITag[] => {
        return filterText && allProducts
            ? allProducts.filter(
                (product) =>
                    product.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1
            )
            : [];
    };
    const getTextFromItem = (item: ITag) => item.name;




    return (
        <Stack>
            <Loading
                loading={ctx.loadingProducts}
            />
            <Stack horizontal tokens={{ childrenGap: 10 }}>
  
                <div style={{ "width": "20%" }}>
                    <TagPicker
                        removeButtonAriaLabel="Remove"
                        selectionAriaLabel="Selected colors"
                        componentRef={picker}
                        onResolveSuggestions={filterSelectedProducts}
                        onItemSelected={onProductSelected}
                        onChange={(products) => {
                            if (!products || products.length < 1) {
                                setSelectedProduct(undefined);
                            }
                        }}
                        getTextFromItem={getTextFromItem}
                        pickerSuggestionsProps={pickerSuggestionsProps}
                        itemLimit={1}
                        selectedItems={selectedProduct ? [selectedProduct] : []}
                        inputProps={{
                            id: 'productPicker'
                        }}
                    />
                </div>
                <div>
                    <span >StartDate:</span>
                </div>
                <div style={{ "width": "15%" }}>
                    <DatePicker  value={props.startDate} onSelectDate={setSelectedStartDate as (date: Date | null | undefined) => void} />
                </div>
                <div>
                    <span >EndDate:</span>
                </div>
                <div style={{ "width": "15%" }}>
                    <DatePicker value={props.endDate} onSelectDate={setSelectedEndDate as (date: Date | null | undefined) => void} />
                </div>

                <div style={{ "width": "10%" }}>
                    <DefaultButton onClick={() => props.handleSearch(startdate, enddate, selectedProduct?.key)} >Search</DefaultButton>
                </div>

            </Stack>
        </Stack>
    );
};
