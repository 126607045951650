import React, { useState, useEffect } from "react";
import { IProduct } from "models/IProduct";
import { context } from "AppContext";
import Logger from "Logger";
import { APIMEnvironment } from "models/APIMEnvironment";
import { ProductBackendPanel } from "./ProductBackendPanel";
import { Loading } from "components/Core/Loading";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  Panel,
  PanelType,
  Stack,
  PrimaryButton,
  DefaultButton,
  Label,
  Toggle,
  Dialog,
  DialogType,
  DialogFooter,
  ResponsiveMode
} from "@fluentui/react";
import { IBackendApp } from "models/IBackendApp";

interface IProductBackendOverviewPanelProps {
  visible: boolean;
  onDismiss: () => void;
  product: IProduct | undefined;
  loading: boolean;
}

export const ProductBackendOverviewPanel = (
  props: IProductBackendOverviewPanelProps
) => {
  const ctx = React.useContext(context)!;
  const [currentProduct, setCurrentProduct] = useState<IProduct>();
  const [isTestUsingSameBackendAsDev, setIsTestUsingSameBackendAsDev] =
    useState<boolean>(false);
  const [configuringBackendEnvironment, setConfiguringBackendEnvironment] =
    useState<APIMEnvironment>();
  const [loading, setLoading] = useState<boolean>();
  const [
    showJwtValidationPolicyForEnvironment,
    setShowJwtValidationPolicyForEnvironment
  ] = useState<APIMEnvironment>();
  const [copied, setCopied] = useState(false);
  const [currentBackendApp, setCurrentBackendApp] = useState<IBackendApp>();

  useEffect(() => {
    if (props.product) {
      setCurrentProduct({ ...props.product });
    }
    setIsTestUsingSameBackendAsDev(
      props.product?.backendAppIdForDevApim !== undefined &&
        props.product?.backendAppIdForDevApim !== null &&
        props.product?.backendAppIdForDevApim !== "" &&
        props.product?.backendAppIdForDevApim ===
          props.product?.backendAppIdForTestApim
    );
  }, [props.product]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  const setUseSameBackendForTestAsForDev = (useSame: boolean) => {
    setLoading(true);
    if (props.product?.id) {
      ctx.backendClient
        .setUseSameBackendForTestAsForDev(props.product?.id, useSame)
        .then(() => {
          if (useSame) {
            setCurrentProduct((prevState) => ({
              ...prevState!,
              backendAppIdForTestApim: prevState!.backendAppIdForDevApim
            }));
            setIsTestUsingSameBackendAsDev(true);
            Logger.Success(
              `TEST Backend config is now using same configuration as DEV backend config.`
            );
          } else {
            setCurrentProduct((prevState) => ({
              ...prevState!,
              backendAppIdForTestApim: ""
            }));
            setIsTestUsingSameBackendAsDev(false);
            Logger.Success(
              `TEST Backend config is now NOT using same configuration as DEV backend config. It is not configured now.`
            );
          }
        })
        .catch((err) => {
          Logger.Error(err);
        })
        .finally(() => setLoading(false));
    }
  };

  const loadBackendAppDataAndShowValidationPolicy = (env: APIMEnvironment) => {
    setLoading(true);
    ctx.backendClient
      .getBackendApp(props.product?.id!, APIMEnvironment[env])
      .then((res) => {
        setCurrentBackendApp(res);
        setShowJwtValidationPolicyForEnvironment(env);
      })
      .catch((err) => {
        Logger.Error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Panel
        headerText={`${
          currentProduct?.displayName ?? props.product?.displayName
        } Backends`}
        isOpen={props.visible && configuringBackendEnvironment === undefined}
        onDismiss={() => {
          props.onDismiss();
        }}
        closeButtonAriaLabel="Close"
        type={PanelType.custom}
        customWidth={"800px"}
        layerProps={{ styles: { root: { zIndex: 998 } } }}
        onOuterClick={() => {
          /*ignored*/
        }}
      >
        <Loading loading={loading || false} />
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack>
            <Label>
              <span style={{ color: "#1FFF5A" }}>DEV</span> environment
            </Label>
            {(currentProduct?.backendAppIdForDevApim &&
              currentProduct?.backendAppIdForDevApim !== "" && (
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <Label styles={{ root: { width: 400, fontWeight: 400 } }}>
                    Backend App Id: {currentProduct?.backendAppIdForDevApim}
                  </Label>
                  <DefaultButton
                    text="Edit"
                    onClick={() => {
                      setConfiguringBackendEnvironment(APIMEnvironment.DEV);
                    }}
                    allowDisabledFocus
                    styles={{ root: { width: 100 } }}
                  />
                  <DefaultButton
                    text="Show validation policy"
                    onClick={() => {
                      loadBackendAppDataAndShowValidationPolicy(
                        APIMEnvironment.DEV
                      );
                    }}
                    allowDisabledFocus
                    styles={{ root: { width: 200 } }}
                  />
                </Stack>
              )) || (
              <PrimaryButton
                text="Create Backend for DEV"
                onClick={() => {
                  setConfiguringBackendEnvironment(APIMEnvironment.DEV);
                }}
                allowDisabledFocus
                styles={{ root: { width: 250 } }}
              />
            )}
          </Stack>
          <Stack>
            <Label>
              <span style={{ color: "orange" }}>TEST</span> environment
            </Label>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
              {(currentProduct?.backendAppIdForTestApim &&
                currentProduct?.backendAppIdForTestApim !== "" &&
                !isTestUsingSameBackendAsDev && (
                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Label styles={{ root: { width: 400, fontWeight: 400 } }}>
                      Backend App Id: {currentProduct?.backendAppIdForTestApim}
                    </Label>
                    <DefaultButton
                      text="Edit"
                      onClick={() => {
                        setConfiguringBackendEnvironment(APIMEnvironment.TEST);
                      }}
                      allowDisabledFocus
                      disabled={isTestUsingSameBackendAsDev}
                      styles={{ root: { width: 100 } }}
                    />
                    <DefaultButton
                      text="Show validation policy"
                      onClick={() => {
                        loadBackendAppDataAndShowValidationPolicy(
                          APIMEnvironment.TEST
                        );
                      }}
                      allowDisabledFocus
                      styles={{ root: { width: 200 } }}
                    />
                  </Stack>
                )) || (
                <PrimaryButton
                  text="Create Backend for TEST"
                  onClick={() => {
                    setConfiguringBackendEnvironment(APIMEnvironment.TEST);
                  }}
                  allowDisabledFocus
                  disabled={
                    isTestUsingSameBackendAsDev ||
                    !currentProduct?.backendAppIdForDevApim ||
                    currentProduct?.backendAppIdForDevApim === ""
                  }
                  styles={{ root: { width: 250 } }}
                />
              )}
              {(isTestUsingSameBackendAsDev ||
                !currentProduct?.backendAppIdForTestApim ||
                currentProduct?.backendAppIdForTestApim === "") && (
                <Toggle
                  onText="Use the same as on DEV"
                  offText="Use the same as on DEV"
                  checked={isTestUsingSameBackendAsDev}
                  disabled={
                    !currentProduct?.backendAppIdForDevApim ||
                    currentProduct?.backendAppIdForDevApim === ""
                  }
                  onChange={(_, checked) => {
                    setUseSameBackendForTestAsForDev(checked ?? false);
                  }}
                />
              )}
            </Stack>
          </Stack>
          <Stack>
            <Label>
              <span style={{ color: "red" }}>PROD</span> environment
            </Label>
            {(currentProduct?.backendAppIdForProdApim &&
              currentProduct?.backendAppIdForProdApim !== "" && (
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <Label styles={{ root: { width: 400, fontWeight: 400 } }}>
                    Backend App Id: {currentProduct?.backendAppIdForProdApim}
                  </Label>
                  <DefaultButton
                    text="Edit"
                    onClick={() => {
                      setConfiguringBackendEnvironment(APIMEnvironment.PROD);
                    }}
                    allowDisabledFocus
                    disabled={false}
                    styles={{ root: { width: 100 } }}
                  />
                  <DefaultButton
                    text="Show validation policy"
                    onClick={() => {
                      loadBackendAppDataAndShowValidationPolicy(
                        APIMEnvironment.PROD
                      );
                    }}
                    allowDisabledFocus
                    styles={{ root: { width: 200 } }}
                  />
                </Stack>
              )) || (
              <PrimaryButton
                text="Create Backend for PROD"
                onClick={() => {
                  setConfiguringBackendEnvironment(APIMEnvironment.PROD);
                }}
                allowDisabledFocus
                disabled={
                  !currentProduct?.backendAppIdForTestApim ||
                  currentProduct?.backendAppIdForTestApim === ""
                }
                styles={{ root: { width: 250 } }}
              />
            )}
          </Stack>
          <DefaultButton
            text="Close"
            onClick={() => props.onDismiss()}
            allowDisabledFocus
            styles={{ root: { width: 100 } }}
          />
        </Stack>
      </Panel>
      <ProductBackendPanel
        visible={configuringBackendEnvironment !== undefined}
        onDismiss={(resultBackendApp) => {
          if (resultBackendApp) {
            switch (configuringBackendEnvironment) {
              case APIMEnvironment.DEV:
                setCurrentProduct((prevState) => ({
                  ...prevState!,
                  backendAppIdForDevApim: resultBackendApp.appId
                }));
                break;
              case APIMEnvironment.TEST:
                setCurrentProduct((prevState) => ({
                  ...prevState!,
                  backendAppIdForTestApim: resultBackendApp.appId
                }));
                break;
              case APIMEnvironment.PROD:
                setCurrentProduct((prevState) => ({
                  ...prevState!,
                  backendAppIdForProdApim: resultBackendApp.appId
                }));
                break;
            }
          }
          setConfiguringBackendEnvironment(undefined);
        }}
        product={currentProduct}
        loading={props.loading}
        env={configuringBackendEnvironment}
      />
      <Dialog
        hidden={showJwtValidationPolicyForEnvironment === undefined}
        onDismiss={() => {
          setShowJwtValidationPolicyForEnvironment(undefined);
          setCopied(false);
        }}
        dialogContentProps={{
          type: DialogType.normal,
          title: `Example JWT policy template for ${
            APIMEnvironment[showJwtValidationPolicyForEnvironment ?? 0]
          } API policies with product ${currentProduct?.displayName}`
        }}
        modalProps={{
          isBlocking: true,
          styles: {
            main: {
              fontSize: 9,
              minWidth: "55% !important",
              maxWidth: "55% !important",
              width: "55% !important",
            }
          },
          responsiveMode: ResponsiveMode.medium
        }}
      >
        <div style={{ fontSize: 16 }}>
          <span style={{ color: "green" }}>
            {`<!-- Place this policy after the <base /> tag in your API or operation policy-->`}
          </span>
          <br />
          <span>
            {`<validate-jwt header-name="Authorization" failed-validation-httpcode="401" failed-validation-error-message="Unauthorized. Access token is missing or invalid.">`}
          </span>
          <br />
          <span
            style={{ paddingLeft: 50 }}
          >{`<openid-config url="https://login.microsoftonline.com/a8f2ac6f-681f-4361-b51f-c85d86014a17/v2.0/.well-known/openid-configuration" />`}</span>
          <br />
          <span style={{ paddingLeft: 50 }}>{`<audiences>`}</span>
          <br />
          <span style={{ paddingLeft: 100 }}>{`<audience>${
            currentBackendApp?.identifierUri ?? ""
          }</audience>`}</span>
          <br />
          <span style={{ paddingLeft: 100 }}>{`<audience>${
            currentBackendApp?.appId ?? ""
          }</audience>`}</span>
          <span style={{ color: "green" }}>
            {`<!-- should be placed in a variable for different environments-->`}
          </span>
          <br />
          <span style={{ paddingLeft: 50 }}>{`</audiences>`}</span>
          <br />
          <span style={{ paddingLeft: 50 }}>{`<issuers>`}</span>
          <br />
          <span
            style={{ paddingLeft: 100 }}
          >{`<issuer>https://sts.windows.net/a8f2ac6f-681f-4361-b51f-c85d86014a17/</issuer>`}</span>
          <br />
          <span
            style={{ paddingLeft: 100 }}
          >{`<issuer>https://login.microsoftonline.com/a8f2ac6f-681f-4361-b51f-c85d86014a17/v2.0</issuer>`}</span>
          <br />
          <span style={{ paddingLeft: 50 }}>{`</issuers>`}</span>
          <br />
          <span style={{ paddingLeft: 50 }}>{`<required-claims>`}</span>
          <br />
          <span
            style={{ paddingLeft: 100 }}
          >{`<claim name="roles" match="any" separator=",">`}</span>
          <br />
          <span style={{ paddingLeft: 150, color: "green" }}>
            {`<!-- place roles according where you put the policy (e.g. for specific operations)-->`}
          </span>
          <br />
          <span
            style={{ paddingLeft: 150 }}
          >{`<value>{Role name(s)} possible values are: ${currentBackendApp?.appRoles
            .map((ar) => ar.name)
            .join(",")}</value>`}</span>

          <br />
          <span style={{ paddingLeft: 100 }}>{`</claim>`}</span>
          <br />
          <span style={{ paddingLeft: 50 }}>{`</required-claims >`}</span>
          <br />
          <span>{`</validate-jwt>`}</span>
          <br />
          <br />
          <CopyToClipboard
            text={`
<!-- Place this policy after the <base /> tag in your API or operation policy-->
<validate-jwt header-name="Authorization" failed-validation-httpcode="401" failed-validation-error-message="Unauthorized. Access token is missing or invalid.">
    <openid-config url="https://login.microsoftonline.com/a8f2ac6f-681f-4361-b51f-c85d86014a17/v2.0/.well-known/openid-configuration" />
    <audiences>
        <audience>${
          currentBackendApp?.identifierUri ?? ""
        }</audience><!-- should be placed in a variable for different environments-->
        <audience>${
          currentBackendApp?.appId ?? ""
        }</audience><!-- should be placed in a variable for different environments-->
    </audiences>
    <issuers>
        <issuer>https://sts.windows.net/a8f2ac6f-681f-4361-b51f-c85d86014a17/</issuer>
        <issuer>https://login.microsoftonline.com/a8f2ac6f-681f-4361-b51f-c85d86014a17/v2.0</issuer>
    </issuers>
    <required-claims>
        <claim name="roles" match="any" separator=",">
            <value>{Role name(s)} possible values are: ${currentBackendApp?.appRoles
              .map((ar) => ar.name)
              .join(
                ","
              )}</value><!-- place roles according where you put the policy (e.g. for specific operations)-->
        </claim>
    </required-claims >
</validate-jwt>`}
            onCopy={() => {
              setCopied(true);
            }}
          >
            {
              <div>
                {(copied && <span>Copied!</span>) || (
                  <DefaultButton
                    id="CopyToClipBoardButton"
                    text="Copy to Clipboard"
                    ariaLabel="Copy to Clipboard"
                    onClick={() => {}}
                  />
                )}
              {"   "}
              <DefaultButton
                  onClick={() => {
                    setShowJwtValidationPolicyForEnvironment(undefined);
                    setCopied(false);
                  }}
                  text="Close"
                />
              </div>
            }
          </CopyToClipboard>
        </div>
        <DialogFooter>
         
        </DialogFooter>
      </Dialog>
    </div>
  );
};
