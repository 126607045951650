import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useParams } from "react-router";
import { context } from "AppContext";
import Logger from "Logger";
import { toast } from "react-toastify";
import {
  TextField,
  Toggle,
  IconButton,
  PrimaryButton,
  ITextFieldProps,
  IButtonStyles,
  Stack,
  DefaultButton,
  Label
} from "@fluentui/react";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { Loading } from "components/Core/Loading";
import { InfoLabelButton } from "components/Core/InfoLabelButton";
import { IClientApp, IPublicClientData, IWebData } from "models/IClientApp";
import { IProduct } from "models/IProduct";
import { IBackendApp } from "models/IBackendApp";
import { AxiosError } from "axios";
import { CompanyPicker } from "components/Core/CompanyPicker";

export enum ClientAppFormMode {
  Create,
  Edit,
  AddPermissions
}

interface IClientAppDetailsProps {
  product?: IProduct;
  clientAppIdToEdit?: string;
  mode: ClientAppFormMode;
  isShownInPanel?: boolean;
  onCancel?: () => void;
  onSuccess?: (createdClientApp: IClientApp) => void;
}

export const ClientAppForm = (props: IClientAppDetailsProps) => {
  const { id }: any = useParams();
  const { appId }: any = useParams();
  const [productId] = useState(props.product?.id ?? id);
  const urlParams: queryString.ParsedQuery = queryString.parse(useLocation().search);
  const ctx = React.useContext(context)!;
  const history = useNavigate();
  const [backendApp, setBackendApp] = useState<IBackendApp>();
  const [addPermissionRequestorMail, setAddPermissionRequestorMail] =
    useState<string>();

  const [currentClientAppDetails, setCurrentClientAppDetails] =
    useState<IClientApp>({
      displayName: "",
      originalRequestorMail: "",
      requiredResourceAccess: [{ resourceAppId: "", resourceAccess: [] }]
    });
  const [loading, setLoading] = useState(true);

  const AddRemoveButtonStyles: IButtonStyles = {
    root: {
      display: "inline-block",
      float: "right",
      position: "relative",
      top: "28px"
    }
  };

  //load data from url parameters if present and data is not loaded from props
  useEffect(() => {
    if (props.mode === ClientAppFormMode.Create) {
      setCurrentClientAppDetails({
        displayName: "",
        originalRequestorMail: urlParams.requestorMail?.toString() ?? "",
        description: urlParams.description?.toString() ?? undefined,
        isWebAppClient:
          urlParams.isWebAppClient !== null &&
          urlParams.isWebAppClient !== undefined &&
          urlParams.isWebAppClient === "true",
        web:
          urlParams.isWebAppClient !== null &&
            urlParams.isWebAppClient !== undefined &&
            urlParams.isWebAppClient === "true"
            ? {
              homePageUrl: urlParams.webAppHomepageUrl?.toString(),
              logoutUrl: urlParams.webAppLogoutUrl?.toString(),
              redirectUris: urlParams.webAppRedirectUris
                ?.toString()
                .split(";") || [""]
            }
            : undefined,
        isPublicClient:
          urlParams.isPublicClient !== null &&
          urlParams.isPublicClient !== undefined &&
          urlParams.isPublicClient === "true",
        publicClient:
          urlParams.isPublicClient !== null &&
            urlParams.isPublicClient !== undefined &&
            urlParams.isPublicClient === "true"
            ? {
              redirectUris: urlParams.publicClientRedirectUris
                ?.toString()
                .split(";") || [""]
            }
            : undefined,
        requiredResourceAccess: []
      });
    } else if (props.mode === ClientAppFormMode.AddPermissions) {
      setAddPermissionRequestorMail(
        urlParams.addPermissionRequestorMail?.toString()
      );
    }
  }, [
    productId,
    props.mode,
    urlParams.addPermissionRequestorMail,
    urlParams.description,
    urlParams.isPublicClient,
    urlParams.isWebAppClient,
    urlParams.publicClientRedirectUris,
    urlParams.requestorMail,
    urlParams.webAppLogoutUrl,
    urlParams.webAppHomepageUrl,
    urlParams.webAppRedirectUris
  ]);

  const loadBackendAppData = useCallback(() => {
    const loadNeededFormDataFromBackend = async () => {
      const backendAppPromise = ctx.backendClient
        .getBackendApp(productId, ctx.apimEnvironment)
        .then((res) => {
          setBackendApp(res);
        })
        .catch((err) => {
          setBackendApp(undefined);
          Logger.Error(err);
        });

      //either get clientapp by selected listentry or by appId from url path if present
      if (props.mode !== ClientAppFormMode.Create) {
        const clientAppToEditPromise = ctx.backendClient
          .getClientApp(productId, props.clientAppIdToEdit ?? appId)
          .then((clientApp) => {
            setCurrentClientAppDetails(clientApp);
          })
          .catch((error) => Logger.Error(error));
        Promise.all([backendAppPromise, clientAppToEditPromise]).then(() =>
          setLoading(false)
        );
        //otherwise just load backend app (when creating new client app)
      } else {
        await backendAppPromise.then(() => setLoading(false));
      }
    };
    loadNeededFormDataFromBackend();
  }, [
    ctx.apimEnvironment,
    productId,
    appId,
    props.mode,
    props.clientAppIdToEdit,
    ctx.backendClient
  ]);

  //load needed data (backendapp and clientapp to edit selected from props)
  useEffect(() => {
    loadBackendAppData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createExpandableStringArrayControls = (
    stringlist: string[],
    updateToState: (newStringlist: string[]) => void,
    controlBaseKey: string,
    controlLabel: string
  ) => {
    let controls: JSX.Element[] = [];
    stringlist.forEach((string, index: number) => {
      controls.push(
        <div key={`${controlBaseKey}Stack${index}`}>
          <TextField
            key={`${controlBaseKey}Textfield${index}`}
            label={`${controlLabel} ${index + 1}`}
            value={stringlist[index]}
            onChange={(e) => {
              let newStrings = [...stringlist];
              newStrings[index] = (e.target as HTMLInputElement).value;
              updateToState(newStrings);
            }}
            required={true}
            disabled={props.mode !== ClientAppFormMode.Create}
            onGetErrorMessage={() =>
              getRedirectUrlErrorMessage(stringlist, index)
            }
            styles={{ root: { width: "82%", display: "inline-block" } }}
            summary={`The redirect URL where the access token is allowed to be sent to (for web applications and public clients). 
                      All redirect URLs have to be specified, otherwise Azure AD will show an error and will not deliver the access token.`}
            onRenderLabel={onRenderLabelInfoButton}
          />
          <IconButton
            key={`${controlBaseKey}RemoveButton${index}`}
            iconProps={{ iconName: "Remove" }}
            ariaLabel={`Remove ${controlBaseKey}`}
            styles={AddRemoveButtonStyles}
            disabled={index < 1 || props.mode !== ClientAppFormMode.Create}
            onClick={() => {
              if (index > 0) {
                let newStrings = [...stringlist];
                newStrings.splice(index, 1);
                updateToState(newStrings);
              }
            }}
          />
          {index === stringlist.length - 1 && (
            <IconButton
              key={`${controlBaseKey}AddButton${index}`}
              iconProps={{ iconName: "Add" }}
              ariaLabel={`Add ${controlBaseKey}`}
              styles={AddRemoveButtonStyles}
              disabled={props.mode !== ClientAppFormMode.Create}
              onClick={() => {
                let newStrings = [...stringlist];
                newStrings.push("");
                updateToState(newStrings);
              }}
            />
          )}
        </div>
      );
    });
    return controls;
  };

  const saveClientApp = () => {
    setLoading(true);
    const clientAppDataToSend = {
      ...currentClientAppDetails,
      displayName:
        getDisplayNamePrefix() +
        currentClientAppDetails.displayName
          .replace(getDisplayNamePrefix(), "")
          .trim(),
      originalRequestorMail:
        addPermissionRequestorMail ??
        currentClientAppDetails.originalRequestorMail,
      companyId: currentClientAppDetails.company?.id,
      company: undefined
    } as IClientApp;
    //create client app if in new mode
    if (props.mode === ClientAppFormMode.Create) {
      ctx.backendClient
        .createClientApp(productId, clientAppDataToSend, ctx.apimEnvironment)
        .then((createdClientApp) => {
          Logger.Success(
            `Client app registration ${createdClientApp.displayName} successfully created.`
          );
          if (props.onSuccess) {
            if (!createdClientApp.originalRequestorMail) {
              createdClientApp.originalRequestorMail =
                clientAppDataToSend.originalRequestorMail;
            }

            if(!createdClientApp.company) {
              createdClientApp.company = ctx.companies.find((comp) => {
                return comp.id === clientAppDataToSend.companyId;
              });
            }
            props.onSuccess(createdClientApp);
          } else {
            history("/", { replace: true });
            window.dispatchEvent(new Event("popstate"));
          }
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 504) {
            //Intermediate network error, returning 504 with timeout of 20s. The backend app is created successfully by the POP backend.
            Logger.Success(
              `The creation of client app registration takes more time than usually. Please check the if the client app has been created after a few minutes. If not, please contact api-support@omv.com.`
            );
          } else {
            Logger.Error(err);
          }
        })
        .finally(() => setLoading(false));
    }
    //update client app when in edit mode
    else {
      ctx.backendClient
        .updateClientApp(productId, clientAppDataToSend)
        .then(() => {
          Logger.Success(`Client app registration saved.`);
          if (props.onSuccess) {
            clientAppDataToSend.company = currentClientAppDetails.company;
            props.onSuccess(clientAppDataToSend);
          } else {
            history("/", { replace: true });
          }
        })
        .catch((err) => Logger.Error(err))
        .finally(() => setLoading(false));
    }
  };
  const createControlsForPermissions = (
    header: string,
    type: string,
    backendPermissions: any
  ) => {
    let controls: JSX.Element[] = [];
    controls.push(<div key="permissionheader">{header}</div>);
    backendPermissions?.forEach((permissionFromBackendApp: any) => {
      controls.push(
        <Toggle
          key={`${type}Toggle${permissionFromBackendApp.name}`}
          label={permissionFromBackendApp.name}
          onText="Granted"
          offText="Not granted"
          checked={
            currentClientAppDetails?.requiredResourceAccess[0]?.resourceAccess?.some(
              (currentGrantedPermission) =>
                currentGrantedPermission.id === permissionFromBackendApp.id
            ) ?? false
          }
          onChange={(_, checked) => {
            let newResourceAccess = [
              ...(currentClientAppDetails.requiredResourceAccess[0]
                ?.resourceAccess || [])
            ];
            if (checked) {
              newResourceAccess.push({
                id: permissionFromBackendApp.id,
                type: type
              });
            } else {
              newResourceAccess = newResourceAccess.filter(
                (currentGrantedPermission) => {
                  return (
                    currentGrantedPermission.id !== permissionFromBackendApp.id
                  );
                }
              );
            }
            setCurrentClientAppDetails((prevState) => ({
              ...prevState!,
              requiredResourceAccess: [
                {
                  resourceAppId:
                    prevState.requiredResourceAccess[0]?.resourceAppId ??
                    backendApp?.appId,
                  resourceAccess: newResourceAccess
                }
              ]
            }));
          }}
        />
      );
    });
    return controls;
  };

  const getRedirectUrlErrorMessage = (
    list: string[] | undefined,
    index: number = -1
  ) => {
    if (!list) {
      return "At least one redirect URL is required";
    }
    const matchHttpsUrlOrLocalhost =
      /(https:\/\/([a-zA-Z0-9]+)|(http:\/\/localhost)|(http:\/\/127.0.0.1))/;
    if (index === -1) {
      for (let i = 0; i < list.length; i++) {
        if (!list[i] || list[i].trim() === "") {
          return "Redirect URL is required";
        } else if (
          list[i] &&
          list[i].trim() !== "" &&
          (!matchHttpsUrlOrLocalhost.test(list[i]) ||
            list[i].trim().indexOf(" ") > -1)
        )
          return "Redirect URL must be a valid URL that starts with 'https://' or with 'http://' on localhost";
      }
    } else if (!list[index] || list[index].trim() === "")
      return "Redirect URL is required";
    else if (
      list[index] &&
      list[index].trim() !== "" &&
      (!matchHttpsUrlOrLocalhost.test(list[index]) ||
        list[index].trim().indexOf(" ") > -1)
    )
      return "Redirect URL must be a valid URL that starts with 'https://' or with 'http://' on localhost";
    return "";
  };

  const getErrorMessage = (fieldName: string) => {
    if (loading) {
      return "";
    }
    const matchHttpsUrl = /https:\/\/([a-zA-Z0-9]+)/;
    let message = "";
    switch (fieldName) {
      case "all":
        if (
          !currentClientAppDetails.requiredResourceAccess[0] ||
          currentClientAppDetails.requiredResourceAccess[0]?.resourceAccess
            .length < 1
        ) {
          message = "You have to grant at least one App Role or User Scope";
          break;
        }
      // falls through
      case "displayName":
        const matchIllegalCharacter = /[&#:<>/]/;
        if (
          !currentClientAppDetails.displayName ||
          currentClientAppDetails.displayName.trim() === ""
        ) {
          message = "Please add a descriptive suffix to the app display name";
          break;
        } else if (
          matchIllegalCharacter.test(currentClientAppDetails.displayName)
        ) {
          message =
            "Client app display name contains illegal character(s) - (&#:<>/)";
          break;
        }
        if (fieldName !== "all") break;
      // falls through
      case "requestorMail":
        if (!currentClientAppDetails?.originalRequestorMail) {
          message = "Requestor mail is required";
          break;
        } else if (
          !validateEmail(currentClientAppDetails.originalRequestorMail)
        ) {
          message = "Requestor mail is not a valid email";
          break;
        }
        if (fieldName !== "all") break;
      // falls through
      case "webAppHomepageURL":
        if (
          currentClientAppDetails.isWebAppClient &&
          currentClientAppDetails.web?.homePageUrl &&
          currentClientAppDetails.web?.homePageUrl.trim() !== "" &&
          (!matchHttpsUrl.test(currentClientAppDetails.web?.homePageUrl) ||
            currentClientAppDetails.web?.homePageUrl?.trim().indexOf(" ") > -1)
        ) {
          message =
            "Web app homepage URL must be a valid URL starting with 'https://'";
          break;
        }
        if (fieldName !== "all") break;
      // falls through
      case "webAppLogoutURL":
        if (
          currentClientAppDetails.isWebAppClient &&
          currentClientAppDetails.web?.logoutUrl &&
          currentClientAppDetails.web?.logoutUrl.trim() !== "" &&
          (!matchHttpsUrl.test(currentClientAppDetails.web?.logoutUrl) ||
            currentClientAppDetails.web?.logoutUrl?.trim().indexOf(" ") > -1)
        ) {
          message =
            "Web app logout URL must be a valid URL starting with 'https://'";
          break;
        }
        if (fieldName !== "all") break;
      // falls through
      case "company":
        if (!currentClientAppDetails.company) {
          message = "Please select a valid company";
        }
        break;
    }
    return message;
  };

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const onRenderLabelInfoButton = (props: ITextFieldProps | undefined) => (
    <InfoLabelButton id="test" {...props} />
  );

  const getDisplayNamePrefix = () => {
    return `apim-clientapp-${ctx.apimEnvironment?.toString().toLowerCase()}-`;
  };

  const getTitle = () => {
    switch (props.mode) {
      case ClientAppFormMode.Create:
        return `Create client app registration`;
      case ClientAppFormMode.Edit:
        return `Edit ${currentClientAppDetails.displayName}`;
      case ClientAppFormMode.AddPermissions:
        return `Add Product permissions to ${currentClientAppDetails.displayName}`;
    }
  };
  return (
    <div>
      <Loading loading={loading || ctx.loadingComplianceWarnings} />
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-lg12">
          <h3>{getTitle()}</h3>
          <Label style={{ fontSize: "20px" }}>
            Environment:{" "}
            <span
              style={{
                color: `${ctx.apimEnvironment === "DEV"
                  ? "#1FFF5A"
                  : ctx.apimEnvironment === "TEST"
                    ? "orange"
                    : "#95233A"
                  }`
              }}
            >
              {ctx.apimEnvironment}
            </span>
          </Label>
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-xxxl4 ms-xxl6 ms-xl6 ms-lg12 ms-md12 ms-sm12">
          <TextField
            disabled
            label="Product Id"
            value={productId}
            onChange={() => { }}
          />
          <TextField
            label="Client App Requestor Mail"
            value={currentClientAppDetails.originalRequestorMail ?? ""}
            onChange={(e) => {
              setCurrentClientAppDetails((prevState) => ({
                ...prevState!,
                originalRequestorMail: (
                  e.target as HTMLInputElement
                ).value?.trim()
              }));
            }}
            required
            disabled={props.mode !== ClientAppFormMode.Create}
            onGetErrorMessage={() => getErrorMessage("requestorMail")}
            summary={`The email address of the original requestor of the OAuth client app registration sent from the developer portal. 
                      After successful creation of the app registration the credentials (app id and link to app secret will be sent to this email address). 
                      You can change it if you want to sent the credentials to a different email address.`}
            onRenderLabel={onRenderLabelInfoButton}
          />
          <TextField
            label="Client app display name"
            prefix={getDisplayNamePrefix()}
            value={
              currentClientAppDetails.displayName?.replace(
                getDisplayNamePrefix(),
                ""
              ) ?? ""
            }
            onChange={(e) =>
              setCurrentClientAppDetails((prevState) => ({
                ...prevState!,
                displayName: (e.target as HTMLInputElement).value
              }))
            }
            disabled={props.mode !== ClientAppFormMode.Create}
            required
            onGetErrorMessage={() => getErrorMessage("displayName")}
            summary={`The display name for the new client app registration as it is visible in the Azure Portal. This value is prefixed by the schema:
                      apim-clientapp-<apim_environment>-<product_id>-. Please add a descriptive suffix for the app display name.`}
            onRenderLabel={onRenderLabelInfoButton}
          />
          <TextField
            label="Description"
            value={currentClientAppDetails.description ?? ""}
            onChange={(e) =>
              setCurrentClientAppDetails((prevState) => ({
                ...prevState!,
                description: (e.target as HTMLInputElement).value ?? undefined
              }))
            }
            multiline
            disabled={props.mode === ClientAppFormMode.AddPermissions}
            autoAdjustHeight
            summary={`The description for the client represented by the client app registration as it has been defined by the requestor in the developer portal. 
                      Feel free to adjust it or append information to it.`}
            onRenderLabel={onRenderLabelInfoButton}
          />
          <CompanyPicker
            updateObjectFunction={setCurrentClientAppDetails}
            object={currentClientAppDetails}
            setTouched={() => { }}
            getErrorMessage={getErrorMessage}
          />
          <Toggle
            label={
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{
                  childrenGap: 4,
                  maxWidth: 300
                }}
              >
                <span>Is web app client </span>
                <InfoLabelButton
                  id="isWebAppClientInfoButton"
                  summary={`Is the client a web application hosted on a server then check this. 
                                      Such clients usually have a user context and use the authentication code OAuth2 flow. 
                                      Such clients get the access token returned to the web application at a specific redirect URL configured below.`}
                />
              </Stack>
            }
            onText="Yes"
            offText="No"
            checked={
              currentClientAppDetails.isWebAppClient !== undefined &&
              currentClientAppDetails.isWebAppClient != null &&
              currentClientAppDetails.isWebAppClient !== false
            }
            disabled={props.mode !== ClientAppFormMode.Create}
            onChange={(_, checked) => {
              setCurrentClientAppDetails(((prevState: any) => ({
                ...prevState!,
                isWebAppClient: checked || false,
                web: !checked ? undefined : prevState.web
              })) as any);
            }}
          />
          {currentClientAppDetails.isWebAppClient && (
            <div>
              <TextField
                label="Web app hompage URL"
                value={currentClientAppDetails.web?.homePageUrl ?? ""}
                onChange={(e) => {
                  const newWebData: IWebData = { ...currentClientAppDetails }
                    .web ?? { redirectUris: [""] };
                  newWebData.homePageUrl = (
                    e.target as HTMLInputElement
                  ).value?.trim();
                  setCurrentClientAppDetails((prevState) => ({
                    ...prevState!,
                    web: newWebData
                  }));
                }}
                summary={`The homepage URL of the web application.`}
                disabled={props.mode !== ClientAppFormMode.Create}
                onRenderLabel={onRenderLabelInfoButton}
                onGetErrorMessage={() => getErrorMessage("webAppHomepageURL")}
              />
              <TextField
                label="Web app logout URL"
                value={currentClientAppDetails.web?.logoutUrl ?? ""}
                onChange={(e) => {
                  const newWebData: IWebData = { ...currentClientAppDetails }
                    .web ?? { redirectUris: [""] };
                  newWebData.logoutUrl = (
                    e.target as HTMLInputElement
                  ).value?.trim();
                  setCurrentClientAppDetails((prevState) => ({
                    ...prevState!,
                    web: newWebData
                  }));
                }}
                summary={`The logout URL of the web application. It will be shown when the user of the client logs out.`}
                disabled={props.mode !== ClientAppFormMode.Create}
                onRenderLabel={onRenderLabelInfoButton}
                onGetErrorMessage={() => getErrorMessage("webAppLogoutURL")}
              />
              {currentClientAppDetails.isWebAppClient &&
                createExpandableStringArrayControls(
                  currentClientAppDetails?.web?.redirectUris ?? [""],
                  (newRedirectUris) => {
                    const newWebData: IWebData = { ...currentClientAppDetails }
                      .web ?? { redirectUris: [""] };
                    newWebData.redirectUris = newRedirectUris;
                    setCurrentClientAppDetails((prevState) => ({
                      ...prevState!,
                      web: newWebData
                    }));
                  },
                  "webAppRedirectUri",
                  "Web app redirect URL"
                )}
            </div>
          )}
          <Toggle
            label={
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{
                  childrenGap: 4,
                  maxWidth: 300
                }}
              >
                <span>Is public client </span>
                <InfoLabelButton
                  id="isPublicClientInfoButton"
                  summary={`Is the client a public client running on a public device like e.g. a phone or a TV. 
                              Such clients usually have a user context and use the authentication code OAuth2 flow with PKCE. 
                              Such clients get the access token returned to public client at a specific redirect URL configured below. `}
                />
              </Stack>
            }
            onText="Yes"
            offText="No"
            checked={
              currentClientAppDetails.isPublicClient !== undefined &&
              currentClientAppDetails.isPublicClient !== null &&
              currentClientAppDetails.isPublicClient !== false
            }
            disabled={props.mode !== ClientAppFormMode.Create}
            onChange={(e, checked) => {
              setCurrentClientAppDetails(((prevState: any) => ({
                ...prevState!,
                isPublicClient: checked || false,
                publicClient: !checked ? undefined : prevState.publicClient
              })) as any);
            }}
          />
          {currentClientAppDetails.isPublicClient &&
            createExpandableStringArrayControls(
              currentClientAppDetails?.publicClient?.redirectUris ?? [""],
              (newRedirectUris) => {
                const newPublicClientData: IPublicClientData = {
                  ...currentClientAppDetails
                }.publicClient ?? { redirectUris: [] };
                newPublicClientData.redirectUris = newRedirectUris;
                setCurrentClientAppDetails((prevState) => ({
                  ...prevState!,
                  publicClient: newPublicClientData
                }));
              },
              "publicClientRedirectUri",
              "Public client redirect URL"
            )}
        </div>
      </div>
      <div className="ms-Grid-row" style={{ marginTop: "15px" }}>
        <div className="ms-Grid-col ms-lg12">
          <h5>{`Permissions for backend app ${backendApp?.displayName}`}</h5>
          {backendApp &&
            backendApp.appRoles &&
            backendApp.appRoles.length > 0 &&
            createControlsForPermissions(
              "App Roles:",
              "Role",
              backendApp.appRoles
            )}
        </div>
      </div>
      <div className="ms-Grid-row" style={{ marginTop: "15px" }}>
        <div className="ms-Grid-col ms-lg12">
          {backendApp &&
            backendApp.scopes &&
            backendApp.scopes.length > 0 &&
            createControlsForPermissions(
              "User context Scopes:",
              "Scope",
              backendApp.scopes
            )}
        </div>
      </div>
      <div className="ms-Grid-row" style={{ marginTop: "15px" }}>
        <div className="ms-Grid-col ms-lg12">
          {addPermissionRequestorMail && (
            <p>
              Notification email will be sent to the requestor of the permission
              update:
              <strong>{" " + addPermissionRequestorMail}</strong>
            </p>
          )}
        </div>
      </div>
      <div className="ms-Grid-row" style={{ marginTop: "15px" }}>
        <div className="ms-Grid-col ms-lg12">
          <PrimaryButton
            text={props.mode === ClientAppFormMode.Create ? "Create" : "Save"}
            onClick={() => {
              let errorMessage: string = !currentClientAppDetails.isWebAppClient
                ? ""
                : getRedirectUrlErrorMessage(
                  currentClientAppDetails.web?.redirectUris
                );
              errorMessage = !currentClientAppDetails.isPublicClient
                ? errorMessage
                : getRedirectUrlErrorMessage(
                  currentClientAppDetails.publicClient?.redirectUris
                );
              const staticFieldsErrorMessage = getErrorMessage("all");
              errorMessage =
                staticFieldsErrorMessage && staticFieldsErrorMessage.length > 0
                  ? staticFieldsErrorMessage
                  : errorMessage;
              if (errorMessage) {
                toast.error(errorMessage, { autoClose: 15000 });
              } else {
                saveClientApp();
              }
            }}
            allowDisabledFocus
          />
          {props.isShownInPanel && (
            <DefaultButton
              text="Cancel"
              onClick={() => {
                if (props.onCancel) {
                  props.onCancel();
                }
              }}
              styles={{ root: { marginLeft: 15 } }}
              allowDisabledFocus
            />
          )}
        </div>
      </div>
    </div>
  );
};
