import { toast } from "react-toastify";

const ERROR_AUTOCLOSE_DELAY = 20000;

export default class Logger {
    static Error = (error: any) => {
        console.error(error);
        if (error?.response?.data?.errors) {
            toast.error(JSON.stringify(error?.response?.data?.errors),
                {
                    autoClose: ERROR_AUTOCLOSE_DELAY,
                });
            console.log(JSON.stringify(error?.response?.data?.errors),
                {
                    autoClose: ERROR_AUTOCLOSE_DELAY,
                });
        } else if (error?.response?.data?.title) {
            toast.error(error?.response?.data?.title,
                {
                    autoClose: ERROR_AUTOCLOSE_DELAY,
                });
            console.log(error?.response?.data?.title,
                {
                    autoClose: ERROR_AUTOCLOSE_DELAY,
                });
        } else if (error?.response?.data?.errorMessage) {
            toast.error(error?.response?.data?.errorMessage,
                {
                    autoClose: ERROR_AUTOCLOSE_DELAY,
                });
            console.log(error?.response?.data?.errorMessage,
                {
                    autoClose: ERROR_AUTOCLOSE_DELAY,
                });
        } else {
            toast.error(error.message, { autoClose: ERROR_AUTOCLOSE_DELAY });
            console.log(error.message);
        }
    };

    static Success = (message: string) => {
        console.log(message);
        toast.success(message);
    };
}