import { AccountInfo } from "@azure/msal-common";
import {
    ClientAppForm,
    ClientAppFormMode
} from "./components/ClientAppManagement/ClientAppForm";
import { ClientSecret } from "./components/ClientAppManagement/ClientSecret";
import { Home } from "./components/Dashboard/Home";
import { loadTheme } from "@fluentui/react";
import { Layout } from "./components/LayoutAndNavigation/Layout";
import { Loading } from "./components/Core/Loading";
import Logger from "./Logger";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { ProductList } from "./components/ProductManagement/ProductList";
import "office-ui-fabric-core/dist/css/fabric.min.css";
import "./custom.css";
import { ProductRequestOwnership } from "./components/ProductManagement/ProductRequestOwnership";
import { ProductOwnershipRequestsList } from "./components/ProductManagement/ProductOwnershipRequestsList";
import { ExportStakeholders } from "./components/Admin/ExportStakeholders";
import { context } from "./AppContext";
import { useMsal, AuthenticatedTemplate } from "@azure/msal-react";
import { ComplianceWarningsExceptionRequests } from "components/ComplianceWarnings/ComplianceWarningsExceptionRequests";
import { EditProductSubscriptionForm } from "components/SubscriptionManagement/EditProductSubscriptionForm";
import { IComplianceWarning } from "models/IComplianceWarning";
import { ClientEnablerDisabler } from "components/Security/ClientEnablerDisabler";
import { ComplianceWarningsStatisticsPanel } from "./components/ComplianceWarnings/ComplianceWarningsStatisticsPanel";

loadTheme({
    palette: {
        themePrimary: "#052759",
        themeLighterAlt: "#eff4f9",
        themeLighter: "#c2d4e7",
        themeLight: "#92b2d1",
        themeTertiary: "#4172a3",
        themeSecondary: "#0e4378",
        themeDarkAlt: "#002e5c",
        themeDark: "#00274e",
        themeDarker: "#001d39",
        neutralLighterAlt: "#faf9f8",
        neutralLighter: "#f3f2f1",
        neutralLight: "#edebe9",
        neutralQuaternaryAlt: "#e1dfdd",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c6c4",
        neutralTertiary: "#595959",
        neutralSecondary: "#373737",
        neutralPrimaryAlt: "#2f2f2f",
        neutralPrimary: "#000000",
        neutralDark: "#151515",
        black: "#0b0b0b",
        white: "#ffffff"
    }
});

export const App = () => {
    const ctx = React.useContext(context)!;
    const [isLoading, setLoading] = useState<boolean>(true);
    const { instance, accounts } = useMsal();
    const [activeAccount, setActiveAccount] = useState<AccountInfo>();

    useEffect(() => {
        if (accounts.length > 0 && !instance.getActiveAccount()) {
            instance.setActiveAccount(instance.getAllAccounts()[0]);
            setActiveAccount(instance.getAllAccounts()[0]);
        } else if (instance.getActiveAccount()) {
            setActiveAccount(instance.getActiveAccount()!);
        }
    }, [accounts, instance]);

    useEffect(() => {
        if (activeAccount) {
            ctx.backendClient
                .isLoggedInUserPOPAdmin()
                .then((isAdmin: boolean) => ctx.setIsLoggedInUserPOPAdmin(isAdmin))
                .catch((error: boolean) => Logger.Error(error));

            ctx.backendClient
                .isLoggedInUserComplianceManager()
                .then((isComplianceManager: boolean) =>
                    ctx.setIsLoggedInUserComplianceManager(isComplianceManager)
                )
                .catch((error: boolean) => Logger.Error(error));

            ctx.backendClient
                .isLoggedInSecurityAdmin()
                .then((isSecurityAdmin: boolean) =>
                    ctx.setIsLoggedInSecurityAdmin(isSecurityAdmin)
                )
                .catch((error: boolean) => Logger.Error(error));
        }
    }, [ctx, activeAccount]);

    useEffect(() => {
        if (activeAccount) {
            ctx.backendClient
                .getCSRFToken()
                .catch((error: any) => Logger.Error(error))
                .finally(() => {
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeAccount?.username]);

    useEffect(() => {
        ctx.setLoadingProducts(true);
        ctx.backendClient
            .getProducts(ctx.apimEnvironment)
            .then((products) => {
                ctx.setProducts(products);
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    console.log(
                        "got 400 error from loading products in first place, reloading site..."
                    );
                    window.location.reload();
                } else {
                    Logger.Error(error);
                }
            })
            .finally(() => {
                ctx.setLoadingProducts(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctx.apimEnvironment]);

    useEffect(() => {
        if (ctx.products) {
            ctx.setLoadingComplianceWarnings(true);
            var loadingComplianceWarningTasks: Promise<void | { product: any, complianceWarnings: any }>[] = [];
            ctx.products.forEach((p) => {
                var loadingComplianceWarningTask = ctx.backendClient
                    .getComplianceWarnings(p.id, ctx.apimEnvironment)
                    .then((complianceWarnings) => {
                        return { product: p, complianceWarnings: complianceWarnings }
                    })
                    .catch((error) => {
                        Logger.Error(error);
                    });

                loadingComplianceWarningTasks.push(loadingComplianceWarningTask);
            });
            Promise.all(loadingComplianceWarningTasks).then((results) => {
                const map = new Map(results.map((obj) => [obj?.product?.id, obj?.complianceWarnings.sort(
                    (c1: IComplianceWarning, c2: IComplianceWarning) => c1.severity - c2.severity)]
                ));
                ctx.setComplianceWarningsForAllProducts(map);
                ctx.setLoadingComplianceWarnings(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctx.products]);

    useEffect(() => {
        if (ctx.backendClient) {
            ctx.backendClient
                .getCompanies()
                .then((companies) => {
                    ctx.setCompanies(companies);
                })
                .catch((error) => {
                    Logger.Error(error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading loading={true} />;
    } else {
        return (
            <AuthenticatedTemplate>
                <Layout hasUserContext={true}>
                    <Routes>
                        <Route index path="/" element={<Home />} />
                        <Route path="/products/*">
                            <Route index element={<ProductList />} />
                            <Route
                                path={":id/clientapps/:appId/addPermission"}
                                element={<ClientAppForm mode={ClientAppFormMode.AddPermissions} />}
                            />
                            <Route
                                path={":id/clientapps/create"}
                                element={<ClientAppForm mode={ClientAppFormMode.Create} />}
                            />
                        </Route>
                        <Route
                            path="/productownershiprequest"
                            element={<ProductRequestOwnership />}
                        />
                        <Route
                            path="/productownershiprequestslist"
                            element={
                                <ProductOwnershipRequestsList />
                            }
                        />
                        <Route
                            path="/exportstakeholders"
                            element={
                                <ExportStakeholders />
                            }
                        />
                        <Route
                            path="/enableclientsofcompany"
                            element={
                                <ClientEnablerDisabler type="enabler" />
                            }
                        />
                        <Route
                            path="/disableclientsofcompany"
                            element={
                                <ClientEnablerDisabler type="disabler" />
                            }
                        />
                        <Route
                            path="/clientSecret/:clientSecretName"
                            element={
                                <ClientSecret authenticated={true} />
                            }
                        />
                        <Route
                            path="/compliancewarningsstatisticspanel"
                            element={
                                <ComplianceWarningsStatisticsPanel />
                            }
                        />
                        <Route
                            path="/compliancewarningexceptionrequests"
                            element={
                                <ComplianceWarningsExceptionRequests />
                            }
                        />

                        <Route path="/approveproductsubscription" element={
                            <EditProductSubscriptionForm
                                touched={true}
                                setTouched={(_) => { }}
                                visible={true}
                                onDismiss={() => { }}
                                onSaved={() => { }}
                                selectedProductListEntry={undefined}
                                selectedSubscriptionListEntry={undefined}
                            />}
                        />
                    </Routes>
                </Layout>
            </AuthenticatedTemplate>
        );
    }
};
