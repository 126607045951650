import React from 'react';
import { NavHeader } from './NavHeader';
import { NavSide } from './NavSide';

interface LayoutProps {
  children: JSX.Element;
  hasUserContext: boolean;
}

export const Layout = (props: LayoutProps) => {
  return (
    <div className="ms-Grid" dir="ltr">
      <div
        className="ms-Grid-row"
        style={{
          position: 'fixed',
          top: '0',
          width: '100%',
          zIndex: 100
        }}
      >
        <div
          className="ms-Grid-col ms-sm12"
          style={{
            paddingLeft: '0px',
            paddingRight: '0px'
          }}
        >
          <NavHeader hasUserContext={props.hasUserContext} />
        </div>
      </div>
      <div
        className="ms-Grid-row"
        style={{
          position: 'relative',
          top: '49px'
        }}
      >
        {props.hasUserContext && (
          <div
            className="ms-Grid-col ms-xxxl2 ms-xxl2 ms-xl2 ms-lg12 ms-md12 ms-sm12"
            style={{
              paddingLeft: '0px',
              paddingRight: '0px'
            }}
          >
            <NavSide />
          </div>
        )}
        <div
          className="ms-Grid-col ms-xxxl10 ms-xxl10 ms-xl10 ms-lg12 ms-md12 ms-sm12"
          style={{
            paddingTop: '10px',
            paddingLeft: '10px',
            paddingRight: '0px'
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};
