import React from "react";
import { IProductListEntry } from "./models/IProductListEntry";
import GraphClient from "./clients/GraphClient";
import BackendClient from "./clients/BackendClient";
import { IComplianceWarning } from "models/IComplianceWarning";
import { ICompany } from "models/ICompany";
export interface IAppContext {
    graphClient: GraphClient;
    backendClient: BackendClient;
    apimEnvironment: string;
    products: IProductListEntry[];
    complianceWarningsForProducts: Map<string, IComplianceWarning[]>;
    isLoggedInUserPOPAdmin: boolean;
    isLoggedInUserComplianceManager: boolean;
    isLoggedInSecurityAdmin: boolean;
    loadingProducts: boolean;
    loadingComplianceWarnings: boolean;
    companies: ICompany[];
    companyResyncInProgress: boolean;
    setCompanyResyncInProgress: (newCompanyResyncInProgress: boolean) => void;
    setApimEnvironment: (APIMEnvironment: string) => void;
    setProducts: (products: IProductListEntry[]) => void;
    setComplianceWarningsForAllProducts: (
        warningsMap: Map<string, Array<IComplianceWarning>>
    ) => void;
    setCompanies: (companies: ICompany[]) => void;
    setLoadingProducts: (newLoadingProducts: boolean) => void;
    setLoadingComplianceWarnings: (newLoadingComplianceWarnings: boolean) => void;
    setIsLoggedInUserPOPAdmin: (isAdmin: boolean) => void;
    setIsLoggedInUserComplianceManager: (isComplianceManager: boolean) => void;
    setIsLoggedInSecurityAdmin(isSecurityAdmin: boolean): void;
}

export const context = React.createContext<IAppContext | null>(null);

export const AppContextProvider = context.Provider;

export const AppContextConsumer = context.Consumer;

//https://medium.com/@thehappybug/using-react-context-in-a-typescript-app-c4ef7504c858
