import {
  buildColumns,
  CommandBar,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  IDropdownOption,
  IDropdownStyles,
  Label,
  MarqueeSelection,
  Panel,
  PanelType,
  SearchBox,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack
} from "@fluentui/react";
import React, { useState, useEffect, useMemo } from "react";
import ReactPaginate from "react-paginate";
import { context } from "AppContext";
import { IProductListEntry } from "models/IProductListEntry";
import { copyAndSort, trimLower } from "helpers/HelperFunctions";
import { IComplianceWarning } from "models/IComplianceWarning";
import {
  ComplianceWarningScope,
  ComplianceWarningSeverity,
  ComplianceWarningState,
  ComplianceWarningBlocking
} from "models/ComplianceWarningEnums";
import { ComplianceWarningsExceptionsPanel } from "./ComplianceWarningsExceptionsPanel";
import { PAGE_SIZE } from "helpers/const";
import { onRenderWithBlockingWarning } from "components/Core/DetailsListUtils";

interface IComplianceWarningsPanelProps {
  visible: boolean;
  onDismiss: () => void;
  selectedProductListEntry: IProductListEntry | undefined;
}

export const ComplianceWarningsPanel = (
  props: IComplianceWarningsPanelProps
) => {
  const ctx = React.useContext(context)!;
  const [columns, setColumns] = useState([] as IColumn[]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentComplianceWarnings, setCurrentComplianceWarnings] = useState<
    IComplianceWarning[]
  >([]);
  const [filteredComplianceWarnings, setFilteredComplianceWarnings] = useState<
    IComplianceWarning[]
  >([]);
  const [filteredComplianceWarningsPage, setFilteredComplianceWarningsPage] =
    useState<IComplianceWarning[]>([]);
  const [selectedComplianceWarning, setSelectedComplianceWarning] =
    useState<IComplianceWarning>();

  const complianceWarningSelection: Selection = new Selection({
    onSelectionChanged: () => {
      setSelectedComplianceWarning(
        complianceWarningSelection.getSelection()[0] as any
      );
    }
  }) as any;
  const [searchText, setSearchText] = useState("");
  const [filteredScope, setFilteredScope] = useState<
    ComplianceWarningScope | undefined
  >();
  const [filteredSeverity, setFilteredSeverity] = useState<
    ComplianceWarningSeverity | undefined
  >();
  const [filteredState, setFilteredState] = useState<ComplianceWarningState>(
    ComplianceWarningState.OPEN
  );
  const [filteredBlocking, setFilteredBlocking] = useState<
  ComplianceWarningBlocking | undefined
>();
  const [
    complianceWarningExceptionPanelVisible,
    setComplianceWarningExceptionPanelVisible
  ] = useState<boolean>(false);
  const [
    isSelectComplianceWarningDialogVisible,
    setIsSelectComplianceWarningDialogVisible
  ] = useState<boolean>(false);

  const complianceWarningsForProduct = useMemo(() => {
    if (props.selectedProductListEntry) {
      return ctx.complianceWarningsForProducts.get(
        props.selectedProductListEntry.id
      );
    }
    return undefined;
  }, [ctx.complianceWarningsForProducts, props.selectedProductListEntry]);

  useEffect(() => {
    setCurrentComplianceWarnings(complianceWarningsForProduct ?? []);
  }, [complianceWarningsForProduct]);

  // apply paging based on current page and pagesize
  useEffect(() => {
    const offset = currentPage * PAGE_SIZE;
    setFilteredComplianceWarningsPage(
      filteredComplianceWarnings.slice(offset, offset + PAGE_SIZE)
    );
  }, [currentPage, filteredComplianceWarnings]);

  const filterDropdownStylesEmpty: Partial<IDropdownStyles> = {
    dropdown: { width: 200 }
  };
  const filterDropdownStylesFiltered: Partial<IDropdownStyles> = {
    dropdown: { width: 200 },
    title: { backgroundColor: "#edebe9" }
  };

  // search and filtering
  useEffect(() => {
    if (currentComplianceWarnings) {
      const newfilteredProductApis = currentComplianceWarnings
        .slice()
        .filter((complianceWarning) => {
          if (
            trimLower(complianceWarning.message).indexOf(
              trimLower(searchText)
            ) === -1 ||
            (filteredSeverity &&
              filteredSeverity !== complianceWarning.severity) ||
            (filteredScope && filteredScope !== complianceWarning.scope) ||
            (filteredState && filteredState !== complianceWarning.state) ||
            (filteredBlocking && filteredBlocking !== (complianceWarning.blocking === true ? ComplianceWarningBlocking.BLOCKING : ComplianceWarningBlocking.NOTBLOCKING))
          ) {
            return false;
          }
          return true;
        });
      setFilteredComplianceWarnings(newfilteredProductApis);
      setCurrentPage(0);
      complianceWarningSelection.setAllSelected(false);
      setSelectedComplianceWarning(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchText,
    currentComplianceWarnings,
    filteredSeverity,
    filteredScope,
    filteredState,
    filteredBlocking
  ]);

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "exception",
      text: !selectedComplianceWarning
        ? "Request exception"
        : selectedComplianceWarning.severity ===
          ComplianceWarningSeverity.INFORMATION
        ? "Set exception"
        : "Request exception",
      iconProps: { iconName: "EntitlementPolicy" },
      onClick: () => {
        if (
          !selectedComplianceWarning ||
          selectedComplianceWarning.state !== ComplianceWarningState.OPEN
        ) {
          setIsSelectComplianceWarningDialogVisible(true);
        } else {
          setComplianceWarningExceptionPanelVisible(true);
        }
      }
    }
  ];

  // build DetailsList columns
  useEffect(() => {
    if (props.selectedProductListEntry) {
      let newColumns = buildColumns(
        ctx.complianceWarningsForProducts.get(
          props.selectedProductListEntry.id
        ) ?? []
      );
      newColumns = newColumns.filter(
        (column) =>
          column.key !== "id" &&
          column.key !== "productId" &&
          column.key !== "apiId" &&
          column.key !== "operationId"
      );
      if (filteredState === ComplianceWarningState.OPEN) {
        newColumns = newColumns.filter(
          (column) =>
            column.key !== "exceptionRequestReason" &&
            column.key !== "exceptionRequestorEmail" &&
            column.key !== "exceptionApproverRejectorEmail" &&
            column.key !== "exceptionRequestCreatedDate" &&
            column.key !== "exceptionApprovedRejectedDate"
        );
      } else if (filteredState === ComplianceWarningState.PENDING_EXCEPTION) {
        newColumns = newColumns.filter(
          (column) =>
            column.key !== "solution" &&
            column.key !== "exceptionApproverRejectorEmail" &&
            column.key !== "exceptionApprovedRejectedDate"
        );
      } else if (filteredState === ComplianceWarningState.REJECTED_EXCEPTION) {
        newColumns = newColumns.filter((column) => column.key !== "solution");
      } else if (filteredState === ComplianceWarningState.APPROVED_EXCEPTION) {
        newColumns = newColumns.filter((column) => column.key !== "solution");
      }
      for (const column of newColumns) {
        column.isResizable = true;
        switch (column.key) {
          case "scope":
            column.name = "Scope";
            column.minWidth = 60;
            column.className = "2";
            break;
          case "apiDisplayName":
            column.name = "API";
            column.minWidth = 80;
            break;
          case "apiVersion":
            column.name = "Version";
            column.minWidth = 40;
            break;
          case "operationDisplayName":
            column.name = "Operation";
            column.minWidth = 80;
            break;
          case "severity":
            column.name = "Severity";
            column.minWidth = 50;
            column.maxWidth = 250;
            column.className = "1";
            column.isSorted = true;
            break;
          case "message":
            column.name = "Message";
            column.minWidth = 150;
            break;
          case "solution":
            column.name = "Solution";
            column.minWidth = 350;
            break;
          case "state":
            column.name = "State";
            column.className = "3";
            column.minWidth = 60;
            break;
          case "exceptionRequestReason":
            column.name = "Exception Reason";
            column.maxWidth = 120;
            break;
          case "exceptionRequestorEmail":
            column.name = "Exception Requestor";
            column.maxWidth = 100;
            break;
          case "exceptionRequestCreatedDate":
            column.name = "Exception Requested";
            column.maxWidth = 120;
            break;
          case "exceptionApproverRejectorEmail":
            if (filteredState === ComplianceWarningState.APPROVED_EXCEPTION) {
              column.name = "Exception Approver";
            } else {
              column.name = "Exception Rejector";
            }
            break;
          case "exceptionApprovedRejectedDate":
            if (filteredState === ComplianceWarningState.APPROVED_EXCEPTION) {
              column.name = "Approval Date";
            } else {
              column.name = "Rejection Date";
            }
            break;
          case "blocking":
              column.name = "Blocking";
              column.maxWidth = 100;
            break;
        }
      }
      newColumns.sort(
        (a, b) => +(a.className ?? 1000) - +(b.className ?? 1000)
      );
      setColumns(newColumns);
    }
  }, [
    columns.length,
    ctx.complianceWarningsForProducts,
    props.selectedProductListEntry,
    filteredState
  ]);

  const onColumnClick = (
    ev: React.MouseEvent<HTMLElement> | undefined,
    column: IColumn | undefined
  ): void => {
    if (column && filteredComplianceWarnings) {
      const newColumns: IColumn[] = [...columns];
      const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key
      )[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
      const newlySortedProductApis = copyAndSort(
        filteredComplianceWarnings,
        currColumn.fieldName!,
        currColumn.isSortedDescending
      );
      setColumns(newColumns);
      setFilteredComplianceWarnings(newlySortedProductApis);
      complianceWarningSelection.setAllSelected(false);
      setSelectedComplianceWarning(undefined);
    }
  };

  const onPageChanged = (page: { selected: number }) => {
    setCurrentPage(page.selected);
  };

  const renderItemColumn = (
    item: IComplianceWarning,
    index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (column) {
      const fieldContent = item[
        column.fieldName as keyof IComplianceWarning
      ] as string;
      switch (column.key) {
        case "scope":
          return <span>{ComplianceWarningScope[+fieldContent]}</span>;
        case "severity":
          return (
            <Stack horizontal>
            {item.blocking 
            ? 
            <><IconButton
                  key={`ComplianceBlockingButton-${item.id}`}
                  iconProps={{ iconName: "Blocked" }}
                  style={{ color: "#fc0303" }}
                  styles={{ icon: { fontSize: 25 } }}
                  ariaLabel={`ComplianceError`}
                  onClick={() => { } } 
                  title="This warning will block the deployment to Test."
              />
              <span
                style={{ color: "black", position: "relative", paddingTop: "2.1%", paddingRight: "2%" }}
              >
                BLOCKING
              </span>
              </>
              
              :
              null}
              <IconButton
                key={`ComplianceWarningButton-${item.id}`}
                iconProps={{
                  iconName:
                    item.severity === ComplianceWarningSeverity.CRITICAL
                      ? "AlertSolid"
                      : item.severity === ComplianceWarningSeverity.WARNING
                      ? "Warning"
                      : "Info"
                }}
                style={{
                  color:
                    item.severity === ComplianceWarningSeverity.CRITICAL
                      ? "#fc0303"
                      : item.severity === ComplianceWarningSeverity.WARNING
                      ? "#fc7f03"
                      : "rgb(0, 51, 102"
                }}
                styles={{ icon: { fontSize: 25 } }}
                ariaLabel={`Compliance`}
                onClick={() => {}}
              />
              <span
                style={{ color: "black", position: "relative", paddingTop: "2.1%" }}
              >
                {ComplianceWarningSeverity[+fieldContent]}
              </span>
            </Stack>
          );
        case "state":
          return <span>{ComplianceWarningState[+fieldContent]}</span>;
        case "message":
          column.isMultiline = true;
          return <span>{fieldContent}</span>;
        case "exceptionRequestReason":
          column.isMultiline = true;
          return <span>{fieldContent}</span>;
        case "solution":
          column.isMultiline = true;
          return <span>{fieldContent}</span>;
        case "blocking":
          column.isMultiline = true;
          return <span>{fieldContent.toString() === "true" ? "Yes" : "No"}</span>;
        default:
          return <span>{fieldContent}</span>;
      }
    }
    return <span></span>;
  };

  return (
    <div>
      <Panel
        headerText={`${
          props.selectedProductListEntry?.displayName ?? ""
        } - Compliance Warnings`}
        isOpen={props.visible}
        onDismiss={() => {
          setFilteredScope(undefined);
          setFilteredSeverity(undefined);
          setFilteredBlocking(undefined);
          setSelectedComplianceWarning(undefined);
          setFilteredState(ComplianceWarningState.OPEN);
          setSearchText("");
          props.onDismiss();
        }}
        closeButtonAriaLabel="Close"
        type={PanelType.extraLarge}
        layerProps={{ styles: { root: { zIndex: 998 } } }}
        onOuterClick={() => {
          /* ignore */
        }}
      >
        <div>
        <Label style={{ fontSize: "20px" }}>
            Environment:{" "}
            <span
                style={{
                    color: `${ctx.apimEnvironment === "DEV"
                            ? "#1FFF5A"
                            : ctx.apimEnvironment === "TEST"
                                ? "orange"
                                : "#95233A"
                        }`
                }}
            >
                {ctx.apimEnvironment}
            </span>
        </Label>
          <Stack
            horizontal
            tokens={{
              childrenGap: 10
            }}
          >
            <SearchBox
              styles={{ root: { width: 300 } }}
              placeholder="Search"
              value={searchText ?? ""}
              onEscape={() => {
                setSearchText("");
                setCurrentPage(0);
              }}
              onClear={() => {
                setSearchText("");
                setCurrentPage(0);
              }}
              onChange={(_, newSearchText) => {
                setSearchText(newSearchText ?? "");
                setCurrentPage(0);
              }}
              onSearch={(newSearchText) => {
                setSearchText(newSearchText ?? "");
                setCurrentPage(0);
              }}
            />
            <Dropdown
              placeholder="Filter Scope"
              selectedKey={filteredScope}
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                item: IDropdownOption | undefined
              ): void => {
                if (item) {
                  setFilteredScope(item.key as number);
                }
              }}
              options={[
                { key: 0, text: "All" },
                { key: ComplianceWarningScope.PRODUCT, text: "Product" },
                { key: ComplianceWarningScope.API, text: "API" },
                {
                  key: ComplianceWarningScope.API_OPERATION,
                  text: "API Operation"
                }
              ]}
              styles={
                filteredScope !== undefined
                  ? filterDropdownStylesFiltered
                  : filterDropdownStylesEmpty
              }
            />
            <Dropdown
              placeholder="Filter Severity"
              selectedKey={filteredSeverity}
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                item: IDropdownOption | undefined
              ): void => {
                if (item) {
                  setFilteredSeverity(item.key as number);
                }
              }}
              options={[
                { key: 0, text: "All" },
                { key: ComplianceWarningSeverity.CRITICAL, text: "Critical" },
                { key: ComplianceWarningSeverity.WARNING, text: "Warning" },
                {
                  key: ComplianceWarningSeverity.INFORMATION,
                  text: "Information"
                }
              ]}
              styles={
                filteredSeverity !== undefined
                  ? filterDropdownStylesFiltered
                  : filterDropdownStylesEmpty
              }
            />
            <Label>Filter State:</Label>
            <Dropdown
              placeholder="Filter State"
              selectedKey={filteredSeverity}
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                item: IDropdownOption | undefined
              ): void => {
                if (item) {
                  setFilteredState(item.key as number);
                }
              }}
              options={[
                {
                  key: ComplianceWarningState.OPEN,
                  text: "Open",
                  selected: true
                },
                {
                  key: ComplianceWarningState.PENDING_EXCEPTION,
                  text: "Pending Exception"
                },
                {
                  key: ComplianceWarningState.REJECTED_EXCEPTION,
                  text: "Rejected Exception"
                },
                {
                  key: ComplianceWarningState.APPROVED_EXCEPTION,
                  text: "Approved Exception"
                }
              ]}
              styles={
                filteredState !== undefined
                  ? filterDropdownStylesFiltered
                  : filterDropdownStylesEmpty
              }
            />
             <Label>Filter Blocking:</Label>
             <Dropdown
              placeholder="Filter Blocking"
              selectedKey={filteredBlocking}
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                item: IDropdownOption | undefined
              ): void => {
                if (item) {
                  setFilteredBlocking(item.key as number);
                }
              }}
              options={[
                {
                  key: 0,
                  text: "All",
                  selected: true
                },
                {
                  key: ComplianceWarningBlocking.BLOCKING,
                  text: "Blocking",
                  selected: true
                },
                {
                  key: ComplianceWarningBlocking.NOTBLOCKING,
                  text: "Not Blocking",
                  selected: true
                },
              ]}
              styles={
                filteredBlocking !== undefined
                  ? filterDropdownStylesFiltered
                  : filterDropdownStylesEmpty
              }
            />
          </Stack>
          <Label
            style={{
              marginTop: 15,
              backgroundColor: "#ffeecc",
              width: "100%",
              paddingLeft: 10,
              paddingRight: 10
            }}
          >
            <span style={{ fontWeight: 700, color: "red" }}>IMPORTANT:</span>{" "}
            Compliance Warnings are evaluated every 2 hours. That means you
                      might see updates with some delay! You are currently viewing compliance warnings on the <strong>{ctx.apimEnvironment}</strong> environment.
          </Label>
          <CommandBar
            items={commandBarItems}
            styles={{ root: { marginTop: 10, padding: 0 } }}
          />
          <div>
            <MarqueeSelection selection={complianceWarningSelection}>
              <ShimmeredDetailsList
                setKey="items"
                items={filteredComplianceWarningsPage}
                onRenderItemColumn={renderItemColumn}
                onRenderRow={onRenderWithBlockingWarning}
                columns={columns}
                selectionMode={SelectionMode.single}
                ariaLabelForShimmer="Loading compliance warnings"
                ariaLabelForGrid="Compliance Warnings"
                listProps={{
                  renderedWindowsAhead: 0,
                  renderedWindowsBehind: 0
                }}
                onColumnHeaderClick={onColumnClick}
                selection={complianceWarningSelection}
                selectionPreservedOnEmptyClick={true}
              />
            </MarqueeSelection>
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <ReactPaginate
                previousLabel={"← Previous"}
                nextLabel={"Next →"}
                pageCount={Math.ceil(Math.max(filteredComplianceWarnings.length, 1) / PAGE_SIZE)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                containerClassName={"paginationContainer"}
                previousClassName={currentPage < 1 ? "disabledPagination" : ""}
                nextClassName={
                  currentPage ===
                  Math.ceil(Math.max(filteredComplianceWarnings.length, 1) / PAGE_SIZE) - 1
                    ? "disabledPagination"
                    : ""
                }
                activeClassName={"active"}
                breakLabel={"..."}
                onPageChange={onPageChanged}
                forcePage={currentPage}
              />
            </div>
          </div>
          {(!currentComplianceWarnings ||
            currentComplianceWarnings.length < 1) && (
            <div>
              <h5>{`No compliance warnings found.`}</h5>
            </div>
          )}
          <div style={{ textAlign: "center", marginTop: 10 }}>
            Total: {currentComplianceWarnings?.length ?? 0} compliance
            warning(s)
          </div>
        </div>
      </Panel>
      <Dialog
        hidden={!isSelectComplianceWarningDialogVisible}
        onDismiss={() => setIsSelectComplianceWarningDialogVisible(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title:
            "Please select a compliance warning with OPEN state that you want to request an exception for."
        }}
        modalProps={{
          isBlocking: true,
          styles: { main: { maxWidth: 450 } }
        }}
      >
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              setIsSelectComplianceWarningDialogVisible(false);
            }}
            text="Close"
          />
        </DialogFooter>
      </Dialog>
      <ComplianceWarningsExceptionsPanel
        visible={complianceWarningExceptionPanelVisible}
        selectedComplianceWarning={selectedComplianceWarning}
        onDismiss={() => {
          setComplianceWarningExceptionPanelVisible(false);
        }}
        onSuccess={(complianceWarning) => {
          let newCurrentComplianceWarnings = [...currentComplianceWarnings];
          const indexToUpdate = newCurrentComplianceWarnings.findIndex(
            (cw) => cw.id === complianceWarning.id
          );
          newCurrentComplianceWarnings[indexToUpdate] = complianceWarning;
          setCurrentComplianceWarnings(newCurrentComplianceWarnings);
          setComplianceWarningExceptionPanelVisible(false);
        }}
      />
    </div>
  );
};
