import React, { useState } from "react";
import { context } from "AppContext";
import Logger from "Logger";
import { TextField, Stack, IconButton, IStackTokens, DefaultButton, PrimaryButton } from "@fluentui/react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Loading } from "components/Core/Loading";

export const ExportStakeholders = () => {
  const ctx = React.useContext(context)!;
  const [currentStakeholders, setCurrentStakeholders] = useState('');
  const [copied, setCopied] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const stackTokens: IStackTokens = {
      childrenGap: 4
  };

  const loadAllStakeholders = () => {
      setLoading(true);
      let allStakeholders: string[] = [];
      ctx.backendClient.getExportProductOwners().then((productOwners) => {
          allStakeholders = allStakeholders.concat(productOwners);
          ctx.backendClient.getExportApiDeployers().then((apiDeployers) => {
              allStakeholders = allStakeholders.concat(apiDeployers);
              ctx.backendClient.getExportCustomStakeholders().then((customStakeholders) => {
                  allStakeholders = allStakeholders.concat(customStakeholders);
                  const allUniqueStakeholders = new Set<string>(allStakeholders);
                  setCurrentStakeholders(Array.from(allUniqueStakeholders).join(';'));
                  }).catch((error) => Logger.Error(error));
              }).catch((error) => Logger.Error(error));
          }).catch((error) => Logger.Error(error))
          .finally(() => {
              setCopied(false);
              setLoading(false);
          });
  };

  return (
    <div>
          <h3>Export API Product Stakeholders</h3>
            <Loading loading={loading || false} />
          <Stack horizontal tokens={stackTokens} style={{ marginTop: 25 }}>
              <PrimaryButton
                  text="Export all"
                  onClick={() => { loadAllStakeholders(); }}
                  allowDisabledFocus
                  styles={{ root: { width: 230 } }} />
              <DefaultButton
                  text="Export Product Owners"
                  onClick={() => {
                      setLoading(true);
                      ctx.backendClient.getExportProductOwners().then((productOwners) => {
                              setCurrentStakeholders(productOwners.join(';'));
                          }).catch((error) => Logger.Error(error))
                          .finally(() => {
                              setCopied(false);
                              setLoading(false);
                          });
                  }}
                  allowDisabledFocus
                  styles={{ root: { width: 230 } }} />
              <DefaultButton
                  text="Export Developers"
                  onClick={() => {
                      setLoading(true);
                      ctx.backendClient.getExportApiDeployers().then((apiDeployers) => {
                          setCurrentStakeholders(apiDeployers.join(';'));
                      }).catch((error) => Logger.Error(error))
                          .finally(() => {
                              setCopied(false);
                              setLoading(false);
                          });
                  }}
                  allowDisabledFocus
                  styles={{ root: { width: 230 } }} />
              <DefaultButton
                  text="Export Custom Stakeholders"
                  onClick={() => {
                      setLoading(true);
                      ctx.backendClient.getExportCustomStakeholders().then((customStakeholders) => {
                          setCurrentStakeholders(customStakeholders.join(';'));
                      }).catch((error) => Logger.Error(error))
                          .finally(() => {
                              setCopied(false);
                              setLoading(false);
                          });
                  }}
                  allowDisabledFocus
                  styles={{ root: { width: 230 } }} />
          </Stack>
        <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-lg3 ms-md8 ms-sm12">
                <Stack tokens={stackTokens} style={{marginTop:15}}>
                    <TextField
                        label="List of selected stakeholders"
                        value={currentStakeholders ?? ''}
                        onChange={(e) => {
                            setCurrentStakeholders((e.target as HTMLInputElement).value ?? undefined);
                            setCopied(false);
                        }
                        }
                        multiline
                        autoAdjustHeight
                    />
                    <CopyToClipboard
                        text={currentStakeholders}
                        onCopy={() => {
                setCopied(true);
              }}
                    >
            {
                  <div>
                      <span>Copy to clipboard</span>
                  {(copied && <span>  - Copied!</span>) || (
                    <IconButton
                      id="CopyToClipBoardButton"
                      iconProps={{ iconName: 'Copy' }}
                      title="Copy to Clipboard"
                      ariaLabel="Copy to Clipboard"
                      onClick={() => {}}
                                      />
                                  )}
                </div>
              }
                      </CopyToClipboard>
                </Stack>
            </div>
        </div>
    </div>
  );
};
