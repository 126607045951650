import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IStackTokens,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField
} from "@fluentui/react";
import React, { useState } from "react";
import { context } from "AppContext";
import { IComplianceWarning } from "models/IComplianceWarning";
import { ComplianceWarningSeverity } from "models/ComplianceWarningEnums";
import Logger from "Logger";
import { Loading } from "components/Core/Loading";

interface IComplianceWarningsPanelProps {
  visible: boolean;
  onDismiss: () => void;
  onSuccess: (complianceWarning: IComplianceWarning) => void;
  selectedComplianceWarning: IComplianceWarning | undefined;
}

export const ComplianceWarningsExceptionsPanel = (
  props: IComplianceWarningsPanelProps
) => {
  const ctx = React.useContext(context)!;
  const [loading, setLoading] = useState<boolean>(false);
  const [currentExceptionReason, setCurrentExceptionREason] =
    useState<string>();
  const [
    isExceptionConfirmationDialogVisible,
    setIsExceptionConfirmationDialogVisible
  ] = useState<boolean>(false);

  const stackTokens: IStackTokens = {
    childrenGap: 4
  };

  const requestExceptionForSelectedComplianceWarning = () => {
    if (props.selectedComplianceWarning) {
      setLoading(true);
      ctx.backendClient
        .requestComplianceWarningException(
          props.selectedComplianceWarning?.productId,
          {
            id: props.selectedComplianceWarning.id,
            exceptionRequestReason: currentExceptionReason ?? ""
          }
        )
        .then((res) => {
          if (
            props.selectedComplianceWarning?.severity ===
            ComplianceWarningSeverity.INFORMATION
          ) {
            Logger.Success("Exception has been set successfully.");
          } else {
            Logger.Success(
              "Exception has been requested successfully and is now pending on approval"
            );
          }
          setCurrentExceptionREason(undefined);
          props.onSuccess(res);
        })
        .catch((error) => Logger.Error(error))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Panel
        headerText={`Exception for compliance warning`}
        isOpen={props.visible}
        onDismiss={() => {
          props.onDismiss();
          setCurrentExceptionREason(undefined);
        }}
        closeButtonAriaLabel="Close"
        type={PanelType.medium}
        layerProps={{ styles: { root: { zIndex: 998 } } }}
        onOuterClick={() => {
          /* ignore */
        }}
      >
        <Loading loading={loading || false} />
        {props.selectedComplianceWarning && (
          <div>
            <Label style={{}}>
              <span style={{ fontWeight: 700 }}>
                Product
                <br />
              </span>
              <span>{props.selectedComplianceWarning?.productId}</span>
            </Label>
            {props.selectedComplianceWarning.apiDisplayName && (
              <Label style={{}}>
                <span style={{ fontWeight: 700 }}>
                  Productive API
                  <br />
                </span>
                <span>{props.selectedComplianceWarning?.apiDisplayName}</span>
              </Label>
            )}
            {props.selectedComplianceWarning.operationDisplayName && (
              <Label style={{}}>
                <span style={{ fontWeight: 700 }}>
                  API Operation
                  <br />
                </span>
                <span>
                  {props.selectedComplianceWarning?.operationDisplayName}
                </span>
              </Label>
            )}
            <Label style={{}}>
              <span style={{ fontWeight: 700 }}>
                Severity
                <br />
              </span>
              <span>
                {
                  ComplianceWarningSeverity[
                    props.selectedComplianceWarning!.severity
                  ]
                }
              </span>
            </Label>
            <Label style={{}}>
              <span style={{ fontWeight: 700 }}>
                Message
                <br />{" "}
              </span>
              <span>{props.selectedComplianceWarning?.message}</span>
            </Label>
            <Label style={{}}>
              <span style={{ fontWeight: 700 }}>
                Proposed Solution
                <br />
              </span>
              <span>{props.selectedComplianceWarning?.solution}</span>
            </Label>
            {props.selectedComplianceWarning &&
              props.selectedComplianceWarning.severity !==
                ComplianceWarningSeverity.INFORMATION && (
                <Label
                  style={{
                    marginTop: 15,
                    backgroundColor: "#ffeecc",
                    width: "100%",
                    paddingLeft: 10,
                    paddingRight: 10
                  }}
                >
                  <span style={{ fontWeight: 700, color: "red" }}>
                    IMPORTANT:{" "}
                  </span>
                  Compliance warnings exceptions with Severity "WARNING" or
                  "CRITICAL" have to be evaluated and approved by the HIP team
                  and security. You will be informed as soon as your exception
                  request is approved or rejected.
                </Label>
              )}
            <TextField
              styles={{ root: { marginTop: 15 } }}
              label="Detailed reason for exception"
              value={currentExceptionReason ?? ""}
              onChange={(e) =>
                setCurrentExceptionREason(
                  (e.target as HTMLInputElement).value ?? undefined
                )
              }
              multiline
              autoAdjustHeight
              required
              onGetErrorMessage={() => {
                if (
                  !currentExceptionReason ||
                  currentExceptionReason.trim() === ""
                ) {
                  return "Please enter a descriptive reason for the compliance exception.";
                }
              }}
            />
            <Stack horizontal tokens={stackTokens} style={{ marginTop: 15 }}>
              <PrimaryButton
                text={
                  props.selectedComplianceWarning.severity ===
                  ComplianceWarningSeverity.INFORMATION
                    ? "Set exception"
                    : "Request exception"
                }
                onClick={() => {
                  setIsExceptionConfirmationDialogVisible(true);
                }}
                allowDisabledFocus
                styles={{ root: { width: 230 } }}
                disabled={
                  !currentExceptionReason ||
                  currentExceptionReason.trim() === ""
                }
              />
              <DefaultButton
                text="Cancel"
                onClick={() => {
                  props.onDismiss();
                  setCurrentExceptionREason(undefined);
                }}
                allowDisabledFocus
                styles={{ root: { width: 230 } }}
              />
            </Stack>
          </div>
        )}
      </Panel>
      <Dialog
        hidden={!isExceptionConfirmationDialogVisible}
        onDismiss={() => setIsExceptionConfirmationDialogVisible(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title: `${
            props.selectedComplianceWarning?.severity ===
            ComplianceWarningSeverity.INFORMATION
              ? "Are you sure you want to set a permanent exception for this compliance warning with the specified reason?"
              : "Are you sure you want to request a permanent exception from security for this compliance warning with the specified reason?"
          }`
        }}
        modalProps={{
          isBlocking: true,
          styles: { main: { maxWidth: 450 } }
        }}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setIsExceptionConfirmationDialogVisible(false);
              requestExceptionForSelectedComplianceWarning();
            }}
            text="Yes"
          />
          <DefaultButton
            onClick={() => {
              setIsExceptionConfirmationDialogVisible(false);
            }}
            text="No"
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
