import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { context } from "AppContext";
import {
    Nav,
    INavLink,
    INavStyles,
    INavLinkGroup
} from "@fluentui/react/lib/Nav";

const navStyles: Partial<INavStyles> = {
    root: {
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        overflowY: "auto"
    }
};

const withRouter = (Component: any) => {
    const Wrapper = (props: any) => {
        const history = useNavigate();
        return <Component history={history} {...props} />
    }
    return Wrapper;
}

export const NavSide = withRouter(({ history }: any) => {
    const ctx = React.useContext(context)!;
    const linksWithoutAdmin = useMemo(
        () => [
            {
                name: "Overview",
                url: "/",
                key: "home",
                icon: "Home"
            },
            {
                name: "Product List",
                url: "/products",
                key: "products",
                icon: "Product"
            },
            {
                name: "Statistics Panel",
                url: "/compliancewarningsstatisticspanel",
                key: "compliancewarningsstatisticspanel",
                icon: "ComplianceAudit"
            },
            {
                name: "Request Ownership",
                url: "/productownershiprequest",
                key: "productownershiprequest",
                icon: "AddFriend"
            }
        ],
        []
    );

    const [navLinkGroups, setNavlinkGroups] = useState<INavLinkGroup[]>([
        {
            links: linksWithoutAdmin
        }
    ]);

    useEffect(() => {
        window.addEventListener("popstate", () => {
            setSelectedKey(getSelectedKey());
        });
    });

    useEffect(() => {
        let linkGroupsToAdd = [] as any;
        if (ctx.isLoggedInUserComplianceManager) {
            linkGroupsToAdd.push({
                name: "Compliance",
                url: "#",
                expandAriaLabel: "Expand Compliance section",
                collapseAriaLabel: "Collapse Compliance section",
                links: [
                   
                    {
                        name: "Exception Requests",
                        url: "/compliancewarningexceptionrequests",
                        key: "compliancewarningexceptionrequests",
                        icon: "EntitlementPolicy"
                    }
                ],
                isExpanded: true
            });
        }
        if (ctx.isLoggedInUserPOPAdmin) {
            linkGroupsToAdd.push({
                name: "Administrator",
                url: "#",
                expandAriaLabel: "Expand Administrator section",
                collapseAriaLabel: "Collapse Administrator section",
                links: [
                    {
                        name: "Ownership Requests",
                        url: "/productownershiprequestslist",
                        key: "productownershiprequestslist",
                        icon: "AddFriend"
                    },
                    {
                        name: "Export Stakeholders",
                        url: "/exportstakeholders",
                        key: "exportstakeholders",
                        icon: "PeopleRepeat"
                    }
                ],
                isExpanded: true
            });
        }

        if (ctx.isLoggedInSecurityAdmin) {
            linkGroupsToAdd.push({
                name: "Security Administrator",
                url: "#",
                expandAriaLabel: "Expand Security Administrator section",
                collapseAriaLabel: "Collapse Security Administrator section",
                links: [
                    {
                        name: "Block Access from an external Company",
                        url: "/disableclientsofcompany",
                        key: "disableclientsofcompany",
                        icon: "Stop"
                    },
                    {
                        name: "Re-Enable Access from an external Company",
                        url: "/enableclientsofcompany",
                        key: "enableclientsofcompany",
                        icon: "PlayResume"
                    },
                ]
            })
        }
        setNavlinkGroups((_) => [{ links: linksWithoutAdmin }, ...linkGroupsToAdd]);
    }, [
        ctx.isLoggedInUserPOPAdmin,
        ctx.isLoggedInUserComplianceManager,
        ctx.isLoggedInSecurityAdmin,
        linksWithoutAdmin
    ]);

    const getSelectedKey = () => {
        let result = "home";
        navLinkGroups[0].links.forEach((link) => {
            if (link.url !== "/" && document.location.pathname === link.url) {
                result = link.key!;
            }
        });
        if (navLinkGroups[1] && result === "home") {
            navLinkGroups[1].links.forEach((link) => {
                if (link.url !== "/" && document.location.pathname === link.url) {
                    result = link.key!;
                }
            });
        }
        return result;
    };

    const [selectedKey, setSelectedKey] = useState(getSelectedKey());

    const _onLinkClick = (
        ev?: React.MouseEvent<HTMLElement>,
        item?: INavLink
    ) => {
        ev?.preventDefault();
        setSelectedKey(item?.key!);
        history(item?.url!);
        window.dispatchEvent(new Event("popstate"));
    };
    return (
        <Nav
            onLinkClick={_onLinkClick}
            selectedKey={selectedKey}
            ariaLabel="Side Navigation"
            styles={navStyles}
            groups={navLinkGroups}
        />
    );
});
