type EnumType = { [s: number]: string };

export const mapEnumToDropDownValues = (
  enumerable: EnumType,
  EnumToStringFunction: (value: string) => string
) => {
  const enumMembers: any[] = Object.keys(enumerable).map(
    (key) => enumerable[key as any]
  );

  const dropDownKeys = enumMembers.filter((v) => typeof v === "number");

  const dropDowns = dropDownKeys.map((key) => {
    return { key: key, text: EnumToStringFunction(key.toString()) };
  });
  return dropDowns;
};

export const mapEnumToDropDownValuesWithUndefinedValue = (
  enumerable: EnumType,
  EnumToStringFunction: (value: string) => string
) => {
  var dropDowns = mapEnumToDropDownValues(enumerable, EnumToStringFunction);
  dropDowns.push({ key: 0, text: "Undefined" });
  return dropDowns;
};
