import { IClientAppListEntry } from "models/IClientAppListEntry";
import {
  DetailsRow,
  IColumn,
  IconButton,
  IDetailsListProps,
  IDetailsRowStyles,
  Stack
} from "@fluentui/react";

export const renderItemColumnWithCompanyWarning = (
  item: any,
  index: number | undefined,
  column: IColumn | undefined
) => {
  if (column) {
    const fieldContent = item[
      column.fieldName as keyof IClientAppListEntry
    ] as string;
    switch (column.key) {
      case "company":
        if (fieldContent && fieldContent.length > 0) {
          return <span>{fieldContent}</span>;
        }
        return (
          <Stack horizontal>
            <IconButton
              iconProps={{ iconName: "Warning" }}
              style={{ color: "#fc7f03" }}
              styles={{ icon: { fontSize: 25 } }}
              ariaLabel={`ComplianceWarning`}
            />
            <span
              style={{ color: "#fc7f03", position: "relative", top: "3px" }}
            >
              undefined
            </span>
          </Stack>
        );

      default:
        return <span>{fieldContent}</span>;
    }
  }
  return <span></span>;
};

export const renderItemColumnWithoutCompanyWarning = (
  item: any,
  index: number | undefined,
  column: IColumn | undefined
) => {
  if (column) {
    const fieldContent = item[
      column.fieldName as keyof IClientAppListEntry
    ] as string;
    switch (column.key) {
      case "company":
        if (fieldContent && fieldContent.length > 0) {
          return <span>{fieldContent}</span>;
        }
        return <span>undefined</span>;

      default:
        return <span>{fieldContent}</span>;
    }
  }
  return <span></span>;
};

export const onRenderRowWithCompany: IDetailsListProps["onRenderRow"] = (
  props
) => {
  const customStyles: Partial<IDetailsRowStyles> = {};
  if (props) {
    if (
      !props.item["company"] &&
      (props.item["state"] === "Active" || props.item["enabled"])
    ) {
      customStyles.root = { backgroundColor: "#ffffcc" };
      props.onRenderItemColumn = renderItemColumnWithCompanyWarning;
    }

    return <DetailsRow {...props} styles={customStyles} />;
  }
  return null;
};

export const onRenderWithBlockingWarning: IDetailsListProps["onRenderRow"] = (
  props
) => {
  const customStyles: Partial<IDetailsRowStyles> = {};
  if (props) {
    if(props.item["blocking"])
    {
      customStyles.root = { backgroundColor: "#ffffcc" };
    }
    return <DetailsRow {...props} styles={customStyles} />;
  }
  return null;
};

