import React, { useState, useEffect } from "react";
import { context } from "AppContext";
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { ICompany } from "models/ICompany";
import { IBackendApp } from "models/IBackendApp";
import { FadeLoader } from 'react-spinners';
import { DetailsList, MarqueeSelection, Selection, DetailsListLayoutMode, SelectionMode } from "@fluentui/react";
import Logger from "Logger";
import ReactPaginate from "react-paginate";
import { PAGE_SIZE } from "helpers/const";

interface IListOfClients {
    setStepInProcess: Function;
    chosenCompany: ICompany;
    backendClient:IBackendApp;
    setResponseFromBackend: Function;
    type: string;
  }

  const disabler = {
    text1:"disabled",
    text2:"disable",
    buttonText: "Disable"
  }
  
  const enabler = {
    text1:"enabled",
    text2:"enable",
    buttonText: "Enable"
  }

export const ListOfClients: any = (props: IListOfClients) => 
{
  const ctx = React.useContext(context)!;
  const [showOverlay, setShowOverlay] = useState<Boolean>(false);
  const [affectedClients, setAffectedClients] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingChangeState, setIsLoadingChangeState] = useState(false);

  const [currentShownClientApps, setCurrentShownClientApps] = useState<any>([]);
  const [currentPageNumberClientApps, setCurrentPageNumberClientApps] = useState(0);

  const [currentShownSubscriptions, setCurrentShownSubscriptions] = useState<any>([]);
  const [currentPageNumberSubscriptions, setCurrentPageNumberSubscriptions] = useState(0);

    let texts = props.type === "disabler" ? disabler : enabler;

  useEffect(() => {
    const getAffectedClients = async () => {
      setIsLoading(true);
      let data = await ctx.backendClient.getAllAffectedClients(props.chosenCompany.id);

      if(props.type === "disabler") {
        data.subscriptions = data.subscriptions.filter((subscription:any) => { return (subscription?.company?.id === props.chosenCompany.id && subscription.state === "Active") });
        data.clientApps = data.clientApps.filter((clientApp:any) => { return (clientApp?.company?.id === props.chosenCompany.id && clientApp.enabled === true)});
      } else {
        data.subscriptions = data.subscriptions.filter((subscription:any) => { return (subscription?.company?.id === props.chosenCompany.id && subscription.state === "Suspended")});
        data.clientApps = data.clientApps.filter((clientApp:any) => { return (clientApp?.company?.id === props.chosenCompany.id && clientApp.enabled !== true) });
      }

      setAffectedClients({ ...data });
      setIsLoading(false);
    };
    getAffectedClients()
  }, [ctx.backendClient, props?.chosenCompany.id, props.type]);

  let disableEnableFunction: () => void;

  if(props.type === "disabler") {
    disableEnableFunction = async () => {
      try {
        setIsLoadingChangeState(true);
        const data = await ctx.backendClient.disableAllClientsByCompany(props.chosenCompany.id);
        props.setResponseFromBackend(data);
        setIsLoadingChangeState(false);
        props.setStepInProcess(2);
      } catch(e:any) {
        Logger.Error(e);
      }
    };
  } else {
    disableEnableFunction = async () => {
      try {
        setIsLoadingChangeState(true);
        const data = await ctx.backendClient.enableAllClientsByCompany(props.chosenCompany.id);
        props.setResponseFromBackend(data);
        setIsLoadingChangeState(false);
        props.setStepInProcess(2);
      } catch(e:any) {
        Logger.Error(e);
      }
    };
  }

  let selection = new Selection({
    onSelectionChanged: () => { return },
  });

  const onPageChangedClientApps = (page: { selected: number }) => {
    setCurrentPageNumberClientApps(page.selected);
  };

  const onPageChangedSubscriptions = (page: { selected: number }) => {
    setCurrentPageNumberSubscriptions(page.selected);
  };

  useEffect(() => {
    const offset = currentPageNumberClientApps * PAGE_SIZE;
    setCurrentShownClientApps(affectedClients?.clientApps?.slice(offset, offset + PAGE_SIZE));
  }, [currentPageNumberClientApps, affectedClients?.clientApps]);

  useEffect(() => {
    const offset = currentPageNumberSubscriptions * PAGE_SIZE;
    setCurrentShownSubscriptions(affectedClients?.subscriptions?.slice(offset, offset + PAGE_SIZE));
  }, [currentPageNumberSubscriptions, affectedClients?.subscriptions]);

  return (
      <div>
          <div>
            Company Info:
            <ul>
                <li>Name: {props.chosenCompany.name}</li>
                <li>idm_uid: {props.chosenCompany.id}</li>
            </ul>
          </div>
          {isLoading ? 
            <div style={{padding: 30, paddingLeft: 250}}> 
              <FadeLoader color={'#123abc'} loading={true} /> 
            </div>
            : 
            <div>
              The following <b>OAuth2-Clients</b> will be {texts.text1}:
              { currentShownClientApps !== undefined 
              ?
              <><div style={{ width: '60%' }}>
              <MarqueeSelection selection={selection}>
                <DetailsList
                  items={!isLoading ? currentShownClientApps : new Array<any>()}
                  columns={[
                    { key: 'column1', name: 'OAuth2 Client Name', fieldName: 'displayName', minWidth: 150, maxWidth: 150, isResizable: true },
                    { key: 'column2', name: 'OAuth2 Client Owner', fieldName: 'originalRequestor', minWidth: 75, maxWidth: 75, isResizable: true },
                  ]}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionPreservedOnEmptyClick={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="select row"
                  selectionMode={SelectionMode.none} />
              </MarqueeSelection>
            </div><div>
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  pageCount={Math.ceil(Math.max(affectedClients?.clientApps.length, 1) / PAGE_SIZE)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  containerClassName={"paginationContainer"}
                  previousClassName={currentPageNumberClientApps < 1 ? "disabledPagination" : ""}
                  nextClassName={currentPageNumberClientApps ===
                    Math.ceil(Math.max(affectedClients?.clientApps.length, 1) / PAGE_SIZE) - 1
                    ? "disabledPagination"
                    : ""}
                  activeClassName={"active"}
                  breakLabel={"..."}
                  onPageChange={onPageChangedClientApps}
                  forcePage={currentPageNumberClientApps} />
              </div></>
              :
              null
            }
            <br />
            The following <b>Subscriptions</b> will be {texts.text1}:
            { currentShownSubscriptions !== undefined 
            ?
            <><div style={{ width: '60%' }}>
              <MarqueeSelection selection={selection}>
                <DetailsList
                  items={!isLoading ? currentShownSubscriptions : new Array<any>()}
                  columns={[
                    { key: 'column1', name: 'Subscription Name', fieldName: 'displayName', minWidth: 150, maxWidth: 150, isResizable: true },
                    { key: 'column2', name: 'Subscription Owner', fieldName: 'email', minWidth: 75, maxWidth: 75, isResizable: true },
                  ]}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionPreservedOnEmptyClick={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="select row"
                  selectionMode={SelectionMode.none}
                  onItemInvoked={(item) => { return; } } />
              </MarqueeSelection>
            </div><div>
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  pageCount={Math.ceil(Math.max(affectedClients?.subscriptions?.length, 1) / PAGE_SIZE)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  containerClassName={"paginationContainer"}
                  previousClassName={currentPageNumberSubscriptions < 1 ? "disabledPagination" : ""}
                  nextClassName={currentPageNumberSubscriptions ===
                    Math.ceil(Math.max(affectedClients?.subscriptions?.length, 1) / PAGE_SIZE) - 1
                    ? "disabledPagination"
                    : ""}
                  activeClassName={"active"}
                  breakLabel={"..."}
                  onPageChange={onPageChangedSubscriptions}
                  forcePage={currentPageNumberSubscriptions} />
              </div></> 
              :
              null
              }
          </div>
          }
          <br />
          <div style={{display: "inline-block"}}>
            {
              isLoading || (affectedClients?.subscriptions?.length === 0 && affectedClients?.clientApps.length === 0) 
              ?
              <div>
                <PrimaryButton onClick={() => { setShowOverlay(true) }} text={texts.buttonText} style={{marginRight: 10, backgroundColor: "grey"}} disabled={true} />
                <DefaultButton onClick={() => { props.setStepInProcess(0) }} text="Abort" />
              </div>
              :
              <div>
                <PrimaryButton onClick={() => { setShowOverlay(true) }} text={texts.buttonText} style={{marginRight: 10, backgroundColor: "red"}} />
                <DefaultButton onClick={() => { props.setStepInProcess(0) }} text="Abort" />
              </div>
            }
          </div>
          
      {showOverlay === true ? 
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      }}>
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            minHeight: "100vh"
            }}>
            {
              isLoadingChangeState ? 
                <div style={{padding: 30, paddingLeft: 250}}> 
                  <FadeLoader color={'#123abc'} loading={true} /> 
                </div> 
                : 
                <div>
                  <p style={{fontWeight:"bold", color:"white"}}>This action will {texts.text2} the shown OAuth2-Clients and subscriptions. Are you sure you want to continue?</p>
                  <PrimaryButton onClick={() => { disableEnableFunction() } } text="Yes I'm sure" style={{marginRight: 10, backgroundColor: "red"}} />
                  <DefaultButton onClick={() => { setShowOverlay(false) }} text="Abort" />
              </div>
            }
      </div>
      </div>:
      null}
    </div> 
    );
}