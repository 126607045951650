import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { PAGE_SIZE } from 'helpers/const';
import ReactPaginate from 'react-paginate';

interface IListOfClientsss {
    setStepInProcess: Function;
    setCompany: Function;
    responseFromBackend: any;
    type: string;
}

const transformResponse = (objectList:any) => {
    return objectList.map((item: any ) => {
        let obj = {id: item.id, success: item.success, additionalInformation: "-", details: "-"}
        if(!item.success) {
            obj.additionalInformation = item?.additionalInformation?.message;
            obj.details = item?.additionalInformation?.details;
        }
        return obj;
    });
}

const disabler = {
    text1:"disabled",
  }
  
  const enabler = {
    text1:"enabled",
  }

export const Outcome = (props: IListOfClientsss) => {

    const [currentShownClientApps, setCurrentShownClientApps] = useState<any>([]);
    const [currentPageNumberClientApps, setCurrentPageNumberClientApps] = useState(0);
  
    const [currentShownSubscriptions, setCurrentShownSubscriptions] = useState<any>([]);
    const [currentPageNumberSubscriptions, setCurrentPageNumberSubscriptions] = useState(0);

    
    let texts = props.type === "disabler" ? disabler : enabler;
    let clientAppsOutcome = transformResponse(props.responseFromBackend?.clientApps);
    let SubscriptionsOutcome = transformResponse(props.responseFromBackend?.subscriptions);

    const _columns = [
        { key: 'column1', name: 'Id', fieldName: 'id', minWidth: 150, maxWidth: 150, isResizable: true },
        { key: 'column2', name: 'Success', fieldName: 'success', minWidth: 75, maxWidth: 75, isResizable: true },
        { key: 'column3', name: 'Additional Information', fieldName: 'additionalInformation', minWidth: 500, maxWidth: 500, isResizable: true },
        { key: 'column4', name: 'Details', fieldName: 'details', minWidth: 500, maxWidth: 500, isResizable: true },
      ];

      let selection = new Selection({
        onSelectionChanged: () => { return }
      });
    
      const onPageChangedClientApps = (page: { selected: number }) => {
        setCurrentPageNumberClientApps(page.selected);
      };
    
      const onPageChangedSubscriptions = (page: { selected: number }) => {
        setCurrentPageNumberSubscriptions(page.selected);
      };
    
      useEffect(() => {
        const offset = currentPageNumberClientApps * PAGE_SIZE;
        setCurrentShownClientApps(clientAppsOutcome?.slice(offset, offset + PAGE_SIZE));
      }, [currentPageNumberClientApps, clientAppsOutcome]);
    
      useEffect(() => {
        const offset = currentPageNumberSubscriptions * PAGE_SIZE;
        setCurrentShownSubscriptions(SubscriptionsOutcome?.slice(offset, offset + PAGE_SIZE));
      }, [currentPageNumberSubscriptions, SubscriptionsOutcome]);

    return (
        <div>
            The following <b>OAuth2 clients</b> were {texts.text1}
            <MarqueeSelection selection={selection}>
                <DetailsList
                    items={currentShownClientApps}
                    columns={_columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionPreservedOnEmptyClick={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                    selectionMode={SelectionMode.none}
                />
            </MarqueeSelection><div>
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  pageCount={Math.ceil(Math.max(clientAppsOutcome?.length, 1) / PAGE_SIZE)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  containerClassName={"paginationContainer"}
                  previousClassName={currentPageNumberClientApps < 1 ? "disabledPagination" : ""}
                  nextClassName={currentPageNumberClientApps ===
                    Math.ceil(Math.max(clientAppsOutcome?.length, 1) / PAGE_SIZE) - 1
                    ? "disabledPagination"
                    : ""}
                  activeClassName={"active"}
                  breakLabel={"..."}
                  onPageChange={onPageChangedClientApps}
                  forcePage={currentPageNumberClientApps} />
              </div>     
            < br/>
            The following <b>Subscriptions</b> were {texts.text1}
        
            <MarqueeSelection selection={selection}>
                <DetailsList
                    items={currentShownSubscriptions}
                    columns={_columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionPreservedOnEmptyClick={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                    selectionMode={SelectionMode.none}
                />
            </MarqueeSelection><div>    
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  pageCount={Math.ceil(Math.max(SubscriptionsOutcome?.length, 1) / PAGE_SIZE)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  containerClassName={"paginationContainer"}
                  previousClassName={currentPageNumberSubscriptions < 1 ? "disabledPagination" : ""}
                  nextClassName={currentPageNumberSubscriptions ===
                    Math.ceil(Math.max(SubscriptionsOutcome?.length, 1) / PAGE_SIZE) - 1
                    ? "disabledPagination"
                    : ""}
                  activeClassName={"active"}
                  breakLabel={"..."}
                  onPageChange={onPageChangedSubscriptions}
                  forcePage={currentPageNumberSubscriptions} />
              </div>
            <br />
            <PrimaryButton onClick={() => {props.setStepInProcess(0); props.setCompany(undefined);}} text='Restart Process' />
        </div>
    )
}