export interface IComplianceWarningStatistics {
    productId: string;
    productName: string;

    devInfoWarningsCount: number;
    devWarningWarningsCount: number;
    devCriticalWarningsCount: number;
    testInfoWarningsCount: number;
    testWarningWarningsCount: number;
    testCriticalWarningsCount: number;
    prodInfoWarningsCount: number;
    prodWarningWarningsCount: number;
    prodCriticalWarningsCount: number;
    createDate: Date;
}
export class ComplianceWarningStatistics implements IComplianceWarningStatistics {
    productId="";
    productName="";

    devInfoWarningsCount=0;
    devWarningWarningsCount = 0;
    devCriticalWarningsCount = 0;
    testInfoWarningsCount = 0;
    testWarningWarningsCount = 0;
    testCriticalWarningsCount = 0;
    prodInfoWarningsCount = 0;
    prodWarningWarningsCount = 0;
    prodCriticalWarningsCount = 0;
    createDate= new Date();
}



