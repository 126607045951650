import React, { useState, useEffect, useCallback } from "react";
import {
  CommandBar,
  ICommandBarItemProps
} from "@fluentui/react/lib/CommandBar";
import { Persona, PersonaSize } from "@fluentui/react/lib/Persona";
import { context } from "AppContext";
import Logger from "Logger";
import { IButtonStyles, Image } from "@fluentui/react";
import {
  Dropdown,
  IDropdownOption,
  IDropdownStyles
} from "@fluentui/react/lib/Dropdown";
import { useMsal } from "@azure/msal-react";

interface INavHeaderProps {
  hasUserContext: boolean;
}

export const NavHeader = (props: INavHeaderProps) => {
  const ctx = React.useContext(context)!;
  const { instance } = useMsal();
  const [userInformation, setUserInformation] = useState({
    givenName: "",
    surname: "",
    displayName: "",
    department: ""
  });
  const [userPhoto, setUserPhoto] = useState("");
  const [selectedItem, setSelectedItem] = useState(ctx.apimEnvironment);

  const dropdownEnvironmentStyles: Partial<IDropdownStyles> = {
    dropdown: {
      width: 150,
      backgroundColor: "rgba(0,0,0,0)",
      color: `${
        ctx.apimEnvironment === "DEV"
          ? "#1FFF5A"
          : ctx.apimEnvironment === "TEST"
          ? "orange"
          : "#95233A"
      }`
    },
    root: { backgroundColor: "rgba(0,0,0,0)" }
  };

  const navHeaderButtonStyles: IButtonStyles = {
    root: {
      backgroundColor: "rgba(0,0,0,0)"
    },
    rootHovered: { backgroundColor: "rgba(0,0,0,0)" },
    rootExpanded: { backgroundColor: "rgba(0,0,0,0)" },
    rootPressed: { backgroundColor: "rgba(0,0,0,0)" },
    rootExpandedHovered: { backgroundColor: "rgba(0,0,0,0)" },
    label: { color: "#fff", fontWeight: 400, fontSize: "18px" }
  };

  const dropdownEnvironmentOptions: IDropdownOption[] = [
    { key: "DEV", text: "DEV" },
    { key: "TEST", text: "TEST" },
    { key: "PROD", text: "PROD" }
  ] as any;

  const onEnvironmentChange = (
    event: React.FormEvent<HTMLDivElement>,
    option: IDropdownOption | undefined
  ): void => {
    setSelectedItem(option!.key.toString());
    ctx.setApimEnvironment(option!.key!.toString());
  };

  const leftItems: ICommandBarItemProps[] = [
    {
      key: "omvlogo",
      text: "",
      onRenderChildren: () => {
        return <Image src="/header_logo.png" width="145px" />;
      },
      buttonStyles: {
        root: { backgroundColor: "rgba(0,0,0,0)" },
        rootHovered: { backgroundColor: "rgba(0,0,0,0)", color: "#fff" }
      }
    },
    {
      key: "home",
      text: "API Product Owner Portal",
      href: "/",
      buttonStyles: {
        root: { fontSize: 22, backgroundColor: "rgba(0,0,0,0)", color: "#fff" },
        rootHovered: {
          textDecoration: "none",
          backgroundColor: "rgba(0,0,0,0)",
          color: "#fff"
        }
      }
    },
    {
      onRenderMenuIcon: () => null,
      buttonStyles: navHeaderButtonStyles,
      onRenderChildren: () => {
        return (
          <Dropdown
            selectedKey={selectedItem ? selectedItem : "DEV"}
            // eslint-disable-next-line react/jsx-no-bind

            onChange={onEnvironmentChange}
            placeholder="APIM instance"
            options={dropdownEnvironmentOptions}
            styles={dropdownEnvironmentStyles}
          />
        );
      },
      key: "environment",
      name: "Environment"
    }
  ];

  const getFarItems = () => {
    if (!props.hasUserContext) {
      return [];
    }
    const farItems: ICommandBarItemProps[] = [
      {
        onRenderMenuIcon: () => null,
        buttonStyles: navHeaderButtonStyles,
        onRenderChildren: () => {
          return (
            <Persona
              size={PersonaSize.size40}
              imageUrl={`data:image/jpeg;base64,${userPhoto}`}
              imageInitials={
                userInformation?.givenName?.substring(0, 1) +
                userInformation?.surname?.substring(0, 1)
              }
              text={userInformation?.displayName}
              secondaryText={userInformation?.department}
              styles={{
                primaryText: { color: "#fff" },
                secondaryText: { color: "#fff" }
              }}
            />
          );
        },
        key: "persona",
        name: "Persona",
        iconOnly: true,
        subMenuProps: {
          items: [
            {
              key: "logout",
              name: "Logout",
              iconProps: {
                iconName: "UserRemove"
              },
              onClick: () => {
                instance.logoutRedirect();
              }
            }
          ]
        }
      }
    ];
    return farItems;
  };

  const loadUserProfilePhoto = useCallback(() => {
    if (!props.hasUserContext || (userPhoto && userInformation)) return;
    ctx.graphClient
      .getUserInformation()
      .then((res) => setUserInformation(res))
      .catch((err) => Logger.Error(err));

    ctx.graphClient
      .getUserPhoto("120x120")
      .then((res) => {
        if (res === "") {
          ctx.graphClient.getUserPhoto("64x64").then((res) => {
            if (res) setUserPhoto(res);
          });
        } else {
          if (res) setUserPhoto(res);
        }
      })
      .catch((err) => Logger.Error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hasUserContext]);

  useEffect(() => {
    loadUserProfilePhoto();
  }, [loadUserProfilePhoto]);

  return (
    <div style={{ borderBottom: "solid 1px #ccc" }}>
      <CommandBar
        items={leftItems}
        farItems={getFarItems()}
        styles={{
          root: {
            paddingLeft: 0,
            backgroundColor: "#052759",
            boxShadow: "0 3px 3px 0 rgba(0,0,0,.35)",
            position: "relative",
            borderBottom: ".3125rem solid #1FFF5A",
            zIndex: "302",
            height: "49px"
          }
        }}
      />
    </div>
  );
};
