import React, { useContext } from 'react';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import {
  IBasePickerSuggestionsProps,
  NormalPeoplePicker,
  ValidationState
} from '@fluentui/react/lib/Pickers';
import { Label } from '@fluentui/react';
import { context } from 'AppContext';
import Logger from 'Logger';
import { IPerson } from "models/IPerson";

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested People',
  mostRecentlyUsedHeaderText: 'Suggested Contacts',
  noResultsFoundText: 'No results found',
  loadingText: 'Loading',
  showRemoveButtons: false,
  suggestionsAvailableAlertText: 'People Picker Suggestions available',
  suggestionsContainerAriaLabel: 'Suggested contacts'
};

interface IAADPeoplePickerProps {
  disabled?: boolean;
  pickerWidth?: number;
  onchange?: (items?: IPersonaProps[]) => void;
}

export const AADPeoplePicker = (props: IAADPeoplePickerProps) => {
  const ctx = useContext(context)!;

  const picker = React.useRef(null);

  const onFilterChanged = async (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined
  ): Promise<IPersonaProps[]> => {
    if (filterText) {
      try {
        let searchedUsersOnAAD = await ctx.backendClient.searchUsers(
          filterText.trim()
        );
        let searchedUsersOnAADAsPersonas = searchedUsersOnAAD.map(
          (p: IPerson) =>
            ({
              text:
                p.lastname && p.firstName
                  ? `${p.lastname}, ${p.firstName}`
                  : p.upn,
              secondaryText: p.lastname && p.firstName ? p.upn : undefined,
              tertiaryText: `${p.firstName}|${p.lastname}|${p.objectId}`
            } as IPersonaProps)
        );

        searchedUsersOnAADAsPersonas =
          removeDuplicates(searchedUsersOnAADAsPersonas, currentPersonas) ?? [];
        return searchedUsersOnAADAsPersonas;
      } catch (error) {
        Logger.Error(error);
        return [];
      }
    } else {
      return [];
    }
  };

  return (
    <div>
      <Label>Add new Owner(s)</Label>
      <NormalPeoplePicker
        onResolveSuggestions={onFilterChanged}
        getTextFromItem={(persona: IPersonaProps) => persona?.text ?? ''}
        pickerSuggestionsProps={suggestionProps}
        className={'ms-PeoplePicker'}
        key={'normal'}
        onValidateInput={() => ValidationState.invalid}
        removeButtonAriaLabel={'Remove'}
        componentRef={picker}
        onInputChange={(input) => input}
        resolveDelay={300}
        styles={{ root: { width: props.pickerWidth ?? '100%' } }}
        onChange={props.onchange}
      />
    </div>
  );
};

function removeDuplicates(
  personas: IPersonaProps[] | undefined,
  possibleDupes: IPersonaProps[] | undefined
) {
  return personas?.filter(
    (persona) =>
      !possibleDupes?.some(
        (p) =>
          p.secondaryText &&
          p.secondaryText?.trim().toLowerCase() ===
            persona.secondaryText?.trim().toLowerCase()
      )
  );
}
