import {
  Panel,
  PanelType,
  PrimaryButton,
  DefaultButton,
  Dialog,
  DialogType,
  DialogFooter
} from "@fluentui/react";
import { useState } from "react";
import { ISubscriptionListEntry } from "models/ISubscriptionListEntry";
import { IProductListEntry } from "models/IProductListEntry";
import { EditProductSubscriptionForm } from "./EditProductSubscriptionForm";

interface IEditProductSubscriptionPanelProps {
  visible: boolean;
  onDismiss: () => void;
  onSaved: (savedProductSubscription: ISubscriptionListEntry) => void;
  selectedSubscriptionListEntry: ISubscriptionListEntry | undefined;
  selectedProductListEntry: IProductListEntry | undefined;
}

export const EditProductSubscriptionPanel = (
  props: IEditProductSubscriptionPanelProps
) => {
  const [
    isConfirmationCloseDialogVisible,
    setIsConfirmationCloseDialogVisible
  ] = useState(false);
  const [
    isEditProductSubscriptionFormTouched,
    setIsEditProductSubscriptionFormTouched
  ] = useState<boolean>(false);

  return (
    <div>
      <Panel
        headerText={`Edit subscription ${
          props.selectedSubscriptionListEntry?.displayName ?? ""
        }`}
        isOpen={props.visible}
        onDismiss={() => {
          if (!isEditProductSubscriptionFormTouched) {
            props.onDismiss();
          } else {
            setIsConfirmationCloseDialogVisible(true);
          }
        }}
        closeButtonAriaLabel="Close"
        type={PanelType.medium}
        layerProps={{ styles: { root: { zIndex: 999 } } }}
        onOuterClick={() => {
          /* ignore */
        }}
      >
        <EditProductSubscriptionForm
          visible={props.visible}
          onDismiss={() => {
            if (isEditProductSubscriptionFormTouched) {
              setIsConfirmationCloseDialogVisible(true);
            } else {
              props.onDismiss();
            }
          }}
          touched={isEditProductSubscriptionFormTouched}
          setTouched={(touched) => {
            setIsEditProductSubscriptionFormTouched(touched);
          }}
          onSaved={(savedProductSubscription) => {
            props.onSaved(savedProductSubscription);
            setIsEditProductSubscriptionFormTouched(false);
            props.onDismiss();
          }}
          selectedSubscriptionListEntry={props.selectedSubscriptionListEntry}
          selectedProductListEntry={props.selectedProductListEntry}
        />
      </Panel>
      <Dialog
        hidden={!isConfirmationCloseDialogVisible}
        onDismiss={() => setIsConfirmationCloseDialogVisible(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title:
            "Are you sure you want to close the subscription editor? All unsaved changes will be lost!"
        }}
        modalProps={{
          isBlocking: true,
          styles: { main: { maxWidth: 450 } }
        }}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setIsConfirmationCloseDialogVisible(false);
              setIsEditProductSubscriptionFormTouched(false);
              props.onDismiss();
            }}
            text="Yes"
          />
          <DefaultButton
            onClick={() => {
              setIsConfirmationCloseDialogVisible(false);
            }}
            text="No"
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
