import { DefaultButton, Label } from "@fluentui/react";
import React from "react";
import { context } from "AppContext";
import Logger from "Logger";

export const CompanyReSync = () => {
  const ctx = React.useContext(context)!;

  return (
    (!ctx.companyResyncInProgress && (
      <DefaultButton
        text="Re-Sync"
        styles={{
          root: { marginLeft: 20, width: 95, position: "relative", top: 3 }
        }}
        onClick={() => {
          Logger.Success(
            `Companies are being resynced from ServiceNow, you will be informed when the sync is complete...`
          );
            ctx.backendClient
                .startCompaniesSync();
            ctx.setCompanyResyncInProgress(true);
        }}
      />
    )) || (
      <Label
        style={{
          paddingLeft: 10,
          color: "green",
          position: "relative",
          top: "-6px"
        }}
      >
        <span style={{ fontWeight: 70 }}>Re-Sync in progress...</span>
      </Label>
    )
  );
};
