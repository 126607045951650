export enum ComplianceWarningScope {
  PRODUCT = 1,
  API = 2,
  API_OPERATION = 3
}

export enum ComplianceWarningSeverity {
  CRITICAL = 1,
  WARNING = 2,
  INFORMATION = 3
}

export enum ComplianceWarningState {
  OPEN = 1,
  PENDING_EXCEPTION = 2,
  REJECTED_EXCEPTION = 4,
  APPROVED_EXCEPTION = 8
}

export enum ComplianceWarningBlocking {
  BLOCKING = 1,
  NOTBLOCKING = 2
}
