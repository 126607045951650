export enum ProductCategory {
  NORMAL = 1,
  IMPORTANT = 2,
  BUSINESS_CRITICAL = 4
}

export enum ProductConfidentiality {
  PUBLIC = 1,
  CONFIDENTIAL = 2,
  STRICTLY_CONFIDENTIAL = 4
}

export enum ProductType {
  INTERNAL = 1,
  PARTNER = 2,
  PUBLIC = 4
}

export enum ProductBackendHosting {
  ONPREM = 1,
  INTERNET = 2
}

export enum ProductBackendRouting {
  CONFIGURATION = 1,
  POLICY = 2
}

export enum ProductAuthorization {
  SUBSCRIPTION_KEY = 1,
  OAUTH = 2,
  CERTIFICATE = 4,
  BASIC = 8
}

export const ProductCategoryToString = (value: string) => {
  const categoryInt = +value;
  const result: string[] = [];
  if (categoryInt & ProductCategory.NORMAL) {
    result.push("Normal");
  }
  if (categoryInt & ProductCategory.IMPORTANT) {
    result.push("Important");
  }
  if (categoryInt & ProductCategory.BUSINESS_CRITICAL) {
    result.push("Business Critical");
  }
  return result.join(", ");
};

export const ProductConfidentialityToString = (value: string) => {
  const confidentialityInt = +value;
  const result: string[] = [];
  if (confidentialityInt & ProductConfidentiality.PUBLIC) {
    result.push("Public");
  }
  if (confidentialityInt & ProductConfidentiality.CONFIDENTIAL) {
    result.push("Confidential");
  }
  if (confidentialityInt & ProductConfidentiality.STRICTLY_CONFIDENTIAL) {
    result.push("Strictly Confidential");
  }
  return result.join(", ");
};

export const ProductTypeToString = (value: string) => {
  const categoryInt = +value;
  const result: string[] = [];
  if (categoryInt & ProductType.INTERNAL) {
    result.push("Internal");
  }
  if (categoryInt & ProductType.PARTNER) {
    result.push("Partner");
  }
  if (categoryInt & ProductType.PUBLIC) {
    result.push("Public");
  }
  return result.join(", ");
};

export const ProductBackendHostingToString = (value: string) => {
  const categoryInt = +value;
  const result: string[] = [];
  if (categoryInt & ProductBackendHosting.INTERNET) {
    result.push("Internet");
  }
  if (categoryInt & ProductBackendHosting.ONPREM) {
    result.push("Onprem");
  }
  return result.join(", ");
};

export const ProductBackendRoutingToString = (value: string) => {
  const categoryInt = +value;
  const result: string[] = [];
  if (categoryInt & ProductBackendRouting.CONFIGURATION) {
    result.push("Configuration");
  }
  if (categoryInt & ProductBackendRouting.POLICY) {
    result.push("Policy");
  }
  return result.join(", ");
};

export const ProductAuthorizationToString = (value: string) => {
  const categoryInt = +value;
  const result: string[] = [];
  if (categoryInt & ProductAuthorization.CERTIFICATE) {
    result.push("Certificate");
  }
  if (categoryInt & ProductAuthorization.OAUTH) {
    result.push("OAuth");
  }
  if (categoryInt & ProductAuthorization.SUBSCRIPTION_KEY) {
    result.push("Subscription Key");
    }
  if (categoryInt & ProductAuthorization.BASIC) {
      result.push("Basic");
  }
  return result.join(", ");
};
