import {
  TagPicker,
  IBasePicker,
  ITag,
  PrimaryButton,
  IBasePickerSuggestionsProps
} from '@fluentui/react';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import React, { useState, useEffect, useContext } from 'react';
import { context } from 'AppContext';
import { Loading } from 'components/Core/Loading';
import Logger from 'Logger';

export const ProductRequestOwnership = () => {
  const ctx = useContext(context)!;

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ITag>();
  const [allProductsWithoutOwners, setAllProductsWithoutOwners] =
    useState<ITag[]>();

  useEffect(() => {
    setLoading(true);
    ctx.backendClient
      .getProductsWithoutOwners(ctx.apimEnvironment)
      .then((productsWithoutOwners) => {
        const allProductsWithoutOwnersAsTags: ITag[] = [];
        productsWithoutOwners.forEach((pwo) => {
          allProductsWithoutOwnersAsTags.push({
            key: pwo.name,
            name: pwo.displayName
          });
        });
        setAllProductsWithoutOwners(allProductsWithoutOwnersAsTags);
      })
      .catch((error) => {
        Logger.Error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [ctx.apimEnvironment, ctx.backendClient]);

  const rootClass = mergeStyles({
    maxWidth: 350
  });

  const filterSelectedProducts = (
    filterText: string): ITag[] => {
    return filterText && allProductsWithoutOwners
      ? allProductsWithoutOwners.filter(
          (product) =>
            product.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1
        )
      : [];
  };
  const picker = React.useRef<IBasePicker<ITag>>(null);

  const listContainsProductList = (tag: ITag, selectedProducts?: ITag[]) => {
    if (
      !selectedProducts ||
      !selectedProducts.length ||
      selectedProducts.length === 0
    ) {
      return false;
    }
    return selectedProducts.some((compareTag) => compareTag.key === tag.key);
  };

  const onProductSelected = React.useCallback((product?: ITag): ITag | null => {
    if (
      !product ||
      (picker.current && listContainsProductList(product, picker.current.items))
    ) {
      setSelectedProduct(undefined);
      return null;
    }
    setSelectedProduct(product);
    return product;
  }, []);

  const getTextFromItem = (item: ITag) => item.name;

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested products without owner',
    noResultsFoundText: 'No products without owner found'
  };

  const createProductOwnershipRequest = (productId: string) => {
    setLoading(true);
    ctx.backendClient
      .createProductOwnershipRequest(productId)
      .then(() => {
        setSelectedProduct(undefined);
        Logger.Success(
          `Product Ownership for product with id ${productId} has been requested. You will get notified via email as soon as the request has been approved/rejected by the HIP team. If you have questions please contact api-support@omv.com`
        );
      })
      .catch(() => {
        Logger.Error(
          `An error happend when trying to create a product ownership request for product with id ${productId}, please try again later. If the issue persists please contact api-support@omv.com`
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={rootClass}>
      <Loading loading={loading || false} />
      <label htmlFor="productPicker">Search product without owner(s)</label>
      <TagPicker
        removeButtonAriaLabel="Remove"
        selectionAriaLabel="Selected colors"
        componentRef={picker}
        onResolveSuggestions={filterSelectedProducts}
        onItemSelected={onProductSelected}
        onChange={(products) => {
          if (!products || products.length < 1) {
            setSelectedProduct(undefined);
          }
        }}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        itemLimit={1}
        selectedItems={selectedProduct ? [selectedProduct] : []}
        inputProps={{
          id: 'productPicker'
        }}
      />
      <PrimaryButton
        text="Request ownership"
        onClick={() =>
          createProductOwnershipRequest(selectedProduct?.key as string)
        }
        allowDisabledFocus
        disabled={!selectedProduct}
        styles={{ root: { marginTop: 20 } }}
      />
    </div>
  );
};
