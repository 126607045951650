import React, { useState, useEffect } from "react";
import { IProduct } from "models/IProduct";
import { context } from "AppContext";
import Logger from "Logger";
import { APIMEnvironment } from "models/APIMEnvironment";
import { Loading } from "components/Core/Loading";
import { InfoLabelButton } from "components/Core/InfoLabelButton";
import { toast } from "react-toastify";
import {
  Panel,
  PanelType,
  TextField,
  ITextFieldProps,
  IconButton,
  IButtonStyles,
  PrimaryButton,
  DefaultButton,
  Dialog,
  DialogType,
  DialogFooter,
  Stack
} from "@fluentui/react";
import {
  IAppRole,
  IBackendApp,
  IOAuth2PermissionScope
} from "models/IBackendApp";
import { AxiosError } from "axios";

interface IProductBackendPanelProps {
  visible: boolean;
  onDismiss: (resultBackendApp: IBackendApp | undefined) => void;
  product: IProduct | undefined;
  env: APIMEnvironment | undefined;
  loading: boolean;
}

export const ProductBackendPanel = (props: IProductBackendPanelProps) => {
  const [currentProduct, setCurrentProduct] = useState<IProduct>();
  const [loading, setLoading] = useState<boolean>();
  const [isExisting, setIsExisting] = useState<boolean>(false);
  const ctx = React.useContext(context)!;
  const [currentBackendApp, setCurrentBackendApp] = useState<IBackendApp>();
  const [DeletingMessage, setDeletingMessage] = useState<JSX.Element[]>([]);
  const [touched, setTouched] = useState<boolean>(false);
  const [
    isCancelConfirmationDialogVisible,
    setIsCancelConfirmationDialogVisible
  ] = useState<boolean>(false);
  const [confirmRemovalOfPermission, setConfirmRemovalOfPermission] =
    useState<any>();

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    if (props.product) {
      setCurrentProduct({ ...props.product });
    }
  }, [props.product]);

  useEffect(() => {
    switch (props.env) {
      case APIMEnvironment.DEV:
        if (
          !props.product?.backendAppIdForDevApim ||
          props.product?.backendAppIdForDevApim === ""
        ) {
          setIsExisting(false);
        } else {
          setIsExisting(true);
        }
        break;
      case APIMEnvironment.TEST:
        if (
          !props.product?.backendAppIdForTestApim ||
          props.product?.backendAppIdForTestApim === ""
        ) {
          setIsExisting(false);
        } else {
          setIsExisting(true);
        }
        break;
      case APIMEnvironment.PROD:
        if (
          !props.product?.backendAppIdForProdApim ||
          props.product?.backendAppIdForProdApim === ""
        ) {
          setIsExisting(false);
        } else {
          setIsExisting(true);
        }
        break;
      default:
        setIsExisting(false);
    }
  }, [props.env, props.product]);

  const onDismiss = (resultBackendApp: IBackendApp | undefined) => {
    setCurrentBackendApp(undefined);
    setTouched(false);
    setIsExisting(false);
    props.onDismiss(resultBackendApp);
  };

  const getDisplayNamePrefix = () => {
    return `apim-backend-${APIMEnvironment[props.env ?? 0]
      ?.toString()
      .toLowerCase()}-`;
  };

  useEffect(() => {
    if (props.product?.id && props.env !== undefined && isExisting) {
      setLoading(true);
      ctx.backendClient
        .getBackendApp(props.product?.id, APIMEnvironment[props.env])
        .then((res) => {
          res.displayName = res.displayName.replaceAll(
            getDisplayNamePrefix(),
            ""
          );
          setCurrentBackendApp(res as any);
        })
        .catch((err) => {
          setCurrentBackendApp(undefined);
          Logger.Error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx, props.product?.id, props.env, isExisting]);

  const onRenderLabelInfoButton = (
    textFieldProps: ITextFieldProps | undefined
  ) => <InfoLabelButton id="test" {...textFieldProps} />;

  const getPermissionNameErrorMessage = (
    list: string[],
    index: number = -1
  ) => {
    if (index === -1) {
      list.forEach((permission) => {
        if (!permission || permission.trim() === "") {
          return "Name is required";
        } else if (/[&#:<>/\s]/.test(permission)) {
          return "The name must not contain a whitespace or any of the characters in (&#:<>/)";
        }
        return "";
      });
    } else if (!list[index] || list[index].trim() === "") {
      return "Name is required";
    } else if (/[&#:<>/\s]/.test(list[index])) {
      return "The name must not contain a whitespace or any of the characters in (&#:<>/)";
    }
    return "";
  };

  const getErrorMessage = (fieldName: string) => {
    if (loading || !touched) {
      return "";
    }
    let message = "";
    const field = fieldName;
    switch (fieldName) {
      case "all":
        if (
          (!currentBackendApp?.appRoles ||
            currentBackendApp?.appRoles.length === 0) &&
          (!currentBackendApp?.scopes || currentBackendApp?.scopes.length === 0)
        ) {
          message = "Please add at least one App role or User scope";
        }
      // falls through
      case "displayName":
        if (
          !currentBackendApp?.displayName ||
          currentBackendApp.displayName.trim() === ""
        ) {
          message = "Please add a descriptive display name for the backend";
          break;
        } else if (/[&#:<>/]/.test(currentBackendApp.displayName)) {
          message =
            "Backend display name contains illegal character(s) - (&#:<>/)";
          break;
        }
        if (field !== "all") {
          break;
        }
      // falls through
      case "identifierUri":
        if (
          !currentBackendApp?.identifierUri ||
          currentBackendApp.identifierUri.trim() === ""
        ) {
          message =
            "Please add a unique scope (identifier URI) for the backend";
          break;
        } else if (
          /[&#<>\s]/.test(currentBackendApp.identifierUri) &&
          !isExisting
        ) {
          message =
            "Scope contains illegal character(s) - whitespace or one of (&#<>)";
          break;
        } else if (
          !/^[a-zA-Z]+:\/\/.+/.test(currentBackendApp.identifierUri) &&
          !isExisting
        ) {
          message =
            "Backend scope must start with a protocol identifier (e.g. https://<apiname>-<env>.omv.com or api://<apiname>-<env>)";
          break;
        }

        if (field !== "all") {
          break;
        }
      // falls through
      case "permissionName":
        if (
          !currentBackendApp?.displayName ||
          currentBackendApp.displayName.trim() === ""
        ) {
          message = "Please specify a name";
          break;
        }
        if (field !== "all") {
          break;
        }
    }
    return message;
  };

  const AddButtonStyles: IButtonStyles = {
    root: {
      display: "inline-block",
      float: "right",
      position: "relative",
      top: "28px"
    }
  };

  const createExpandableStringArrayControls = (
    permissionList: IAppRole[] | IOAuth2PermissionScope[],
    updateToState: (
      newPermissionList: IAppRole[] | IOAuth2PermissionScope[]
    ) => void,
    controlBaseKey: string,
    controlLabel: string
  ) => {
    const controls: JSX.Element[] = [];
    permissionList.forEach((_, index: number) => {
      controls.push(
        <div key={`${controlBaseKey}Stack${index}`}>
          <TextField
            key={`${controlBaseKey}Textfield${index}`}
            label={`${controlLabel} Name`}
            value={permissionList[index].name}
            placeholder={
              controlBaseKey === "AppRole"
                ? "e.g.: Data1.ReadWrite"
                : "e.g.: UserData1.ReadWrite"
            }
            onChange={(e) => {
              const newPermissions = [...permissionList];
              newPermissions[index] = {
                id: newPermissions[index].id,
                name: (e.target as HTMLInputElement).value,
                description: (e.target as HTMLInputElement).value,
                type: newPermissions[index].type
              };
              updateToState(newPermissions);
              setTouched(true);
            }}
            required={true}
            disabled={false}
            styles={{ root: { width: "82%", display: "inline-block" } }}
            summary={`The name of the ${controlLabel} (can be used in API Management policy to check the ${controlLabel} of a client)`}
            onRenderLabel={onRenderLabelInfoButton}
            onGetErrorMessage={() =>
              getPermissionNameErrorMessage(
                permissionList.map((permission) => permission.name),
                index
              )
            }
          />
          <IconButton
            key={`${controlBaseKey}RemoveButton${index}`}
            iconProps={{ iconName: "Remove" }}
            ariaLabel={`Remove ${controlBaseKey}`}
            styles={AddButtonStyles}
            disabled={false}
            onClick={async () => {
                await SetMessageForDeleting(permissionList[index].id, permissionList[index].type);
                if (isExisting) {
                  setConfirmRemovalOfPermission((prevState: any) => ({
                    ...prevState!,
                    list: permissionList,
                    listIndex: index,
                    updateList: updateToState
                  }));
                } else {
                  const newPermissions = [...permissionList];
                  newPermissions.splice(index, 1);
                  updateToState(newPermissions);
                  setTouched(true);
                }
            }}
          />
        </div>
      );
    });
    return controls;
  };

  const saveBackend = () => {
    setLoading(true);
    if (
      !isExisting &&
      props.product &&
      props.env !== undefined &&
      currentBackendApp
    ) {
      ctx.backendClient
        .createBackendApp(props.product?.id, APIMEnvironment[props.env], {
          ...currentBackendApp,
          displayName: getDisplayNamePrefix() + currentBackendApp.displayName,
          scopes: currentBackendApp.scopes ?? [],
          appRoles: currentBackendApp.appRoles ?? []
        })
        .then((createdBackendApp) => {
          Logger.Success(
            `Backend ${createdBackendApp.displayName} successfully created.`
          );
          onDismiss(createdBackendApp as any);
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 504) {
            // Intermediate network error, returning 504 with timeout of 20s. The backend app is created successfully by the POP backend.
            Logger.Success(
              `The creation of the backend takes more time than usually. Please check if the client app has been created after a few minutes. If not, please contact api-support@omv.com.`
            );
          } else {
            Logger.Error(err);
          }
        })
        .finally(() => setLoading(false));
    } else if (props.product && props.env !== undefined && currentBackendApp) {
      ctx.backendClient
        .updateBackendApp(props.product?.id, APIMEnvironment[props.env], {
          ...currentBackendApp,
          displayName: getDisplayNamePrefix() + currentBackendApp.displayName,
          scopes: currentBackendApp.scopes ?? [],
          appRoles: currentBackendApp.appRoles ?? []
        })
        .then((updatedBackendApp) => {
          Logger.Success(`Backend successfully updated.`);
          onDismiss(updatedBackendApp as any);
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 504) {
            // Intermediate network error, returning 504 with timeout of 20s. The backend app is created successfully by the POP backend.
            Logger.Success(
              `The update of the backend takes more time than usually. Please check if the client app has been created after a few minutes. If not, please contact api-support@omv.com.`
            );
          } else {
            Logger.Error(err);
          }
        })
        .finally(() => setLoading(false));
      }
    };

  const SetMessageForDeleting = async (id: string|undefined, type:string) => {
    const control: JSX.Element[] = [];

    if (type === "AppRole") {
        control.push(
          <div>
            <p> Are you sure you want to remove the App Role from the backend?</p>
            <p>If there are already existing clients that have this App Role assigned, the permission will be removed and the clients might not have access anymore.</p>
          </div>);
    } else {
      setLoading(true);
      await ctx.backendClient
          .getclientappsByScope(props.product?.id!, id ? id:"test")
          .then((res) => {
              const list = res.map((app) => (
                  <li key={app.displayName}>
                      <p>{app.displayName}</p>
                  </li>
              ));

              control.push(<div><p> Are you sure you want to remove the Scope from the backend?</p></div>)

              if (res?.length > 0) {
                  control.push(<div><p>The Scope will be automatically deleted from the following Clients:</p></div>)
                  control.push(<div><ul>{list}</ul></div>);
              }
          }).catch((err) => {
              Logger.Error(err);
          }).finally(() => {
              setLoading(false);
          });
    }
    setDeletingMessage(control);
  };

  return (
    <div>
      <Panel
        headerText={`${isExisting ? "Edit" : "Create"} ${
          currentProduct?.displayName ?? props.product?.displayName
        } ${APIMEnvironment[props.env ?? 0]} Backend`}
        isOpen={props.visible}
        onDismiss={() => {
          if (touched) {
            setIsCancelConfirmationDialogVisible(true);
          } else {
            onDismiss(undefined);
          }
        }}
        closeButtonAriaLabel="Close"
        type={PanelType.medium}
        layerProps={{ styles: { root: { zIndex: 998 } } }}
        onOuterClick={() => {
          /*ignored*/
        }}
      >
        <Loading loading={loading || false} />
        <TextField
          label="Backend Display Name"
          value={currentBackendApp?.displayName ?? ""}
          onChange={(e) => {
            setCurrentBackendApp((prevState) => ({
              ...prevState!,
              displayName: (e.target as HTMLInputElement).value
            }));
            setTouched(true);
          }}
          prefix={getDisplayNamePrefix()}
          disabled={isExisting}
          required
          onGetErrorMessage={() => getErrorMessage("displayName")}
          summary={`The display name for the backend as it is visible in the Azure Portal. This value is prefixed by:
                      'apim-backend-<apim_environment>-'. Please append a descriptive name for the backend (without whitespaces).`}
          onRenderLabel={onRenderLabelInfoButton}
        />
        <TextField
          label="Backend Scope (Identifier URI)"
          value={currentBackendApp?.identifierUri ?? ""}
          onChange={(e) => {
            setCurrentBackendApp((prevState) => ({
              ...prevState!,
              identifierUri: (e.target as HTMLInputElement).value
            }));
            setTouched(true);
          }}
          disabled={isExisting}
          placeholder="e.g. api://<apiname>-<env> or https://api-<env>.omv.com/<apipath>"
          required
          onGetErrorMessage={() => getErrorMessage("identifierUri")}
          summary={`The scope of the backend, which has to be unique and is used as a identifier URI. When clients want to request an access token for this backend
                      they will have to send this in the scope parameter like <scope>/.default. When using https:// as a protocol the domain used has to be a verified domain within the OMV tenant like *.omv.com.`}
          onRenderLabel={onRenderLabelInfoButton}
        />
        <div className="ms-Grid-row" style={{ marginTop: "15px" }}>
          <div className="ms-Grid-col ms-lg12">
            <Stack horizontal>
              <h5>App roles</h5>
              <InfoLabelButton
                id="appRoleInfoButton"
                summary={`App roles can be used to assign roles to a client of an API. These roles can then be checked in the Operation Policies of the API operations.
                                By this means fine granular access for clients to specific operations of an API can be established. See the "View policy"
                                button on the overview panel for the products OAuth2 backends to display the policy to be added in API Management for client app role validation.`}
              />
              {!isExisting &&
                props.env !== null &&
                props.product?.id &&
                APIMEnvironment[props.env!] !== "DEV" && (
                  <DefaultButton
                    text="Copy DEV App Roles"
                    onClick={() => {
                      setLoading(true);
                      ctx.backendClient
                        .getBackendApp(
                          props.product?.id!,
                          APIMEnvironment[APIMEnvironment.DEV]
                        )
                        .then((res) => {
                          const newAppRoles: IAppRole[] = res.appRoles;
                          setCurrentBackendApp((prevState) => ({
                            ...prevState!,
                              appRoles: newAppRoles
                          }));
                          setTouched(true);
                          Logger.Success(
                            `Copied App Roles defined for the DEV environment, please check if they are applicable also to the  ${
                              APIMEnvironment[props.env!]
                            } environment.`
                          );
                        })
                        .catch((err) => {
                          Logger.Error(err);
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    }}
                    styles={{ root: { marginLeft: 15 } }}
                    allowDisabledFocus
                  />
                )}
            </Stack>
            {currentBackendApp &&
              currentBackendApp.appRoles &&
              currentBackendApp.appRoles.length > 0 &&
              createExpandableStringArrayControls(
                currentBackendApp?.appRoles ?? [undefined],
                (newAppRoles) => {
                  currentBackendApp.appRoles = newAppRoles;
                  setCurrentBackendApp((prevState) => ({
                    ...prevState!,
                    appRoles: newAppRoles
                  }));
                },
                "AppRole",
                "App Role"
              )}
            <IconButton
              key={`$AppRoleAddButton`}
              iconProps={{ iconName: "Add" }}
              ariaLabel={`Add App Role`}
              disabled={false}
              onClick={() => {
                  const newAppRoles = [...(currentBackendApp?.appRoles ?? [])];
                  newAppRoles.push({ name: "", description: "", type:"AppRole" });
                setCurrentBackendApp((prevState) => ({
                  ...prevState!,
                  appRoles: newAppRoles
                }));
                setTouched(true);
              }}
            />
          </div>
        </div>
        <div className="ms-Grid-row" style={{ marginTop: "15px" }}>
          <div className="ms-Grid-col ms-lg12">
            <Stack horizontal>
              <h5>User Scopes</h5>
              <InfoLabelButton
                id="appRoleInfoButton"
                summary={`User scopes can be used for clients that have interaction with a user, e.g. a web application, a native or a mobile app where a user has to log in.
                                In such clients a user bound access token can be issued, which contains information about the user. The token will also include a scope (scp) claim that can be checked in API Management policy of
                                API operations or it can be used in the backend to generate a user context of the API calls. See the "View policy"
                                button on the overview panel for the products OAuth2 backends to display the policy to be added in API Management for user scope validation.`}
              />
              {!isExisting &&
                props.env !== null &&
                props.product?.id &&
                APIMEnvironment[props.env!] !== "DEV" && (
                  <DefaultButton
                    text="Copy DEV User Scopes"
                    onClick={() => {
                      setLoading(true);
                      ctx.backendClient
                        .getBackendApp(
                          props.product?.id!,
                          APIMEnvironment[APIMEnvironment.DEV]
                        )
                        .then((res) => {
                          const newScopes: IOAuth2PermissionScope[] =
                            res.scopes;
                          setCurrentBackendApp((prevState) => ({
                            ...prevState!,
                              scopes: newScopes
                          }));
                          setTouched(true);
                          Logger.Success(
                            `Copied User Scopes defined for the DEV environment, please check if they are applicable also to the  ${
                              APIMEnvironment[props.env!]
                            } environment.`
                          );
                        })
                        .catch((err) => {
                          Logger.Error(err);
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    }}
                    styles={{ root: { marginLeft: 15 } }}
                    allowDisabledFocus
                  />
                )}
            </Stack>
            {currentBackendApp &&
              currentBackendApp.scopes &&
              currentBackendApp.scopes.length > 0 &&
              createExpandableStringArrayControls(
                currentBackendApp?.scopes ?? [undefined],
                (newUSerScopes) => {
                  currentBackendApp.scopes = newUSerScopes;
                  setCurrentBackendApp((prevState) => ({
                    ...prevState!,
                    scopes: newUSerScopes
                  }));
                },
                "UserScope",
                "User Scope"
              )}
            <IconButton
              key={`$UserScopeeAddButton`}
              iconProps={{ iconName: "Add" }}
              ariaLabel={`Add User Scope`}
              disabled={false}
              onClick={() => {
                  const newUserScopes = [...(currentBackendApp?.scopes ?? [])];
                  newUserScopes.push({ name: "", description: "", type:"UserScope" });

                  setCurrentBackendApp((prevState) => ({
                    ...prevState!,
                    scopes: newUserScopes
                  }));
                  setTouched(true);
                }
              }
            />
          </div>
        </div>
        <div className="ms-Grid-row" style={{ marginTop: "15px" }}>
          <div className="ms-Grid-col ms-lg12">
            <PrimaryButton
              text={isExisting ? "Save" : "Create"}
              onClick={() => {
                const errorMessage = getErrorMessage("all");
                const errorMessageAppRoles = getPermissionNameErrorMessage(
                  currentBackendApp?.appRoles?.map((role) => role.name) ?? []
                );
                const errorMessageScopes = getPermissionNameErrorMessage(
                  currentBackendApp?.scopes?.map((scope) => scope.name) ?? []
                );
                if (errorMessage.length > 0) {
                  toast.error(errorMessage, { autoClose: 15000 });
                } else if (errorMessageAppRoles.length > 0) {
                  toast.error(errorMessageAppRoles, { autoClose: 15000 });
                } else if (errorMessageScopes.length > 0) {
                  toast.error(errorMessageScopes, { autoClose: 15000 });
                } else {
                  saveBackend();
                }
              }}
              disabled={!touched}
              allowDisabledFocus
            />
            <DefaultButton
              text="Cancel"
              onClick={() => {
                if (touched) {
                  setIsCancelConfirmationDialogVisible(true);
                } else {
                  onDismiss(undefined);
                }
              }}
              styles={{ root: { marginLeft: 15 } }}
              allowDisabledFocus
            />
          </div>
        </div>
        <Dialog
          hidden={!isCancelConfirmationDialogVisible}
          onDismiss={() => setIsCancelConfirmationDialogVisible(false)}
          dialogContentProps={{
            type: DialogType.normal,
            title: `${
              isExisting
                ? "Are you sure you want to cancel the creation of the backend?"
                : "Are you sure you want to cancel the update of the backend?"
            }`
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                setIsCancelConfirmationDialogVisible(false);
                onDismiss(undefined);
              }}
              text="Yes"
            />
            <DefaultButton
              onClick={() => {
                setIsCancelConfirmationDialogVisible(false);
              }}
              text="No"
            />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!confirmRemovalOfPermission}
          onDismiss={() => setConfirmRemovalOfPermission(undefined)}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Confirm deletion of Client Role/Scope"  }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
              >
          {DeletingMessage}
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                const newPermissions = [...confirmRemovalOfPermission.list];
                newPermissions.splice(confirmRemovalOfPermission.listIndex, 1);
                confirmRemovalOfPermission.updateList(newPermissions);
                setTouched(true);
                setConfirmRemovalOfPermission(undefined);
              }}
              text="Yes"
            />
            <DefaultButton
              onClick={() => {
                setConfirmRemovalOfPermission(undefined);
              }}
              text="No"
            />
          </DialogFooter>
        </Dialog>
      </Panel>
    </div>
  );
};
