import { useState, useEffect, useContext } from "react";
import { Stack } from "@fluentui/react";
import { DonutChart } from "components/Dashboard/DonutChart";
import { context } from "AppContext";
import { Loading } from "components/Core/Loading";

export const Home = () => {
  const ctx = useContext(context)!;
  const DONUT_ANIMATION_DURATION = 3000;
  const DONUT_SIZE = 300;

  const [, setProductStatistics] = useState([
    { x: "1", y: 33 },
    { x: "2", y: 33 },
    { x: "3", y: 34 }
  ]);

  useEffect(() => {
    setProductStatistics([
      { x: "1", y: 50 },
      { x: "2", y: 50 }
    ]);
  }, []);

  return (
    <Stack>
      <Loading loading={ctx.loadingProducts} />
      <Stack>
        <h3>Welcome to the API Product Owner Portal</h3>
      </Stack>
      <div
        style={{
          display: "table",
          width: 260,
          fontSize: 20,
          border: "1px solid rgb(31, 254, 90)",
          padding: 10
        }}
      >
        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell" }}>Products owned:</div>
          <div style={{ display: "table-cell", fontWeight: "bold" }}>
            {ctx.products?.length || 0}
          </div>
        </div>
      </div>
      <Stack style={{ marginTop: "25px" }}>
        <h4>
          API Product statistics on{" "}
          <span
            style={{
              color: `${
                ctx.apimEnvironment === "DEV"
                  ? "#1FFF5A"
                  : ctx.apimEnvironment === "TEST"
                  ? "orange"
                  : "#95233A"
              }`
            }}
          >
            {ctx.apimEnvironment}
          </span>
        </h4>
      </Stack>
      {(ctx.products && ctx.products.length > 0) || ctx.loadingProducts ? (
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-xxxl4 ms-xxl4 ms-xl4 ms-lg6 ms-md12 ms-sm12">
            <DonutChart
              centerLabel="Category"
              size={DONUT_SIZE}
              total={ctx.products?.length || 0}
              animationDuration={DONUT_ANIMATION_DURATION}
              data={[
                {
                  label: "Normal",
                  amount: ctx.products?.filter(
                    (p) => p.category && p.category === 1
                  ).length,
                  navigateToLink: "/products?filtered=true&category=1"
                },
                {
                  label: "Important",
                  amount: ctx.products?.filter(
                    (p) => p.category && p.category === 2
                  ).length,
                  navigateToLink: "/products?filtered=true&category=2"
                },
                {
                  label: "Business Critical",
                  amount: ctx.products?.filter(
                    (p) => p.category && p.category === 4
                  ).length,
                  navigateToLink: "/products?filtered=true&category=4"
                }
              ]}
              undefinedNavigateToLink="/products?filtered=true&category=0"
              colorScale={["#1FFF5A", "#e39014", "#95233A", "#aaaaaa"]}
            />
          </div>
          <div className="ms-Grid-col ms-xxxl4 ms-xxl4 ms-xl4 ms-lg6 ms-md12 ms-sm12">
            <DonutChart
              centerLabel="Type"
              size={DONUT_SIZE}
              total={ctx.products?.length || 0}
              animationDuration={DONUT_ANIMATION_DURATION}
              data={[
                {
                  label: "Internal",
                  amount: ctx.products?.filter((p) => p.type && p.type === 1)
                    .length,
                  navigateToLink: "/products?filtered=true&types=1"
                },
                {
                  label: "Partner",
                  amount: ctx.products?.filter((p) => p.type && p.type === 2)
                    .length,
                  navigateToLink: "/products?filtered=true&types=2"
                },
                {
                  label: "Public",
                  amount: ctx.products?.filter((p) => p.type && p.type === 4)
                    .length,
                  navigateToLink: "/products?filtered=true&types=4"
                },
                {
                  label: "Multiple",
                  amount: ctx.products?.filter(
                    (p) => p.type && (p.type === 3 || p.type > 4)
                  ).length,
                  navigateToLink: "/products?filtered=true&types=-1"
                }
              ]}
              undefinedNavigateToLink="/products?filtered=true&types=0"
              colorScale={[
                "#1FFF5A",
                "#052759",
                "#1F55DF",
                "#e39014",
                "#aaaaaa"
              ]}
            />
          </div>
          <div className="ms-Grid-col ms-xxxl4 ms-xxl4 ms-xl4 ms-lg6 ms-md12 ms-sm12">
            <DonutChart
              centerLabel="Backend Hosts"
              size={DONUT_SIZE}
              total={ctx.products?.length || 0}
              animationDuration={DONUT_ANIMATION_DURATION}
              data={[
                {
                  label: "OnPremise",
                  amount: ctx.products?.filter(
                    (p) => p.backendHosting && p.backendHosting === 1
                  ).length,
                  navigateToLink: "/products?filtered=true&backendHostings=1"
                },
                {
                  label: "Cloud/Internet",
                  amount: ctx.products?.filter(
                    (p) => p.backendHosting && p.backendHosting === 2
                  ).length,
                  navigateToLink: "/products?filtered=true&backendHostings=2"
                },
                {
                  label: "OnPremise and Cloud/Internet",
                  amount: ctx.products?.filter(
                    (p) => p.backendHosting && p.backendHosting === 3
                  ).length,
                  navigateToLink: "/products?filtered=true&backendHostings=1,2"
                }
              ]}
              undefinedNavigateToLink="/products?filtered=true&backendHostings=0"
              colorScale={["#1FFF5A", "#052759", "#1F55DF", "#aaaaaa"]}
            />
          </div>
          <div className="ms-Grid-col ms-xxxl4 ms-xxl4 ms-xl4 ms-lg6 ms-md12 ms-sm12">
            <DonutChart
              centerLabel="Backend Routing"
              size={DONUT_SIZE}
              total={ctx.products?.length || 0}
              animationDuration={DONUT_ANIMATION_DURATION}
              data={[
                {
                  label: "Globally Configured",
                  amount: ctx.products?.filter(
                    (p) => p.backendRouting && p.backendRouting === 1
                  ).length,
                  navigateToLink: "/products?filtered=true&backendRoutings=1"
                },
                {
                  label: "Policy based",
                  amount: ctx.products?.filter(
                    (p) => p.backendRouting && p.backendRouting === 2
                  ).length,
                  navigateToLink: "/products?filtered=true&backendRoutings=2"
                },
                {
                  label: "Globally configured and policy based",
                  amount: ctx.products?.filter(
                    (p) => p.backendRouting && p.backendRouting === 3
                  ).length,
                  navigateToLink: "/products?filtered=true&backendRoutings=1,2"
                }
              ]}
              undefinedNavigateToLink="/products?filtered=true&backendRoutings=0"
              colorScale={["#1FFF5A", "#052759", "#1F55DF", "#aaaaaa"]}
            />
          </div>
          <div className="ms-Grid-col ms-xxxl4 ms-xxl4 ms-xl4 ms-lg6 ms-md12 ms-sm12">
            <DonutChart
              centerLabel="Authorization"
              size={DONUT_SIZE}
              total={ctx.products?.length || 0}
              animationDuration={DONUT_ANIMATION_DURATION}
              data={[
                {
                  label: "Subscription key",
                  amount: ctx.products?.filter(
                    (p) => p.authorization && p.authorization === 1
                  ).length,
                  navigateToLink: "/products?filtered=true&authorizations=1"
                },
                {
                  label: "OAuth 2.0",
                  amount: ctx.products?.filter(
                    (p) => p.authorization && p.authorization === 2
                  ).length,
                  navigateToLink: "/products?filtered=true&authorizations=2"
                },
                {
                  label: "Client Certificate",
                  amount: ctx.products?.filter(
                    (p) => p.authorization && p.authorization === 4
                  ).length,
                  navigateToLink: "/products?filtered=true&authorizations=4"
                },
                {
                  label: "Multiple",
                  amount: ctx.products?.filter(
                    (p) =>
                      p.authorization &&
                      (p.authorization === 3 || p.authorization > 4)
                  ).length,
                  navigateToLink: "/products?filtered=true&authorizations=-1"
                }
              ]}
              colorScale={[
                "#1FFF5A",
                "#052759",
                "#1F55DF",
                "#e39014",
                "#aaaaaa"
              ]}
              undefinedNavigateToLink="/products?filtered=true&authorizations=0"
            />
          </div>
          <div className="ms-Grid-col ms-xxxl4 ms-xxl4 ms-xl4 ms-lg6 ms-md12 ms-sm12">
            <DonutChart
              centerLabel="KeyVault Usage"
              size={DONUT_SIZE}
              total={ctx.products?.length || 0}
              animationDuration={DONUT_ANIMATION_DURATION}
              data={[
                {
                  label: "Active",
                  amount: ctx.products?.filter((p) => p.isUsingKeyVault).length,
                  navigateToLink: "/products?filtered=true&keyVaultUsage=1"
                },
                {
                  label: "No",
                  amount: ctx.products?.filter((p) => !p.isUsingKeyVault)
                    .length,
                  navigateToLink: "/products?filtered=true&keyVaultUsage=2"
                }
              ]}
              colorScale={["#1FFF5A", "#052759", "#aaaaaa"]}
              undefinedNavigateToLink="/products?filtered=true&keyVaultUsage=0"
            />
          </div>
          <div className="ms-Grid-col ms-xxxl4 ms-xxl4 ms-xl4 ms-lg6 ms-md12 ms-sm12">
            <DonutChart
              centerLabel="Confidentiality"
              size={DONUT_SIZE}
              total={ctx.products?.length || 0}
              animationDuration={DONUT_ANIMATION_DURATION}
              data={[
                {
                  label: "Public",
                  amount: ctx.products?.filter(
                    (p) => p.confidentiality && p.confidentiality === 1
                  ).length,
                  navigateToLink: "/products?filtered=true&confidentiality=1"
                },
                {
                  label: "Confidential",
                  amount: ctx.products?.filter(
                    (p) => p.confidentiality && p.confidentiality === 2
                  ).length,
                  navigateToLink: "/products?filtered=true&confidentiality=2"
                },
                {
                  label: "Strictly Confidential",
                  amount: ctx.products?.filter(
                    (p) => p.confidentiality && p.confidentiality === 4
                  ).length,
                  navigateToLink: "/products?filtered=true&confidentiality=4"
                }
              ]}
              undefinedNavigateToLink="/products?filtered=true&confidentiality=0"
              colorScale={["#1FFF5A", "#e39014", "#95233A", "#aaaaaa"]}
            />
          </div>
        </div>
      ) : (
        <p>
          &nbsp;You dont own any products yet. If you have questions please
          contact
          <a href="mailto:api-support@omv.com"> api-support@omv.com</a>.
        </p>
      )}
    </Stack>
  );
};
